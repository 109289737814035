import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';

import custom_os_aop from '../controls/custom_os_aop.variable';

import MultiSelect from "react-multi-select-component";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';

const VlasntnaPeriodaAOP = (props) => {

    var options = arrangeCourses(props.allCourses)
    
    function getCourseNameFromAlias(alias){
        const table = props.allCourses.filter(function(item){return item.course_alias === alias})[0]
        const course_name = table.course_nazov
        return course_name
      }

    // const [rozsahArr1,setRozsahArr1] = useState(props.option_array ? arr2opt(props.option_array) : []);
    const [valuesRozsah,settersRozsah] = useState((props.option_array)?arr2opt(props.option_array):
        // [
        //     {'label':getCourseNameFromAlias('opp_hasiace'),'value':getCourseNameFromAlias('opp_hasiace')},
        // ]
        function(){
            let out = []
            Object.keys(custom_os_aop).forEach(alias=>{
                if (custom_os_aop[alias].aop){
                    out.push({'label':getCourseNameFromAlias(alias),'value':getCourseNameFromAlias(alias)})
                }
            })
            return out
        }
    );


    // const valuesRozsah = rozsahArr1
    // const settersRozsah = setRozsahArr1

    function arr2opt(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push({'label': item, 'value': item}))
        }
        return converted
    }

    function arrangeCourses(allCourses){
        var converted = []
        if (allCourses.length>0){
            allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(item => {if (item.course_AOP===true) converted.push({'label': item.course_nazov, 'value': item.course_nazov})})
        }
        return converted
    }

    function convert_multiselect(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push(item["label"]))
        }
        return converted
    }


    const popoverHoverFocus = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" style={{color:'black',fontSize:20,padding:"1em",maxWidth:"400px"}}>
            Nasledujúce odbornosti majú prednastavené osobitné lehoty AOP: 
            <ul>
                {Object.keys(custom_os_aop).map(alias=>(
                (custom_os_aop[alias].aop)?<li>{getCourseNameFromAlias(alias)} ({custom_os_aop[alias].aop} m.)</li>:null
                ))}
            </ul>
        </Popover>
      );

    useEffect(()=>{
        props.onChangeCompanyData(convert_multiselect(valuesRozsah),"vlastna_perioda_aop","yes")},[valuesRozsah]
        )


    console.log('options chosen:',valuesRozsah)
    console.log('options from location state:',props.option_array)
    return(
        
        <>
                {(options) ? (
        
                    <form className="form-group col-lg-4">
                    <label style={{color: "white"}}>Osobitná lehota AOP:
                    <span>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="right"
                            overlay={popoverHoverFocus}
                            >
                            {/* <Button>Hover + Focus</Button> */}
                            <AiOutlineInfoCircle
                                size="1.3em"
                                style={{marginLeft:"1em"}}
                                color = 'orange'
                            />
                        </OverlayTrigger>
                    </span>
                    </label>
                    <MultiSelect 
                    options={options}
                    overrideStrings={{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj...",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Vyber..."
                    }}
                    value={valuesRozsah}
                    onChange={settersRozsah}
                    labelledBy="Select"
                    
                    />
                    </form>
                ) : null}

               
        </>
    )
    
}
export default VlasntnaPeriodaAOP;