import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';

import { FaExclamationCircle} from "react-icons/fa";


import MultiSelect from "react-multi-select-component";


const Firmy = (props) => {

    var options = arr2opt(props.companiesList)

    const [rozsahArr1,setRozsahArr1] = useState(props.option_array ? arr2opt(props.option_array) : []);

    const valuesRozsah = rozsahArr1
    const settersRozsah = setRozsahArr1

    function arr2opt(array){
        var converted = []
        if (array.length>0){
            array.sort((a, b) => a > b ? 1:-1).map(item => {
                if (props.companiesListInactive.includes(item)){
                    // converted.push({'label': <div>{item} <FaExclamationCircle style={{fontSize: "20px", color: "red", cursor: "pointer"}}/> </div>, 'value': item}) // element in label causes problem in multiselect manual search
                    converted.push({'label': item+ '❗', 'value': item})
                    
                }else{
                    converted.push({'label': item, 'value': item})
                }
                
            })
        }
        return converted
    }

    useEffect(()=>{
        props.filter_companies(valuesRozsah)},[valuesRozsah]
        )


    // console.log('options chosen:',valuesRozsah)
    // options && console.log('options in firmy-osobyFilters:',options)
    // console.log('options from location state:',props.option_array)
    return(
        
        <>
                {(options) ? (
        
                    <form className="form-group col-lg-8">
                    <label style={{color: "white"}}>Firmy:</label>
                    <MultiSelect 
                    options={options}
                    overrideStrings={{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko (všetky profily so zadanou firmou)",
                        "selectSomeItems": "Všetky (aj nezadané)"
                    }}
                    value={valuesRozsah}
                    onChange={settersRozsah}
                    labelledBy="Select"
                    
                    />
                    </form>
                ) : null}

               
        </>
    )
    
}
export default Firmy;