import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "./styles.css";
import OS from './formOS.component';
import AOP from './formAOP.component';
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';



import MultiSelect from "react-multi-select-component";


const ZS = (props) => {

    var options = []
    var rDict={}
    const [showDeletePopup,setShowDeletePopup] = useState(0);
    
    const [npreukaz, setNpreukaz] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || props.currentTutorial[props.course.course_nazov].zakladne.evcislo==="") ? (props.noPreukazAllowed.includes(props.course.course_alias)?0:1) : (props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;").length));

    //adjusted to max. number of preukaz: 5
    const max_preukaz = 1

    const [vydanieArr1,setVydanieArr1] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.vydanie || props.currentTutorial[props.course.course_nazov].zakladne.vydanie==="") ? ("") : (props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;")[0]));
    const [vydanieArr2,setVydanieArr2] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.vydanie || props.currentTutorial[props.course.course_nazov].zakladne.vydanie==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;").length>=2) ? props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;")[1] : ""));
    const [vydanieArr3,setVydanieArr3] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.vydanie || props.currentTutorial[props.course.course_nazov].zakladne.vydanie==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;").length>=3) ? props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;")[2] : ""));
    const [vydanieArr4,setVydanieArr4] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.vydanie || props.currentTutorial[props.course.course_nazov].zakladne.vydanie==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;").length>=4) ? props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;")[3] : ""));
    const [vydanieArr5,setVydanieArr5] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.vydanie || props.currentTutorial[props.course.course_nazov].zakladne.vydanie==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;").length>=5) ? props.currentTutorial[props.course.course_nazov].zakladne.vydanie.split(";;")[4] : ""));
    const valuesVydanie = [vydanieArr1,vydanieArr2,vydanieArr3,vydanieArr4,vydanieArr5]
    const settersVydanie = [setVydanieArr1,setVydanieArr2,setVydanieArr3,setVydanieArr4,setVydanieArr5]

    const [rozsahArr1,setRozsahArr1] = useState( (!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah==="" || npreukaz===0) ? ([]) : (()=> setRozsahy(0))  );
    const [rozsahArr2,setRozsahArr2] = useState( (!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah==="" || npreukaz===0) ? ([]) : (()=> setRozsahy(1))  );
    const [rozsahArr3,setRozsahArr3] = useState( (!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah==="" || npreukaz===0) ? ([]) : (()=> setRozsahy(2))  );
    const [rozsahArr4,setRozsahArr4] = useState( (!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah==="" || npreukaz===0) ? ([]) : (()=> setRozsahy(3))  );
    const [rozsahArr5,setRozsahArr5] = useState( (!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah==="" || npreukaz===0) ? ([]) : (()=> setRozsahy(4))  );
 
    const valuesRozsah = [rozsahArr1,rozsahArr2,rozsahArr3,rozsahArr4,rozsahArr5]
    const settersRozsah = [setRozsahArr1,setRozsahArr2,setRozsahArr3,setRozsahArr4,setRozsahArr5]

    const [evcisloArr1,setEvCisloArr1] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || props.currentTutorial[props.course.course_nazov].zakladne.evcislo==="") ? ("") : (props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;")[0]));
    const [evcisloArr2,setEvCisloArr2] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || props.currentTutorial[props.course.course_nazov].zakladne.evcislo==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;").length>=2) ? props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;")[1] : ""));
    const [evcisloArr3,setEvCisloArr3] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || props.currentTutorial[props.course.course_nazov].zakladne.evcislo==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;").length>=3) ? props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;")[2] : ""));
    const [evcisloArr4,setEvCisloArr4] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || props.currentTutorial[props.course.course_nazov].zakladne.evcislo==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;").length>=4) ? props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;")[3] : ""));
    const [evcisloArr5,setEvCisloArr5] = useState((!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || props.currentTutorial[props.course.course_nazov].zakladne.evcislo==="") ? ("") : ((props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;").length>=5) ? props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;")[4] : ""));
    const valuesEvCislo = [evcisloArr1,evcisloArr2,evcisloArr3,evcisloArr4,evcisloArr5]
    const settersEvCislo = [setEvCisloArr1,setEvCisloArr2,setEvCisloArr3,setEvCisloArr4,setEvCisloArr5]

    const [evcisloFinal,setEvcisloFinal] = useState("")
    const [vydanieFinal,setVydanieFinal] = useState("")
    const isFirstRender = useRef(true)

    function setRozsahy(i){
        console.log('in setRoszahy',props.currentTutorial,props.course.course_nazov)
        console.log('in setRozsahy-items:',props.course.course_rozsah.split(";;"))
        console.log('in setRozsahy-map:',props.course.course_rozsah.split(";;").map(item=>props.currentTutorial[props.course.course_nazov].zakladne.rozsah[item]))
        {var result = []}
        (props.course.course_rozsah.split(";;").filter(item=>props.currentTutorial[props.course.course_nazov].zakladne.rozsah[item].includes(props.currentTutorial[props.course.course_nazov].zakladne.evcislo.split(";;")[i]))).map(roz => result.push({'label': roz, 'value': roz}) )
        if (result.length!==[]) {console.log('result:',result)}
        return result
    }

    function initialRozsah(initRozsah = {}){   
        props.course.course_rozsah.split(";;").map( (rozsah) => {
            console.log(rozsah)
            initRozsah[rozsah] = []
            console.log('initRozsah',initRozsah)
        })
        return initRozsah
    }

    function onChangeEvCislo(e,i,direct= 'no') {
        console.log('vars: e,i',e,i)
        const entry = (direct==='yes') ? e : e.target.value;
        settersEvCislo[i](entry)
        console.log('onChangeEvCislo entry:',entry,valuesEvCislo[i])
    };
    
    function onChangeVydanie(e,i,direct = 'no') {
        console.log('vars: e,i',e,i)
        const entry = (direct==='yes') ? e : e.target.value;
        settersVydanie[i](entry)
    };


    function onChangeRozsah(iter,init) {

        options.map(option => {
            if (iter===0) {rDict = initialRozsah({});init=1}
            if (init===1) {iter=1}
            if (iter===1){
            console.log('option:',option)
                Array.from(Array(npreukaz).keys()).map((i) => {
                    console.log('valueRozsah:',valuesRozsah[i])
                    console.log('valueEvcislo:',valuesEvCislo[i])
                    if (valuesRozsah[i].some(e=>e.label === option.label)) {
                        console.log('yes, setting ',option.label,' to ',valuesEvCislo[i]);
                        rDict[option.label] = [...rDict[option.label], valuesEvCislo[i]]
                        console.log('setting: ', [option.label],'->',valuesEvCislo[i])}
                        console.log('rDict:',rDict)
                })
            }
            props.onChangeUniversal(rDict,props.course.course_nazov,'zakladne','rozsah','yes')
        })
        
        // console.log('in onChangeRozsah')
        // console.log('evcisloArr1:',evcisloArr1)
    };

    function cleanPrekazData(which_preukaz){
        console.log("cleaning whichpreukaz:",which_preukaz)

        
        if (which_preukaz>0 || which_preukaz === -1) {
            props.onChangeUniversal("",props.course.course_nazov,'opakovane','skuska0','yes')
            props.onChangeUniversal([],props.course.course_nazov,'opakovane','predAOParr0','yes')
        }
        if (which_preukaz>0 || which_preukaz === -1) {
            props.onChangeUniversal("",props.course.course_nazov,'aktualizacia','skuska0','yes')
            props.onChangeUniversal([],props.course.course_nazov,'aktualizacia','predAOParr0','yes')
        }

        if (which_preukaz !== -1){
            settersVydanie[which_preukaz]("")
            settersEvCislo[which_preukaz]("")
            settersRozsah[which_preukaz]([])
            
            props.onChangeUniversal("",props.course.course_nazov,'opakovane','skuska'+(which_preukaz+1),'yes')
            props.onChangeUniversal([],props.course.course_nazov,'opakovane','predAOParr'+(which_preukaz+1),'yes')
            
            props.onChangeUniversal("",props.course.course_nazov,'aktualizacia','skuska'+(which_preukaz+1),'yes')
            props.onChangeUniversal([],props.course.course_nazov,'aktualizacia','predAOParr'+(which_preukaz+1),'yes')

            if (max_preukaz===1){
                props.onChangeUniversal("",props.course.course_nazov,'zakladne','evcislo','yes')
                props.onChangeUniversal("",props.course.course_nazov,'zakladne','vydanie','yes')
            }

            props.onChangePreukazScan("",props.course.course_nazov,which_preukaz+1,'provided')
        }
    }

    
    useEffect(() => {
        props.npreukazTracer(props.course.course_nazov, npreukaz)

        if (npreukaz<max_preukaz){
            // console.log('cleaning:',props.course.course_nazov, 'and preukaz: ',npreukaz+1)
            cleanPrekazData(npreukaz)
            // console.log('npreukaz in useEffect:',npreukaz)
        }
        }, [npreukaz])

    useEffect(() => {
        // var evcisla = []
        if (npreukaz===0) console.log('make some alert!')
        if (npreukaz===1) {setEvcisloFinal(evcisloArr1)}
        if (npreukaz > 1) {setEvcisloFinal(Array.from(Array(npreukaz).keys()).map((i) => `${valuesEvCislo[i]}`).join(';;'))}
    }, [valuesEvCislo])

    useEffect(() => {
        if (npreukaz===0) console.log('make some alert!')
        if (npreukaz===1) {setVydanieFinal(vydanieArr1)}
        if (npreukaz > 1) {setVydanieFinal(Array.from(Array(npreukaz).keys()).map((i) => `${valuesVydanie[i]}`).join(';;'))}
    }, [valuesVydanie])

    useEffect(() => {
        // if (isFirstRender.current) {
        //   isFirstRender.current = false // toggle flag after first render/mounting
        //   return;
        // }
        props.onChangeUniversal(evcisloFinal,props.course.course_nazov,'zakladne','evcislo','yes')
        // console.log('evcislofinal:',evcisloFinal)
        }, [evcisloFinal])

    useEffect(() => {
        // if (isFirstRender.current) {
        //     isFirstRender.current = false // toggle flag after first render/mounting
        //     return;
        // }
        props.onChangeUniversal(vydanieFinal,props.course.course_nazov,'zakladne','vydanie','yes')
        // console.log('vydaniefinal:',vydanieFinal)
        }, [vydanieFinal])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false // toggle flag after first render/mounting
            return;
        }
        onChangeRozsah(0,0)
        // console.log('valuesrozsah:',valuesRozsah)
        }, [rozsahArr1,rozsahArr2,rozsahArr3,rozsahArr4,rozsahArr5,evcisloArr1,evcisloArr2,evcisloArr3,evcisloArr4,evcisloArr5])


        useEffect(() => {

            props.final_checks_clear("error_"+props.course.course_nazov+"_rozsah")

            }, [valuesRozsah[0]])

    if (props.course.course_rozsah) {console.log('rozsah:',props.course.course_rozsah);props.course.course_rozsah.split(";;").map(rozsah => options.push({'label': rozsah, 'value': rozsah}))}
    
    // console.log('npreukaz:',npreukaz)
    // console.log('setters',settersEvCislo)
    // console.log('setters[0]',settersEvCislo[0])
    // console.log('values',valuesEvCislo[0],valuesEvCislo[1],valuesEvCislo[2])
    // console.log('rozsahy:',rozsahArr1,rozsahArr2,rozsahArr3)
    // console.log('final ev cislo:',evcisloFinal)
    // console.log('final vydanie:',vydanieFinal)
    // console.log('currentTut',props.currentTutorial,props.course.course_nazov,props.currentTutorial[props.course.course_nazov].zakladne.rozsah)
    // console.log('priradenie rozsahov:')
    // console.log('condition on rozsah setting:',props.currentTutorial[props.course.course_nazov].zakladne.rozsah,!props.currentTutorial[props.course.course_nazov].zakladne.rozsah,(!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah===""))
    // console.log('condition:',(!props.currentTutorial[props.course.course_nazov].zakladne.evcislo || !props.currentTutorial[props.course.course_nazov].zakladne.rozsah || props.currentTutorial[props.course.course_nazov].zakladne.rozsah==="") ? ([]) : (null))
    console.log('current tutorial in ZS Hook: ',props.currentTutorial)
    console.log('preukaz:',props.currentTutorial[props.course.course_nazov]["preukaz"+(0+1)] || props.currentTutorial[props.course.course_nazov]["preukaz"+(0+1)])

    // if (valuesRozsah[0].length >0) props.final_checks_clear("error_"+props.course.course_nazov+"_rozsah")
    console.log('values rozsh:',valuesRozsah[0])
    return(
        <>
            <div className="row w-100 mx-auto justify-content-left">
                <h5 style={{color: "white"}}>Základný kurz</h5>
            </div>
            <br/>
            
            
            <div className="form-group col-lg-4 required">
            <label style={{color: "white"}}>Dátum skúšky</label>
            <input
                type="text"
                className="form-control"
                required
                id={props.course.course_nazov}
                value={props.currentTutorial[props.course.course_nazov].zakladne.skuska}
                onChange={(e) => {props.onChangeUniversal(e,props.course.course_nazov,'zakladne','skuska');props.final_checks_clear("error_"+props.course.course_nazov)}}
                name={props.course.course_nazov}
                placeholder="DD.MM.RRRR"
            />
                {props.checks["error_"+props.course.course_nazov] && props.checks["error_"+props.course.course_nazov]!=="" && <p className="error">{props.checks["error_"+props.course.course_nazov]}</p>}
            </div>

            <div className="form-group col-lg-4 ">
            <label style={{color: "white"}}>Poznámka</label>
            <input
                type="text"
                className="form-control"
                id={props.course.course_nazov}
                value={props.currentTutorial[props.course.course_nazov].zakladne.poznamka}
                // onChange={(e) => {props.onChangeUniversal(e,props.course.course_nazov,'zakladne','poznamka');props.final_checks_clear("error_"+props.course.course_nazov)}}
                onChange={(e) => {props.onChangeUniversal(e,props.course.course_nazov,'zakladne','poznamka')}}
                name={props.course.course_nazov}
            />
            </div>

            <div className="form-group col-lg-4 ">
            </div>



            {Array.from(Array(npreukaz).keys()).map((i) => 
            <>
            <div className="row w-100 mx-auto justify-content-left">
                <h5 style={{color: "white", marginTop:20}}>Preukaz {i+1}</h5>
            </div>
            <Row className="w-100 mx-auto border border-secondary" style={{borderRadius:'0px', marginBottom: 30}}>



                <div className="form-group col-lg-4 required">
                    <label style={{color: "white"}}>Dátum vydania preukazu</label>
                    <input
                        type="text"
                        className="form-control"
                        id={props.course.course_nazov}
                        required
                        value={valuesVydanie[i]}
                        onChange={(e) => {onChangeVydanie(e,i);props.final_checks_clear("error_"+props.course.course_nazov+"_vydanie"+(i+1))}}
                        name={props.course.course_nazov}
                        placeholder="DD.MM.RRRR"
                    />
                    {/* <div class="invalid-feedback">Prosím vyplňte pole, ak chcete evidovať preukaz.</div> */}
                    {props.checks["error_"+props.course.course_nazov+"_vydanie"+(i+1)] && props.checks["error_"+props.course.course_nazov+"_vydanie"+(i+1)]!=="" && <p className="error">{props.checks["error_"+props.course.course_nazov+"_vydanie"+(i+1)]}</p>}
                </div>

                <div className="form-group col-lg-4 required">
                    <label style={{color: "white"}}>Evidenčné číslo preukazu</label>
                    <input
                        type="text"
                        className="form-control"
                        id={props.course.course_nazov}
                        required
                        value={valuesEvCislo[i]}
                        onChange={(e) => {onChangeEvCislo(e,i);props.final_checks_clear("error_"+props.course.course_nazov+"_evcislo"+(i+1))}}
                        name={props.course.course_nazov}
                    />
                    {/* <div class="invalid-feedback">Prosím vyplňte pole, ak chcete evidovať preukaz.</div> */}
                    {props.checks["error_"+props.course.course_nazov+"_evcislo"+(i+1)] && props.checks["error_"+props.course.course_nazov+"_evcislo"+(i+1)]!=="" && <p className="error">{props.checks["error_"+props.course.course_nazov+"_evcislo"+(i+1)]}</p>}
                </div>

                {/* <div className="input-group round col-lg-4" style ={{ marginTop: 0}}> */}
                <div className="form-group col-lg-4" style ={{ marginTop: 0}}>
                    <label htmlFor="validatedCustomFile" style={{color: "white"}}>Scan preukazu</label>
                    <div className="custom-file">
                    <input  type="file" className="custom-file-input" id="validatedCustomFile" accept="application/pdf, image/png, image/jpeg, image/jpg" onChange={(e)=>props.onChangePreukazScan(e,props.course.course_nazov,i+1)}/>
                    {/* <label className="custom-file-label" style={{marginTop:0,marginLeft:0}} for="validatedCustomFile">{(!props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)] || props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)]==="") ? "Vyber súbor...": props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)].name}</label> */}
                    <label className="custom-file-label" style={{marginTop:0,marginLeft:0}} for="validatedCustomFile">{(!props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)] || props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)]==="") ? "Vyber súbor...": (("name" in props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)]) ? props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)].name : props.currentTutorial[props.course.course_nazov]["preukaz"+(i+1)].config.url.split("/")[3])}</label>
                    </div>
                    {/* <div className="input-group-append">
                    <button style={{marginTop:30, marginBottom:17}} className="btn btn-secondary" type="button" onClick={this.onFileUpload}>Nahrať</button>
                    </div> */}
                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                </div>

                {(props.course.course_rozsah) ? (
        
                    <form className="form-group col-lg-4 required">
                    <label style={{color: "white"}}>Rozsah</label>
                    <MultiSelect 
                    options={options}
                    overrideStrings={{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Vyber..."
                    }}
                    value={valuesRozsah[i]}
                    onChange={settersRozsah[i]}
                    // onChange={setter_function(i)}
                    // onCreateOption={()=>{props.final_checks_clear("error_"+props.course.course_nazov+"_rozsah")}}
                    labelledBy="Select"
                    
                    />
                    {props.checks["error_"+props.course.course_nazov+"_rozsah"] && props.checks["error_"+props.course.course_nazov+"_rozsah"]!=="" && <p className="error">{props.checks["error_"+props.course.course_nazov+"_rozsah"]}</p>}
                    </form>
                ) : null}

                {(props.course.course_OS && npreukaz>0) ? 
                <OS 
                    final_checks_clear={props.final_checks_clear}
                    nazov={props.course.course_nazov} 
                    currentTutorial = {props.currentTutorial} 
                    onChangeUniversal = {props.onChangeUniversal} 
                    handleKeyDown = {props.handleKeyDown}
                    handlePaste = {props.handlePaste}
                    handleDelete = {props.handleDelete}
                    npreukaz = {i+1}
                /> : null
                }
                {(props.course.course_AOP && npreukaz>0) ? 
                <AOP
                    final_checks_clear={props.final_checks_clear} 
                    nazov={props.course.course_nazov} 
                    currentTutorial = {props.currentTutorial} 
                    onChangeUniversal = {props.onChangeUniversal} 
                    handleKeyDown = {props.handleKeyDown}
                    handlePaste = {props.handlePaste}
                    handleDelete = {props.handleDelete}
                    npreukaz = {i+1}
                /> : null
                }

                
            </Row>
            </>)}

            <div className=" w-100 mx-auto text-left" style = {{marginTop:10,paddingLeft:13}}>
                { (npreukaz<max_preukaz) ?
                    <button onClick={ () => {if (npreukaz< max_preukaz) {setNpreukaz(npreukaz + 1);cleanPrekazData(-1)}}} className="btn btn-primary">
                    Pridaj preukaz
                    </button> : null
                }    
                { (npreukaz>0 && props.noPreukazAllowed.includes(props.course.course_alias)) ?               
                    <button onClick={() => setShowDeletePopup(1)} className="btn btn-danger" style={{marginLeft: 10}}>
                    Odstráň preukaz
                    </button> : null
                }
                { (npreukaz>1 && !props.noPreukazAllowed.includes(props.course.course_alias)) ?               
                    <button onClick={() => setShowDeletePopup(1)} className="btn btn-danger" style={{marginLeft: 10}}>
                    Odstráň preukaz
                    </button> : null
                }
            </div>

            <SweetAlert
                show={showDeletePopup===1}
                warning
                showCancel
                confirmBtnText="Áno, chcem vymazať!"
                cancelBtnText="Zrušiť"
                confirmBtnBsStyle="danger"
                title="Chcete naozaj vymazať preukaz?"
                onConfirm={ 
                    // console.log('in onConfirm'),
                    () => {if (npreukaz>0) {setNpreukaz(npreukaz - 1)}
                    setShowDeletePopup(0)
                }}
                onCancel={()=>{
                console.log('cancel');
                setShowDeletePopup(0);
                }}
                focusCancelBtn
            >
            </SweetAlert>
        </>
    )
    
}
export default ZS;