import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FaPlus,FaMinus,FaRegWindowClose} from "react-icons/fa";
import {MdCancelPresentation} from "react-icons/md"
import SweetAlert from 'react-bootstrap-sweetalert';

const ExtraKontakty = (props) => {

    const [nextra,setNextra] = useState((props.currentCompany.extraKontakts)?props.currentCompany.extraKontakts.length:0);
    const [showDeleteKontakt, setShowDeleteKontakt] = useState(false);
    const [Index,setIndex] = useState(-1);

    function foo(array){

        return 0
    }

 
    useEffect(()=>{
 
        },
    
        []
    )


    console.log('props in kontakty:',props)
    return(
        
    // <div className = "w-100">
    // <Row>
    <>

        {Array.from(Array(nextra).keys()).map((item,index)=>(
            <React.Fragment>
                <div className = "row col-lg-12 float-right" style={{padding: "0"}}>
                    <button className=" btn btn-outline-danger div-right" style={{borderWidth: "2px"}} onClick ={()=>{setShowDeleteKontakt(true);setIndex(index)}}>
                    <FaMinus style={{fontSize: "12px", color: "white", cursor: "pointer"}}/>
                    </button>
                </div>



                    <div className="form-group col-lg-3" style={{}}>
                    <label style={{color: "white"}}>Kontaktná osoba {index+2}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="statutar"
                        // required
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].kontakt)?props.currentCompany.extraKontakts[index].kontakt:""}
                        onChange={(e) => props.onChangeExtraContact(e,'kontakt',index)}
                        name="kontakt"
                    />
                    </div>

                    <div className="form-group col-lg-3"style={{}}>
                    <label style={{color: "white"}}>Email (k. os. {index+2})</label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        // required
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].kontakt_email)?props.currentCompany.extraKontakts[index].kontakt_email:""}
                        onChange={(e) => props.onChangeExtraContact(e,'kontakt_email',index)}
                        name="kontakt_email"
                    />
                    </div>

                    <div className="form-group col-lg-3">
                    <label style={{color: "white"}}>Telefónne číslo (k. os. {index+2})</label>
                    <input
                        type="text"
                        className="form-control"
                        id="mesto"
                        // required
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].kontakt_mobil)?props.currentCompany.extraKontakts[index].kontakt_mobil:""}
                        onChange={(e) => props.onChangeExtraContact(e,'kontakt_mobil',index)}
                        name="kontakt_mobil"
                    />
                    </div>
                    
                    <div className="form-group col-lg-3">
                    <label style={{color: "white"}}>Poznámka (k. os. {index+2})</label>
                    <input
                        type="text"
                        className="form-control"
                        id="pozn1"
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].kontakt_poznamka)?props.currentCompany.extraKontakts[index].kontakt_poznamka:""}
                        onChange={(e) => props.onChangeExtraContact(e,'kontakt_poznamka',index)}
                        name="kontakt_poznamka"
                    />
                    </div>

                    <div className="form-group col-lg-3" style={{}}>
                    <label style={{color: "white"}}>Pobočka {index+2}: Ulica</label>
                    <input
                        type="text"
                        className="form-control"
                        id="statutar"
                        // required
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].ulica)?props.currentCompany.extraKontakts[index].ulica:""}
                        onChange={(e) => props.onChangeExtraContact(e,'ulica',index)}
                        name="kontakt"
                    />
                    </div>

                    <div className="form-group col-lg-3"style={{}}>
                    <label style={{color: "white"}}>Pobočka {index+2}: Mesto</label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        // required
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].mesto)?props.currentCompany.extraKontakts[index].mesto:""}
                        onChange={(e) => props.onChangeExtraContact(e,'mesto',index)}
                        name="kontakt_email"
                    />
                    </div>

                    <div className="form-group col-lg-3">
                    <label style={{color: "white"}}>Pobočka {index+2}: PSČ</label>
                    <input
                        type="text"
                        className="form-control"
                        id="mesto"
                        // required
                        value={(props.currentCompany.extraKontakts.length>index && props.currentCompany.extraKontakts[index].PSC)?props.currentCompany.extraKontakts[index].PSC:""}
                        onChange={(e) => props.onChangeExtraContact(e,'PSC',index)}
                        name="kontakt_mobil"
                    />
                    </div>
                    
                    <div className="form-group col-lg-3">
                    {/* <label style={{color: "white"}}>Poznámka (k. os. {index+2})</label>
                    <input
                        type="text"
                        className="form-control"
                        id="pozn1"
                        value={(props.currentCompany.extraKontakts.length>index)?props.currentCompany.extraKontakts[index].kontakt_poznamka:""}
                        onChange={(e) => props.onChangeExtraContact(e,'kontakt_poznamka',index)}
                        name="kontakt_poznamka"
                    /> */}
                    </div>

                </React.Fragment>
        ))}
        {/* <div className="row form-group col-lg-3"> */}
        {/* <Row className="w-100"> */}
            <button className=" btn btn-primary" style={{borderWidth: "2px",marginLeft:"1em"}} onClick ={()=>setNextra(nextra+1)}>
                <FaPlus style={{fontSize: "12px", color: "white", cursor: "pointer"}} />
            </button>
        {/* </Row> */}
        {/* </div> */}
    {/* </div> */}
    {/* </Row> */}

        <SweetAlert
            show={showDeleteKontakt}
            warning
            showCancel
            confirmBtnText="Áno, chcem vymazať!"
            cancelBtnText="Zrušiť"
            confirmBtnBsStyle="danger"
            title="Chcete naozaj vymazať vybraný kontakt?"
            onConfirm={() => {
                props.deleteExtraContant(Index);
                setNextra(nextra - 1);
                setShowDeleteKontakt(false)
            }
            }
            onCancel={()=>{
                setShowDeleteKontakt(false)
            }}
            focusCancelBtn
        >
            Vymazaný kontakt už nebude možné obnoviť!
        </SweetAlert>
    </>
    )
}
export default ExtraKontakty;