import React, { Component,hashHistory, browserHistory } from "react";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import {Router} from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import background from "./background_ready.jpg";

import "@fontsource/quicksand"; // Defaults to weight 400.

// import InitialCards from "./components/initial-cards.component";
import InitialCards from "./components/initial-cards.component_v2";
import PersonalProfile from "./components/personalProfile.component";
import EditPersonalProfile from "./components/editPersonalProfile.component";
import CreatePersonalProfile from "./components/createPersonalProfile.component";

import CreateCompany from "./components_company/createCompany.component_company";
import EditCompany from "./components_company/editCompany.component_company";
import CompanyProfile from "./components_company/companyProfile.component_company";
import ManageTechnicians from "./components_company/manageTechnicians.component_company";
import Archive from "./components_archive/archiveBase.component";

import CreateCourses from "./components_kurzy/createCourse.component";
import Filters from "./components_terminy/terminyBase.component";

import DataService from "./services/tutorial.service";

// import send_notifications from './notifications/notifications'
import send_notifications from './notifications/notifications_single_connection'


class App extends Component {
  constructor(){
    super();
    this.profile = "jb"
    this.verify_user = this.verify_user.bind(this);
    this.set_notifications_start = this.set_notifications_start.bind(this);
    this.state = {
      personal_profile: "hello",
      login_page: true,
      // login_page: Boolean(window.sessionStorage.getItem("logoff")),
      show_auth_error: "",
      username: window.sessionStorage.getItem("username"),
      password: window.sessionStorage.getItem("password"),
    }
  }

  set_notifications_start(){
    console.log('setting alert - notification sending started')
    this.setState({notifications_in_progress: true})
  }
  
  verify_user() {
      let data = {'username': this.state.username, 'password': this.state.password}
      console.log("verify user");
      DataService.verify_user(data)
        .then(response => {
          console.log('Success in login:',response.data);
          this.setState({login_page: false})
          window.sessionStorage.setItem("username",this.state.username);
          window.sessionStorage.setItem("password", this.state.password);
          window.sessionStorage.setItem("logoff", false);
          document.cookie = `token=${this.state.username}${this.state.password};`
          // window.location.href="/tutorials";
        })
        .then(async ()=>{
          console.log('notification will be sent')
          // this.setState({notifications_in_progress: true})
          try{
            // await new Promise((resolve,reject)=>{
            //   setTimeout(()=>{
            //     console.log('mimicking notification sending!')
            //     // resolve(0)
            //     reject(-1)
            //   },5000)
            // })
            // this.setState({notifications_in_progress: false,notifications_success: true})
            
            const global_status = await send_notifications(this.set_notifications_start)
            console.log('global_status send notifications:',global_status)
            if(global_status==="success"){
              this.setState({notifications_in_progress: false,notifications_success: true})
            }
            if(global_status==="failed"){
              this.setState({notifications_in_progress: false,notifications_success: false})
            }
          }catch(e){
            console.log('notification sending failed!')
            this.setState({notifications_in_progress: false,notifications_success: false})
          }
          await new Promise((resolve,reject)=>{
            // send_notifications()
            setTimeout(()=>{
              this.setState({notifications_success:null})
              resolve(0)
              reject(-1)
            },5000)
          })
          
          console.log('notification have been sent')
        })
        .catch(e => {
          console.log('Error in login:',e);
          // this.setState({login_page: false})
          this.setState({login_message: "Chyba! Nesprávne prihlasovacie údaje.",login_page: true, show_auth_error: "Nesprávne prístupové údaje"})
        });
  
  }

  setPersonalProfile(profile){
    this.setState({
      personal_profile: profile
    })
    this.profile=profile;
    console.log('current tut in App.js: ',profile,this.profile);
  }
  
  Popup() {
    alert("Are you sure you want to proceed?");
  }

  Backend() {}

  send_notifications(){
    console.log('processing notifications...')
  }

  render() {
    // console.log('background image:',background)
    console.log('this.state in App.js:',this.state)
    console.log('username+password:',window.sessionStorage.getItem("username"),window.sessionStorage.getItem("password"))
    console.log('logoff:',Boolean(window.sessionStorage.getItem("logoff")))
    console.log('window.location.origin: ',window.location.origin)
    return (


    <div className="root-div">
      {/* {this.state.login_page && this.state.login_page===true ? (  */}
        {/* {Boolean(window.sessionStorage.getItem("logoff"))===true ? (  */}
      {this.state.login_page===true && !window.sessionStorage.getItem("username") ? ( 
      <div className="background" 
      style={{marginBottom:0,borderBottom:0,paddingBottom:0,
        backgroundImage: `url(${background})`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        minHeight: '100vh',
        overflowY: "auto",
        display: "flex",
        flexFlow: "column",
        height: "100%"
      }}>
            <div className="header" >
                <h1 className="text" style={{marginTop:130}}>Prihlásenie</h1>
            </div>
            <div className="box" style={{alignSelf:"center", height:"unset"}}>
                <div>
                    <center>
                        <input
                            className
                            type="text"
                            name="findId"
                            id="IdInput"
                            placeholder="Prihlasovacie meno"
                            onChange={e => this.setState({username: e.target.value,show_auth_error:""})}
                            required
                            autocapitalize="on"
                            autofocus
                        />
                    </center>
                    <center>
                        <input
                            className
                            type="password"
                            name="password"
                            id="passwordInput"
                            placeholder="Heslo"
                            onChange={e => this.setState({password: e.target.value, show_auth_error: ""})}
                            required
                            autocapitalize="on"
                            autofocus
                        />
                        {this.state.show_auth_error && this.state.show_auth_error!=="" && <p className="error" style={{marginTop:10}}>{this.state.show_auth_error}</p>}
                    </center>
                    <center style={{marginBottom:"3em"}}>
                        <input
                            id="loginBtn"
                            type="submit"
                            class="login-btn"
                            value="Prihlásiť"
                            onClick={() => this.verify_user()}
                        />
                    </center>
                </div>
            </div>
            <footer className="footer">
                <p id="footer-text">
                    <div style={{textAlign:"right"}}>Jozef Bucko ©2022</div>
                </p>
            </footer>
        </div>

      ) : (

      <div 
        style={{ 
          backgroundImage: `url(${background})`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          minHeight: '100vh',
          overflowY: "auto",
          image:{
          opacity: 0.0,
          
          },
          display: "flex",
          flexFlow: "column",
          height: "100%"
          }}
        >

        <nav className="navbar navbar-expand navbar-dark bg-dark border-bottom border-secondary" style={{'justifyContent':"flex-end", flex: "0 1 auto"}} >
        {(this.state.notifications_in_progress && this.state.notifications_in_progress === true)?
          <div class="alert alert-warning" role="alert" style={{position:"absolute",margin:"auto"}}>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{width: "1.2rem", height: "1.2rem",marginRight:"1em"}}></span>
            Práve sa posielajú notifikácie na emailové adresy technikov, prosím, nezatvárajte aplikáciu
          </div>
        :null}
        {(this.state.notifications_success && this.state.notifications_success === true)?
          <div class="alert alert-success" role="alert" transition="fade" style={{position:"absolute",margin:"auto",transition:"ease-out"}}>
            Notifikácie úspešne odoslané
          </div>
        :null}
        {(this.state.notifications_success!==null && this.state.notifications_success === false)?
          <div class="alert alert-danger" role="alert" dismissible="true" style={{position:"absolute",margin:"auto"}}>
            Notifikácie sa nepodarilo odoslať. Prosím, nahláste túto chybu na IT support
          </div>
        :null}
          <Link to="/tutorials" className="navbar-brand">
            Domov
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                Vyhľadať
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/add"} className="nav-link">
                Nový profil
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/technicians"} className="nav-link">
                Technici
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/archive"} className="nav-link">
                Archív
              </Link>
            </li>
          </div>
          <div className="navbar-nav ml-auto">
            <li className="nav-item"> 
            <div className="btn btn-secondary" onClick={() => {
              this.setState({login_page: true,username: "",password: ""});
              window.sessionStorage.setItem("username", "");
              window.sessionStorage.setItem("password", "");
              window.sessionStorage.setItem("logoff", true);
              window.location.href = "/"
            }}
            >
                Odhlásiť
            </div>
            </li>
          </div>
        </nav>


        <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, display: "flex", flex: "1 1 auto"}}>
          <Switch history={hashHistory}>
            <Route exact path={["/", "/tutorials"]} component={InitialCards} />
            <Route exact path="/add" component={CreatePersonalProfile}/>
            <Route path="/profile" render={ () => <PersonalProfile setProfile={ this.setPersonalProfile.bind(this) }/> } />
            <Route path="/edit_profile" component={EditPersonalProfile} />

            {/* module Firmy */}
            <Route exact path="/add_company" component={CreateCompany}/>
            <Route path="/company" component={CompanyProfile}/>
            {/* {/* <Route path="/technicians" component={ManageTechnicians} username = {this.state.username} password = {this.state.password}/> */}
            <Route path="/technicians" component={ManageTechnicians}/>
            <Route path="/archive" component={Archive}/>
            <Route path="/edit_company" component={EditCompany} />

            <Route path="/courses" component={CreateCourses} />
            <Route path="/filters" component={Filters} />
          </Switch>
        </div>
      </div>
      )}

      </div>
    );
  }
}

export default App;