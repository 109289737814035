import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import {Link } from "react-router-dom";
import { BsCardText } from 'react-icons/bs';

  
  const Preukaz = (props) => {

    const [showModal,setShowModal] = useState(false)

    function get_vydanie(line){
      const parsed = JSON.parse(line)
      const alias = parsed.course_alias
      const output = parsed[alias+'_zvydanie']
      if (output==='') return "nezadané"
      else return output 
    }

    function getCourseNameFromAlias(alias,item){
      const courseName = (props.allCourses.length>0)?props.allCourses.filter(item=>item.course_alias === alias)[0].course_nazov:""
      return courseName
    }

    function getRelevantRozsahy(line){
      const parsed = JSON.parse(line)
      const alias = parsed.course_alias
      console.log('[preukaz.hook] processing line:',alias,parsed)
      const rozsahy = JSON.parse(parsed[alias+"_zrozsah"])
      console.log('[preukaz.hook] rozsahy:',rozsahy,parsed[alias+"_zrozsah"])

      if (!rozsahy) return []
      else{
        let rozsahy_valid = []
        Object.keys(rozsahy).map(key=>{
          if (rozsahy[key].includes(props.evcislo_old)){
            rozsahy_valid.push(key)
          }
        })
        return rozsahy_valid
      }
    }

    const popover = (
      <>
      </>
    );
    
    // console.log('props:',props,props.data.personal_details)
    return(
        <div className="col" style={{marginBottom:"3em"}}>
          <form >
            <div className="card text-white" style={{width: '280px', backgroundColor: 'black',margin: "auto"}}>
              <Link onClick = {() => {setShowModal(true)}} to = '/archive' className="image">
                <BsCardText className = "icon__initial"/> 
                <div className = "icon__initial__text_archiv">{props.evcislo_old}</div>
                <div className = "icon__initial__text_archiv" style={{marginTop:"1.5em"}}>{(props.data.personal_details.length>0)?`${props.data.personal_details[0].priezvisko} ${props.data.personal_details[0].meno}`:"Neznámy profil"}</div>
                <div className="image__overlay image__overlay--blur" onClick = {() => {}}>
                  <div className="image__title">{props.evcislo_old}</div>
                    <p className="image__description" style={{"textAlign":"center",margin:"0.5em"}}>
                      {(props.data.personal_details.length>0)?`${props.data.personal_details[0].priezvisko} ${props.data.personal_details[0].meno}`:"Neznámy profil"}
                    </p>
                    <p className="image__description" style={{"textAlign":"center",margin:"0.5em"}}>
                      {(props.data.personal_details.length>0)?`${props.data.personal_details[0]["zamestnávateľ"]}`:"Neznáma firma"}
                    </p>
                    <p className="image__description" style={{"textAlign":"center",margin:"0.5em"}}>
                      Archivácia: {JSON.parse(props.data.archive_line[0]).createdAt.slice(0,10)}
                    </p>
                    <Link onClick = {() => {setShowModal(true)}} to = '/archive' className="stretched-link">Detail</Link>
                </div>
              </Link>
            </div>
          </form>


          <Modal className="my-modal my-nice-scrollbar" 
              styles={{ backgroundColor: "black" }}  
              show={showModal} 
              onHide={()=>{setShowModal(false)}} 
              size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {/* <h4 style={{color:"white"}}>Preukaz zastaraný: {JSON.parse(props.data.archive_line[0]).createdAt.slice(0,10).split("-").reverse().join(".")}</h4> */}
                <h4 style={{color:"white"}}>Preukaz zastaraný: {props.data.createdAt.slice(0,10).split("-").reverse().join(".")}</h4>
              </Modal.Title>

              

            </Modal.Header>
                    
            <Modal.Body className="my-nice-scrollbar">

              <Container style={{color:'white'}}>
                <Row>
                  <Col className="col-6">
                    <h4>
                      {(props.data.personal_details.length>0)?`${props.data.personal_details[0].meno} ${props.data.personal_details[0].priezvisko}`:"Neznámy profil"}
                    </h4>
                    <br/>
                    <h5>
                      Firma:  {(props.data.personal_details.length>0)?`${props.data.personal_details[0]["zamestnávateľ"]}`:""}
                    </h5>

                    <h5>
                      Ev.číslo: {props.evcislo_old}
                    </h5>

                    <h5>
                      Dátum vydania: {get_vydanie(props.data.archive_line[0])}
                    </h5>

                    <h5>
                      Nahradené: {props.data.evcislo_new}
                    </h5>

                  </Col>

                  <Col className="col-6">
                    <ul>
                    {props.data.archive_line.map(item=>(
                      <li>
                        {getCourseNameFromAlias(JSON.parse(item).course_alias,item)}
                        <ul>
                          {getRelevantRozsahy(item).map(item2=>(
                            <li>
                              {item2}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                    </ul>
                    

                    
                  </Col>
                </Row>
                  

              </Container>
              
            </Modal.Body>
              
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
        </div>

    )
  };
export default Preukaz;