import React, { Component, useState, useEffect, useRef,useLayoutEffect } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';

import custom_os_aop from '../controls/custom_os_aop.variable';



import MultiSelect from "react-multi-select-component";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';




const VlasntnaPeriodaOS = (props) => {

    var options = arrangeCourses(props.allCourses)
    
    function getCourseNameFromAlias(alias){
        const table = props.allCourses.filter(function(item){return item.course_alias === alias})[0]
        // console.log('alias,table row filtered:',alias,table)
        const course_name = table.course_nazov
        return course_name
      }

    const [valuesRozsah,settersRozsah] = useState((props.option_array)?arr2opt(props.option_array):function(){
            let out = []
            Object.keys(custom_os_aop).forEach(alias=>{
                if (custom_os_aop[alias].os){
                    out.push({'label':getCourseNameFromAlias(alias),'value':getCourseNameFromAlias(alias)})
                }
            })
            return out
        }
    );


    function arr2opt(array){
        var converted = []
        if (array.length>0){
            array.map(item => {
                converted.push({'label': item, 'value': item})
            })
        }
        console.log('converted:',converted)
        
        return converted
    }

    function arrangeCourses(allCourses){
        var converted = []
        if (allCourses.length>0){
            allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(item => {if (item.course_OS===true) converted.push({'label': item.course_nazov, 'value': item.course_nazov})})
        }
        return converted
    }

    function convert_multiselect(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push(item["label"]))
        }
        return converted
    }


    useEffect(()=>{
        const array = convert_multiselect(valuesRozsah)
        props.onChangeCompanyData(array,"vlastna_perioda_os","yes")},[valuesRozsah]
    )

    // useEffect(()=>{
    //     settersRozsah(arr2opt(props.option_array))},[]
    // )

    console.log('props:',props)
    console.log('options chosen:',valuesRozsah)
    console.log('options from location state os:',props.option_array)

    const popoverHoverFocus = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" style={{color:'black',fontSize:20,padding:"1em",maxWidth:"400px"}}>
            Nasledujúce odbornosti majú prednastavené osobitné lehoty: 
            <ul>
                {Object.keys(custom_os_aop).map(alias=>(
                (custom_os_aop[alias].os)?<li>{getCourseNameFromAlias(alias)} ({custom_os_aop[alias].os} m.)</li>:null
                ))}
            </ul>
        </Popover>
      );

    return(
        
        <>
            {(options) ? (
    
                <form className="form-group col-lg-4">
                <label style={{color: "white"}}>Osobitná lehota OŠ: 
                <span>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="right"
                        overlay={popoverHoverFocus}
                        >
                        {/* <Button>Hover + Focus</Button> */}
                        <AiOutlineInfoCircle
                            size="1.3em"
                            style={{marginLeft:"1em"}}
                            color = 'orange'
                        />
                    </OverlayTrigger>
                </span>
                </label>
                <MultiSelect 
                options={options}
                overrideStrings={{
                    "allItemsAreSelected": "Všetky položky vybrané.",
                    "clearSearch": "Zruš hľadanie",
                    "noOptions": "Prázdny výber",
                    "search": "Hľadaj...",
                    "selectAll": "Vyber všetko",
                    "selectSomeItems": "Vyber..."
                }}
                value={valuesRozsah}
                // value = {(props.option_array)?arr2opt(props.option_array):valuesRozsah}
                // value={[{'label': 'Hi','value':'By'}]}
                onChange={settersRozsah}
                labelledBy="Select"
                
                />
                </form>
            ) : null}

               
        </>
    )
    
}
export default VlasntnaPeriodaOS;