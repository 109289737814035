import React, { Component } from "react";
import DataService from "../services/tutorial.service";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../components/styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';

import Preukaz from './preukaz.hook';

export default class Archive extends Component {
  constructor(props) {
    super(props);

    this.componentWillMount = this.componentWillMount.bind(this);
	this.matchString = this.matchString.bind(this);

    this.color = "white";

    this.state = {
		archive: {},
		allCourses: [],
		searchTitle: "",
    };
  }

  async componentWillMount() {
    console.log('in WillMount-Archive');

	DataService.getAllCourses()
	.then(response=>{
		this.setState({allCourses: response.data[0]})
	})
	.catch(err=>{
		console.log('error in retrieving all courses',err)
	})

	DataService.getArchive()
	.then(response => {
		console.log("received archive data:",response.data);
		this.setState({
			message: "The archived data were obtained successfully!"
		});
		this.setState({
			archive: response.data[0]
		});
		// collect data with keys being evcisla_old
		let archive_evcisla_as_keys  = {}
		response.data[0].forEach(archive_entry=>{
			const evcislo_old = archive_entry.archive_evcislo_old
			const evcislo_new = archive_entry.archive_evcislo_new
			if (evcislo_old in archive_evcisla_as_keys){
				// console.log('key found',evcislo_old,' in ',archive_evcisla_as_keys,archive_evcisla_as_keys[evcislo_old])
				archive_evcisla_as_keys[evcislo_old].archive_line.push(archive_entry.archive_line)
			}else{
				// console.log('person data for id:',archive_entry.person_id,response.data[0])
					// console.log('key not found',evcislo_old,' in ',archive_evcisla_as_keys)
					const entry = {'evcislo_new':evcislo_new,'archive_line':[archive_entry.archive_line],'personal_details':[],'person_id':archive_entry.person_id, 'createdAt':archive_entry.createdAt}
					archive_evcisla_as_keys[evcislo_old] = entry
					this.setState({archive_evcisla_as_keys:archive_evcisla_as_keys},console.log('updating keys:',this.state))
			}
		})
		Object.keys(archive_evcisla_as_keys).forEach(item=>{
			// console.log('item in fetch:',item,archive_evcisla_as_keys[item])
				// for new entry, fetch personal data for a given id, if id not existing, fetch returns empty array
				DataService.get(archive_evcisla_as_keys[item].person_id)
				.then(response=>{
					
					let item_updated = {...archive_evcisla_as_keys}
					// console.log('copy:',item_updated)
					item_updated[item].personal_details = (response.data[0].length>0)?response.data[0]:[{meno: "Neznámy", priezvisko: "Profil", "zamestnávateľ":"Firma Neznáma"}]
					this.setState({archive_evcisla_as_keys:item_updated})
				})
				.catch(err=>{
					console.log("archiveBase.component.js: problem retrieving personal profile id:",archive_evcisla_as_keys[item].person_id)
				})
		})
	})
	.then(()=>{
		this.setState({render:true})
	})
	.catch(e => {
	console.log('The archive fetch failed!',e);
	});
  }

  isRightSubset = (s1, s2) => {
	if (s1.size != s2.size) return false;
	const arr1 = [...s1];
	const arr2 = [...s2];

	for (let x of arr1) {

		if (!arr2.some(item=>item.includes(x))) {
		  return false;
		}else{
			const idx = arr2.indexOf(x)
			if (idx>=0){
				arr2.splice(idx,1)
			}else{
				if (arr1.length>1 && x!==arr1[arr1.length-1]) return false
			}
		}
	  }
	return true;
  }

  matchString(searchTitle,data){
	
	if (data.personal_details.length>0){
		const meno_parsed = data.personal_details[0].meno.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
		const priezvisko_parsed = data.personal_details[0].priezvisko.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
		const target = [...meno_parsed,...priezvisko_parsed]
		const search = searchTitle.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")

		const out = this.isRightSubset(search,target)

		const evcislo_old = Object.keys(this.state.archive_evcisla_as_keys).filter(key=>this.state.archive_evcisla_as_keys[key].evcislo_new === data.evcislo_new)[0]
		const evcislo_parsed = evcislo_old.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
		const out2 = this.isRightSubset(search,evcislo_parsed)

		if (out===true || out2===true){
			return true
		}else{
			return false
		}
	}else{
		return false
	}
  }

  sortArchive(a,b){
	console.log('data a:',this.state.archive_evcisla_as_keys[a])
	console.log('data b:',this.state.archive_evcisla_as_keys[b])

	const pr1 = (this.state.archive_evcisla_as_keys[a].personal_details.length>0)?this.state.archive_evcisla_as_keys[a].personal_details[0].priezvisko:""
	const pr2 = (this.state.archive_evcisla_as_keys[b].personal_details.length>0)?this.state.archive_evcisla_as_keys[b].personal_details[0].priezvisko:""
	const m1 = (this.state.archive_evcisla_as_keys[a].personal_details.length>0)?this.state.archive_evcisla_as_keys[a].personal_details[0].meno:""
	const m2 = (this.state.archive_evcisla_as_keys[b].personal_details.length>0)?this.state.archive_evcisla_as_keys[b].personal_details[0].meno:""
	const ev1 = a
	const ev2 = b



	const comp_priezvisko = pr1.localeCompare(pr2)
	if (comp_priezvisko!==0) return comp_priezvisko
	else{
		const comp_meno = m1.localeCompare(m2)
		if (comp_meno !==0) return comp_meno
		else{
			return ev1.localeCompare(ev2)
		}
	}
	return 1
  }

  render() {
	const {searchTitle} = this.state
	console.log('state:',this.state)
    return (
     
    <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, borderTop: 0}}>
		<Row className="col-sm-12" style={{marginTop: '10em'}}>
			<Col className="col-sm-1">
			</Col>
			<Col className="col-sm-10">
				<Row style={{justifyContent:"center",marginBottom:"5em"}}>
					<div style={{width:"100%"}}>
						<h3 className="row" style={{color: "white",justifyContent:"center"}}>
							Archivované preukazy
						</h3>
					</div>
					<div style={{width:"30%",marginTop:"2em"}}>
						<div className="input-group" style={{margin:"auto"}}>
				
							<input
							style={{borderRadius: '5px'}}
							type="text"
							className="form-control"
							placeholder="Hľadaj podľa mena/priezviska/ev. čísla"
							value={searchTitle}
							onChange={(e)=>{this.setState({
												searchTitle: e.target.value,
												searched: false
											},
											this.searchTitle); 
										}}
							/>
						</div>
					</div>
				</Row>
				<Row style={{justifyContent:"center"}}>
					{this.state.archive_evcisla_as_keys && Object.keys(this.state.archive_evcisla_as_keys).sort((a,b)=>this.sortArchive(a,b)).map(preukaz=>(
						// {this.state.archive_evcisla_as_keys && Object.keys(this.state.archive_evcisla_as_keys).map(preukaz=>(
						(searchTitle === "")?
							<Preukaz evcislo_old = {preukaz} data = {this.state.archive_evcisla_as_keys[preukaz]} allCourses = {this.state.allCourses}/>:
							((this.matchString(searchTitle,this.state.archive_evcisla_as_keys[preukaz])===true)?<Preukaz evcislo_old = {preukaz} data = {this.state.archive_evcisla_as_keys[preukaz]} allCourses = {this.state.allCourses}/>:null)
					))}
				</Row>
			</Col>
			<Col className="col-sm-1">
			</Col>
		</Row>
    </div>
    )
  }
}


