import React, { Component } from "react";
import DataService from "../services/tutorial.service";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav'
import Switch, { Case, Default } from 'react-switch-case';
import Modal from 'react-bootstrap/Modal';
import { InputTags } from 'react-bootstrap-tagsinput'
import "./../components/styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';

import VykonavaSa from './vykonavaSa.hook';


import axios from 'axios';



export default class CreatePersonalProfile extends Component {
  constructor(props) {
    super(props);

    this.componentWillMount = this.componentWillMount.bind(this);
    this.setActiveTech = this.setActiveTech.bind(this);
    this.createTechnician = this.createTechnician.bind(this);
    this.adjustTechnician = this.adjustTechnician.bind(this);
    this.deleteTechnician = this.deleteTechnician.bind(this);

    

    // this.manage = this.manage.bind(this);
    this.color = "white";
    

    this.state = {
      currentIndex:-1,
      currentTech: "",
      inputShow: false,
      showCreatePopup: false,
      showAdjustPopup: false,
      showDeletePopup: false,
      showDeletePopup2: false,
      createMessage: "",
      adjustMessage: "",
      noChange: false,
      deleteMessage: "",
      inputAdjust: false,
    };
  }

  componentWillMount() {
    console.log('in WillMount-getAllCourses');
      

      DataService.getAllTechnicians()
      .then(response => {
        console.log("received technicians data:",response.data);
        this.setState({
          message: "The techicians were obtained successfully!"
        });
        this.setState({
          allTechnicians: response.data[0]
        });
      })
      .catch(e => {
        console.log('The technicians fetch failed!',e);
      });
  }


  createTechnician() {
    var data = this.state;
    console.log("saveNewTechnician");
    DataService.createTechnician(data)
      .then(response => {
        console.log('Success:',response.data);
        this.setState({showCreatePopup:true,createMessage: "Success"})
      })
      .catch(e => {
        console.log(e);
        console.log('Error in create technician');
        this.setState({showCreatePopup:true,createMessage: "Error"})
      });
  }

  adjustTechnician() {
    var data = this.state;
    console.log("adjustTechnician");
    if (this.state.new_meno === this.state.currentTech.technician_meno && this.state.new_priezvisko === this.state.currentTech.technician_priezvisko && this.state.new_email === this.state.currentTech.technician_email) this.setState({noChange: true}) 
    else {
      DataService.adjustTechnician(data)
        .then(response => {
          console.log('Success:',response.data);
          this.setState({showAdjustPopup:true,adjustMessage: "Success"})
        })
        .catch(e => {
          console.log(e);
          console.log('Error in adjust technician');
          this.setState({showAdjustPopup:true,adjustMessage: "Error"})
        });
      }
  }

  deleteTechnician() {
    console.log('profile:',this.state.currentTech.id, this.state.currentIndex);
    DataService.deleteTech(this.state.currentTech.id)
      .then(response => {
        console.log(response.data);
        this.setState({showDeletePopup2:true,deleteMessage: "Success"})
        // this.refreshList();
      })
      .catch(e => {
        console.log('error detected:',e);
        this.setState({showDeletePopup2:true,deleteMessage: "Error"})
      });
  }

  setActiveTech(tech, index) {
    this.setState({
      currentTech: tech,
      currentIndex: index,
      new_meno: tech.technician_meno,
      new_priezvisko: tech.technician_priezvisko,
      new_email: tech.technician_email,
    });
    console.log('##set currect company to',tech);    
  }



  render() {
    console.log('this.state:',this.state)
    return (
     
    <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, borderTop: 0}}>
      <Row className="fluid" style={{height: '100vh'}}>
 

        <Col xs={4}></Col>
        <Col xs={4} style={{paddingTop: 15, paddingLeft: 40, paddingRight: 40,marginBottom:30}} className="my_scroll">


        <h4 className="justify-content-left" style={{color: "white",marginTop: 60}}>Zoznam technikov:</h4> 
          <br/>

          <ul className="list-group scroll_custom" style={{marginBottom:30,maxHeight:"60vh",overflowY:"scroll"}}>
              {this.state.allTechnicians  &&
                this.state.allTechnicians.sort((a, b) => {if (a.technician_priezvisko !== b.technician_priezvisko) {return a.technician_priezvisko > b.technician_priezvisko ? 1:-1} else { return a.technician_meno > b.technician_meno ? 1:-1}}).map((tech, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === this.state.currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveTech(tech, index)}
                  onDoubleClick={() => this.setState({inputAdjust: true})}
                  key={tech.technician_id}
                >
                  {tech.technician_priezvisko} {tech.technician_meno}
                </li>
                ))}
          </ul>
         
              
            
          <div style={{marginBottom: 30}} className="text-right">

            {/* {(this.state.searchCompany!=="") ?  */}
                  <Button className="m-2 btn btn-sm btn-secondary" onClick={() => this.setState({inputShow:true})} style={{fontSize:16}}>
                    Pridať technika
                  </Button>
            {(this.state.currentTech&&this.state.allTechnicians.length>0) ? 
                //  ( (this.state.searchCompany!=="") ? 
                  <Button className="m-2 btn btn-sm btn-danger" onClick={ () => this.setState({showDeletePopup: true})} style={{fontSize:16}}>
                    Odstrániť profil
                  </Button> :null}

              <Modal className="my-modal" styles={{ backgroundColor: "black" }}  show={this.state.inputShow} onHide={() => this.setState({inputShow:false})} animation={true} scrollable={true} size='sm'>
                <Modal.Header closeButton>
                  <Modal.Title style={{color: "white"}}>
                    Nový technik
                  </Modal.Title>
                </Modal.Header>
                        
                <Modal.Body className="show-grid">

                  <Container style={{color:'white'}}>
                    <Row>
                    <div className="form-group col-lg-12">
                      <label>Meno</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_meno"
                        value={this.state.currentTech.meno}
                        onChange={(e) => this.setState({new_meno: e.target.value})}
                        name="new_meno"
                      />                    
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Priezvisko</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_priezvisko"
                        value={this.state.currentTech.priezvisko}
                        onChange={(e) => this.setState({new_priezvisko: e.target.value})}
                        name="new_priezvisko"
                      />                    
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_email"
                        value={this.state.currentTech.email}
                        onChange={(e) => this.setState({new_email: e.target.value})}
                        name="new_email"
                      />                    
                    </div>

                    </Row>  
                    </Container>
                  
                </Modal.Body>
                  
                <Modal.Footer>
            
                  <div className="text-right">
                    <Button 
                      disabled = {(!this.state.new_meno || this.state.new_meno === "" || !this.state.new_priezvisko || this.state.new_priezvisko === "" || !this.state.new_email || this.state.new_email === "")?true:false}
                      className="m-2 btn btn-primary" onClick = {this.createTechnician}
                    >
                      Pridať
                    </Button>
                  </div>

                </Modal.Footer>
              </Modal>


              <Modal className="my-modal" styles={{ backgroundColor: "black" }}  show={this.state.inputAdjust} onHide={() => this.setState({inputAdjust:false})} animation={true} scrollable={true} size='sm'>
                <Modal.Header closeButton>
                  <Modal.Title style={{color: "white"}}>
                    Upraviť technika
                  </Modal.Title>
                </Modal.Header>
                        
                <Modal.Body className="show-grid">

                  <Container style={{color:'white'}}>
                    <Row>
                    <div className="form-group col-lg-12">
                      <label>Meno</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_meno"
                        value={(!this.state.new_meno || this.state.new_meno==="") ? this.state.new_meno : this.state.new_meno}
                        onChange={(e) => this.setState({new_meno: e.target.value})}
                        name="new_meno"
                      />                    
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Priezvisko</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_priezvisko"
                        // value={(!this.state.new_priezvisko || this.state.new_priezvisko==="") ? this.state.currentTech.technician_priezvisko : this.state.new_priezvisko}
                        value={(!this.state.new_priezvisko || this.state.new_priezvisko==="") ? this.state.new_priezvisko : this.state.new_priezvisko}
                        onChange={(e) => this.setState({new_priezvisko: e.target.value})}
                        name="new_priezvisko"
                      />                    
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_email"
                        value={(!this.state.new_email || this.state.new_email==="") ? this.state.new_email : this.state.new_email}
                        onChange={(e) => this.setState({new_email: e.target.value})}
                        name="new_email"
                      />                    
                    </div>

                    </Row>  
                    </Container>
                  
                </Modal.Body>
                  
                <Modal.Footer>
            
                  <div className="text-right">
                    <Button 
                      disabled = {(!this.state.new_meno || this.state.new_meno === "" || !this.state.new_priezvisko || this.state.new_priezvisko === "" || !this.state.new_email || this.state.new_email === "")?true:false}
                      className="m-2 btn btn-primary" 
                      onClick = {this.adjustTechnician}
                    >
                      Upraviť
                    </Button>
                  </div>

                </Modal.Footer>
              </Modal>

          <SweetAlert   
            show={this.state.showCreatePopup}
            type={(this.state.createMessage.slice(0,7)==="Success") ? "success" : "danger" }
            title={(this.state.createMessage.slice(0,7)==="Success") ? "Hotovo!" : "Pozor!" } 
            // success title="Hotovo!" 
            onConfirm={() => {
              console.log('confirm');
              this.setState({showCreatePopup: false,inputShow: false});
              window.location.href='/technicians';
            }
            }
            onCancel={()=>{
              console.log('cancel');
              this.setState({showCreatePopup: false, inputShow: false})
              window.location.href='/technicians';
            }}
            >
            {this.state.createMessage.slice(0,7)==="Success" ? "Technik bol úspešne pridaný do databázy!" : "Pri vytváraní profilu technika sa vyskytla chyba!"}
        </SweetAlert>

        <SweetAlert 
            show={this.state.showAdjustPopup}
            type={(this.state.adjustMessage.slice(0,7)==="Success") ? "success" : "danger" }
            title={(this.state.adjustMessage.slice(0,7)==="Success") ? "Hotovo!" : "Pozor!" } 
            onConfirm={() => {
              console.log('confirm');
              this.setState({showAdjustPopup: false, new_meno: "", new_priezvisko: ""});
              if (this.state.adjustMessage.slice(0,7)==="Success") window.location.href='/technicians';
            }
            }
            onCancel={()=>{
              console.log('cancel');
              this.setState({showAdjustPopup: false, new_meno: "", new_priezvisko: ""})
              if (this.state.adjustMessage.slice(0,7)==="Success") window.location.href='/technicians';
            }}
            >
            {this.state.adjustMessage.slice(0,7)==="Success" ? "Technik bol úspešne upravený!" : "Pri upravovaní profilu technika sa vyskytla chyba!"}
        </SweetAlert>

        <SweetAlert 
            show={this.state.noChange}
            type= "success"
            title="Hotovo!"
            onConfirm={() => {
              console.log('confirm');
              this.setState({noChange: false, inputAdjust: false});
            }
            }
            onCancel={()=>{
              console.log('confirm');
              this.setState({noChange: false, inputAdjust: false});
            }}
            >
            Technik nebol zmenený!
        </SweetAlert>

        <SweetAlert 
            show={this.state.showDeletePopup2}
            type={(this.state.deleteMessage.slice(0,7)==="Success") ? "success" : "danger" }
            title={(this.state.deleteMessage.slice(0,7)==="Success") ? "Hotovo!" : "Pozor!" } 
            onConfirm={() => {
              console.log('confirm');
              this.setState({showDeletePopup2: false});
              if (this.state.deleteMessage.slice(0,7)==="Success") window.location.href='/technicians';
            }
            }
            onCancel={()=>{
              console.log('cancel');
              this.setState({showDeletePopup2: false})
              if (this.state.deleteMessage.slice(0,7)==="Success") window.location.href='/technicians';
            }}
            >
            {this.state.deleteMessage.slice(0,7)==="Success" ? "Technik bol úspešne odstránený z databázy!" : "Pri odstraňovaní profilu technika sa vyskytla chyba!"}
        </SweetAlert>

        <SweetAlert
          show={this.state.showDeletePopup}
          warning
          showCancel
          confirmBtnText="Áno, chcem vymazať!"
          cancelBtnText="Zrušiť"
          confirmBtnBsStyle="danger"
          title="Chcete naozaj vymazať vybraný profil?"
          onConfirm={() => {
            console.log('confirm');          
            this.deleteTechnician();
            this.setState({showDeletePopup: false})
          }
          }
          onCancel={()=>{
            console.log('cancel');
            this.setState({showDeletePopup: false})
          }}
          focusCancelBtn
        >
          Vymazaný profil už nebude možné obnoviť!
          </SweetAlert>

        </div>






        </Col>
        <Col xs={4}></Col>

      </Row>
    </div>
    )
  }
}