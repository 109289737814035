import React, { Component } from "react";
import DataService from "../services/tutorial.service";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal';
import { InputTags } from 'react-bootstrap-tagsinput'
import "./../components/styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';

import VykonavaSa from './vykonavaSa.hook';
import VlasntnaPeriodaOS from './custom_period_os.hook';
import VlasntnaPeriodaAOP from './custom_period_aop.hook';
import ExtraKontakty from './kontakty.hook'

import Switch from 'react-ios-switch';


import axios from 'axios';
import { parse } from "react-view";
import swal from "sweetalert";



export default class CreatePersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.updateCompany = this.updateCompany.bind(this);
    this.final_checks = this.final_checks.bind(this);
    this.onChangeCompanyData = this.onChangeCompanyData.bind(this);
    this.componentWillMount = this.componentWillMount.bind(this);
    this.final_checks = this.final_checks.bind(this);
    this.final_checks_clear = this.final_checks_clear.bind(this);
    this.onChangeExtraContact = this.onChangeExtraContact.bind(this);
    this.deleteExtraContant = this.deleteExtraContant.bind(this);

    this.color = "white";
    

    this.state = {
      showUpdatePopup: false,
      showCompanynDefinedPopup: false,
      updateMessage: "",
      currentCompany: {
        id: "",
        nazov: "",
        ico: "",
        statutar: "",
        ulica: "",
        mesto: "",
        psc: "",
        email: "",
        mobil: "",
        vznik: "",
        pridelenie1: "",
        pridelenie2: "",
        vyznam: "",
        vyznam_checked: [0,0,0,0,0],
        velkost: "",
        velkost_checked: [0,0,0,0,0],
        popis: "",
        poznamka: "",
        perioda_os: "",
        perioda_aop: "",
        vykonava_sa: [],
        kontakt: "",
        kontakt_email: "",
        kontakt_mobil: "",
        kontakt_poznamka: "",
        vlastna_perioda_os: [],
        vlastna_perioda_aop: [],
        extraKontakts: [],
        active: true,
      },
      message: "",
      error: "",
      showCourses: {},
      checks:{}
    };
  }

  componentWillMount() {
    console.log('in WillMount-getAllCourses');



  
    DataService.getAllCompanies()
    .then(response => {
      console.log("received companies",response.data);

      this.setState({
        allCompanies: response.data[0]
      });
    })
    .catch(e => {
      console.log('The allCompanies fetch failed!',e);
    });
  
      DataService.getAllTechnicians()
      .then(response => {
        console.log("received technicians data:",response.data);
        this.setState({
          message: "The techicians were obtained successfully!"
        });
        this.setState({
          allTechnicians: response.data[0]
        });
      })
      .catch(e => {
        console.log('The technicians fetch failed!',e);
      });

      DataService.getAllCourses()
      .then(response => {
        console.log("received data",response.data);
        this.setState({
          allCourses: response.data[0]
        });
      })
      .then(()=>{
        let courseName2courseAlias = {}
        let courseAlias2courseName = {}
        this.state.allCourses.forEach(item=>{
          courseName2courseAlias[item.course_nazov] = item.course_alias
          courseAlias2courseName[item.course_alias] = item.course_nazov
        })
        this.setState({courseName2courseAlias: courseName2courseAlias,courseAlias2courseName: courseAlias2courseName})
      })
      .catch(e => {
        console.log('The courses fetch failed!',e);
      });
      console.log('location.state:',this.props.location.state)
      this.setState(function(prevState) {
        return {
          currentCompany: {
            ...prevState.currentCompany,
            id: this.props.location.state.company_id,
            nazov: this.props.location.state.company_nazov,
            nazov_orig: this.props.location.state.company_nazov,
            ico: this.props.location.state.company_ico,
            statutar: this.props.location.state.company_statutar,
            ulica: this.props.location.state.company_ulica,
            mesto: this.props.location.state.company_mesto,
            mesto_orig: this.props.location.state.company_mesto,
            psc: this.props.location.state.company_psc,
            email: this.props.location.state.company_email,
            mobil: this.props.location.state.company_mobil,
            vznik: this.props.location.state.company_vznik,
            pridelenie1: this.props.location.state.company_pridelenie1,
            pridelenie2: this.props.location.state.company_pridelenie2,
            vyznam: this.props.location.state.company_vyznam,
            vyznam_checked: [...this.state.currentCompany.vyznam_checked.slice(0,this.props.location.state.company_vyznam-1),(this.props.location.state.company_vyznam!=="") ? 1: 0,...this.state.currentCompany.vyznam_checked.slice(this.props.location.state.company_vyznam)],
            velkost: this.props.location.state.company_velkost,
            velkost_checked: [...this.state.currentCompany.velkost_checked.slice(0,this.props.location.state.company_velkost-1),(this.props.location.state.company_velkost!=="") ? 1: 0,...this.state.currentCompany.velkost_checked.slice(this.props.location.state.company_velkost)],
            popis: this.props.location.state.company_popis,
            poznamka: this.props.location.state.company_poznamka,
            perioda_os: this.props.location.state.company_period_os,
            perioda_aop: this.props.location.state.company_period_aop,
            kontakt: this.props.location.state.company_kontakt,
            kontakt_email: this.props.location.state.company_kontakt_email,
            kontakt_mobil: this.props.location.state.company_kontakt_mobil,
            kontakt_poznamka: this.props.location.state.company_kontakt_poznamka,
            extraKontakts: JSON.parse(this.props.location.state.company_kontakt_extra)?JSON.parse(this.props.location.state.company_kontakt_extra):[],
            active: this.props.location.state.active,
            vlastna_perioda_os_dict: JSON.parse(this.props.location.state.company_vlastna_perioda_os),
            vlastna_perioda_aop_dict: JSON.parse(this.props.location.state.company_vlastna_perioda_aop)
          }}},()=>this.arrangeExtraContacts2InitDict(this.props.location.state.company_kontakt_extra))

          JSON.parse(this.props.location.state.company_vykonava_sa).map((item,index)=> {this.setState(function(prevState) {
            return {
              currentCompany: {
                ...prevState.currentCompany,
                vykonava_sa: [...this.state.currentCompany.vykonava_sa, item] 
              }}}
            )
          console.log('item:',item)
          })

  }

  updateCustomDict(e,alias,os_vs_aop = null){
    let currentCompany_tmp = JSON.parse(JSON.stringify(this.state.currentCompany))
    
    if (os_vs_aop==='os') currentCompany_tmp.vlastna_perioda_os_dict[alias] = e.target.value
    if (os_vs_aop==='aop') currentCompany_tmp.vlastna_perioda_aop_dict[alias] = e.target.value

    this.setState({currentCompany:currentCompany_tmp})
  }

  arrangeExtraContacts2InitDict(extra_contacts){
    let out_dict = {}
    console.log('extra_contacts:',extra_contacts)
    if (!extra_contacts || JSON.parse(extra_contacts).length===0){
      this.setState({extra_contacts_dict:out_dict})
    }
    const parsed = (extra_contacts)?JSON.parse(extra_contacts):[]
    console.log('parsing extra_contacts:',parsed)
    parsed.map(item=>{
      const key = item.mesto
      if (key && key!=="") out_dict[key] = key
    })
    this.setState({extra_contacts_dict:out_dict})
  }

  final_checks(){
    //non-empty name
    let ok = true;
    if (!this.state.currentCompany.nazov || this.state.currentCompany.nazov==="") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_name": "Názov nezadaný!"}}})
      ok=false
    }


    //non-empty ico
    if (!this.state.currentCompany.ico || this.state.currentCompany.ico==="") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_ico": "IČO nezadané!"}}})
      ok=false
    }

    // correct format of date of birth
    else{
      if (this.state.currentCompany.ico!=="" && !this.isICO(this.state.currentCompany.ico)) {
        this.setState(function(prevState) {return {checks: {...prevState.checks,"error_ico": "Zlý formát, zadaj ako 8-ciferné číslo!"}}})
        ok=false
      }
    }


    //is company already in database?
    const company_already_defined = this.state.allCompanies.filter(item=>
      item.company_nazov === this.state.currentCompany.nazov && item.company_ico === this.state.currentCompany.ico && this.state.currentCompany.id !== item.company_id
    )
    console.log('company defined:',company_already_defined,this.state.currentCompany.nazov,this.state.currentCompany.ico)
    if (company_already_defined && company_already_defined.length>0){
      console.log('company matched:',company_already_defined)
      this.setState({showCompanynDefinedPopup: true})
      ok = false
    }

    //correct format of date of birth
    if (this.state.currentCompany.vznik!=="" && !this.isDate(this.state.currentCompany.vznik)) {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_vznik": "Zlý formát, zadaj ako DD.MM.RRRR"}}})
      ok=false
    }

    // check custom lehoty os
    Object.keys(this.state.currentCompany.vlastna_perioda_os_dict).sort((a,b)=>a.localeCompare(b,'sk')).map(item=>{
      if (!this.isPositiveInteger(this.state.currentCompany.vlastna_perioda_os_dict[item])){
        this.setState(function(prevState) {return {checks: {...prevState.checks,["error_os_"+item]: "Zlý formát, zadaj počet mesiacov"}}})
        ok=false
      }
    })

    // check custom lehoty aop
    Object.keys(this.state.currentCompany.vlastna_perioda_aop_dict).sort((a,b)=>a.localeCompare(b,'sk')).map(item=>{
      if (!this.isPositiveInteger(this.state.currentCompany.vlastna_perioda_aop_dict[item])){
        this.setState(function(prevState) {return {checks: {...prevState.checks,["error_aop_"+item]: "Zlý formát, zadaj počet mesiacov"}}})
        ok=false
      }
    })

    return ok;
  } 

  final_checks_clear(err) {
    this.setState(function(prevState) {return {checks: {...prevState.checks,[err]: ""}}})
  }

  isDate(date) {
    return /^(\d{2})(\/|-|\.)(\d{2})(\/|-|\.)(\d{4})$/.test(date);
  }

  isICO(ico) {
    return /^(\d{8})$/.test(ico);
  }


  onChangeExtraContact(e,vstup,index){


    console.log('what:',vstup)
    console.log('e:',e.target.value)
    console.log('index:',index)
    let arr = this.state.currentCompany.extraKontakts

    // when added new exxtra contact, arr[index] does not exist
    if (arr[index]){
      const value2change = arr[index].mesto
      let extra_contacts_dict_tmp = this.state.extra_contacts_dict
      const key2change = Object.keys(extra_contacts_dict_tmp).filter(item=>extra_contacts_dict_tmp[item] === value2change)[0]
      console.log('key2change,value2change:',key2change,value2change)
      if (!key2change){
        // swal("Pozor!",'Problém pri zmene názvu pobočky. Kontaktujte IT support',"warning")
        console.log('key2change does not exist')
      }else{
        extra_contacts_dict_tmp[key2change] = e.target.value
        this.setState({extra_contacts_dict:extra_contacts_dict_tmp})
      }
    }
  


    console.log('arr:',arr)
    while (arr.length<index+1){
      arr.push({})
    }

    arr[index][vstup] = e.target.value

    this.setState(function(prevState) {
      return {
        currentCompany: {
          ...prevState.currentCompany,
            extraKontakts: arr
        }
      };
    }) 
  }
  deleteExtraContant(index){
    let arr = this.state.currentCompany.extraKontakts
    console.log('original array:',arr,index)
    
    // const key2change = arr[index].mesto
    // console.log('key2change:',key2change)
    // if (key2change && key2change!==""){
    //   let extra_contacts_dict_tmp = this.state.extra_contacts_dict
    //   extra_contacts_dict_tmp[key2change] = ""
    //   this.setState({extra_contacts_dict:extra_contacts_dict_tmp})
    // }
    const value2change = arr[index].mesto
    let extra_contacts_dict_tmp = this.state.extra_contacts_dict
    const key2change = Object.keys(extra_contacts_dict_tmp).filter(item=>extra_contacts_dict_tmp[item] === value2change)[0]
    console.log('key2change,value2change:',key2change,value2change)
    if (!key2change){
      // swal("Pozor!",'Problém pri zmene názvu pobočky. Kontaktujte IT support',"warning")
      console.log('key2change does not exist')
    }else{
      extra_contacts_dict_tmp[key2change] = ""
      this.setState({extra_contacts_dict:extra_contacts_dict_tmp})
    }

    arr = arr.filter((item,idx)=> idx!==index)
    console.log('array after:',arr)

    this.setState(function(prevState) {
      return {
        currentCompany: {
          ...prevState.currentCompany,
            extraKontakts: arr
        },
      };
    })
  }


  onChangeCompanyData(e,vstup,direct='no'){
    const entry = (direct==='yes') ? e : e.target.value;
    console.log('what:',vstup)
    console.log('entry,e:',entry,e)
    console.log('this.state.currentCompany',this.state.currentCompany)

    this.setState(function(prevState) {
      return {
        currentCompany: {
          ...prevState.currentCompany,
            [vstup]: entry
        }
      };
    })

    // if vstup === vlastna_perioda_os or vlastna_perioda_aop then update also the vlastna_perioda_os_dict or vlastna_perioda_aop_dict
    if (this.state.courseName2courseAlias && vstup === 'vlastna_perioda_os' && direct === 'yes'){
      let vlastna_perioda_os_tmp = JSON.parse(JSON.stringify(this.state.currentCompany.vlastna_perioda_os_dict))
      let vlastna_perioda_os_new = {}
      entry.map(course=>{
        const alias = this.state.courseName2courseAlias[course] // convert to alias
        if (Object.keys(vlastna_perioda_os_tmp).includes(alias)) {vlastna_perioda_os_new[alias] = vlastna_perioda_os_tmp[alias]}
        else {vlastna_perioda_os_new[alias] = ""}
      })
      this.setState(function(prevState) {
        return {
          currentCompany: {
            ...prevState.currentCompany,
            vlastna_perioda_os_dict: vlastna_perioda_os_new
          }
        };
      })
    }
    if (this.state.courseName2courseAlias && vstup === 'vlastna_perioda_aop' && direct === 'yes'){
      let vlastna_perioda_aop_tmp = JSON.parse(JSON.stringify(this.state.currentCompany.vlastna_perioda_aop_dict))
      let vlastna_perioda_aop_new = {}
      entry.map(course=>{
        const alias = this.state.courseName2courseAlias[course] // convert to alias
        if (Object.keys(vlastna_perioda_aop_tmp).includes(alias)) {vlastna_perioda_aop_new[alias] = vlastna_perioda_aop_tmp[alias]}
        else {vlastna_perioda_aop_new[alias] = ""}
      })
      this.setState(function(prevState) {
        return {
          currentCompany: {
            ...prevState.currentCompany,
            vlastna_perioda_aop_dict: vlastna_perioda_aop_new
          }
        };
      })
    }
  }

  isPositiveInteger(str_number){
    return /^\+?([1-9]\d*)$/.test(str_number);
  }

  updateCompany() {
    var data = this.state;
    console.log("update company");
    DataService.update_company(this.state.currentCompany.id, data)
      .then(response => {
        console.log('write response',response.data);
        this.setState({updateMessage: "Success: The company was updated successfully"})
      })
      .catch(e => {
        console.log(e);
        console.log('hello');
        this.setState({updateMessage: "Error: The company update failed"})
      });
  }



  render() {
    const { currentCompany} = this.state;
    console.log('employee being modified:',this.state.currentCompany);
    console.log('full state:',this.state);
    console.log('sidebar:',this.state.sidebar)
    console.log('this.state.currentCompany:',this.state.currentCompany)
    console.log('company received:',this.props.location.state)
    console.log('vykonava sa PARSE:',JSON.parse(this.props.location.state.company_vykonava_sa),this.props.location.state.company_vykonava_sa)
    console.log('this.state.currentCompany:',this.state.currentCompany)



    return (
     
    <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, borderTop: 0}}>
      <Row className="fluid" style={{height: '100vh'}}>
 

        <Col xs={2}></Col>
        <Col xs={8} style={{paddingTop: 15, paddingLeft: 40, paddingRight: 40,marginBottom:30}} className="my_scroll">
          <div className="row w-100 mx-auto justify-content-center">
            <h4 style={{color: this.color}}>Upraviť firemný profil</h4>
            <div className="div-right">
                <label style={{color:"white", marginRight:"1em"}}>Aktívny profil:</label>
                <Switch
                  className="align-middle"
                  style={{marginRight: "0em",}}
                  checked={this.state.currentCompany.active}
                  handleColor="white"
                  offColor="grey"
                  onChange={() => {
                    this.setState(function(prevState) {
                      return {
                        currentCompany: {
                          ...prevState.currentCompany,
                          active: !this.state.currentCompany.active
                        }
                      };
                    });
                  }}
                  onColor="rgb(76, 217, 100)"
                />              
            </div>
          </div>
          <Row>
              <div className="form-group col-lg-4 required">
                <label style={{color: "white"}}>Názov</label>
                <input
                  type="text"
                  className="form-control"
                  id="nazov"
                  value={this.state.currentCompany.nazov}
                  onChange={(e) => {this.onChangeCompanyData(e,'nazov');this.final_checks_clear("error_name")}}
                  name="nazov"
                />
                {this.state.checks.error_name && this.state.checks.error_name!=="" && <p className="error">{this.state.checks.error_name}</p>}
              </div>

              <div className="form-group col-lg-4 required">
                <label style={{color: "white"}}>IČO</label>
                <input
                  type="text"
                  className="form-control"
                  id="meno"
                  // required
                  value={this.state.currentCompany.ico}
                  onChange={(e) => {this.onChangeCompanyData(e,'ico');this.final_checks_clear("error_ico")}}
                  name="meno"
                />
                {this.state.checks.error_ico && this.state.checks.error_ico!=="" && <p className="error">{this.state.checks.error_ico}</p>}
              </div>

              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Zmluva od</label>
                <input
                  type="text"
                  className="form-control"
                  id="vznik"
                  // required
                  value={this.state.currentCompany.vznik}
                  onChange={(e) => {this.onChangeCompanyData(e,'vznik');this.final_checks_clear("error_vznik")}}
                  name="vznik"
                  placeholder = "DD.MM.RRRR"
                />
                {this.state.checks.error_vznik && this.state.checks.error_vznik!=="" && <p className="error">{this.state.checks.error_vznik}</p>}
              </div>

          
              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Ulica</label>
                <input
                  type="text"
                  className="form-control"
                  id="ulica"
                  // required
                  value={this.state.currentCompany.ulica}
                  onChange={(e) => this.onChangeCompanyData(e,'ulica')}
                  name="ulica"
                />
              </div>
            
              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Mesto</label>
                <input
                  type="text"
                  className="form-control"
                  id="mesto"
                  // required
                  value={this.state.currentCompany.mesto}
                  onChange={(e) => this.onChangeCompanyData(e,'mesto')}
                  name="mesto"
                />
              </div>
            
              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>PSČ</label>
                <input
                  type="text"
                  className="form-control"
                  id="psc"
                  // required
                  value={this.state.currentCompany.psc}
                  onChange={(e) => this.onChangeCompanyData(e,'psc')}
                  name="psc"
                />
              </div>


              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Štatutár</label>
                <input
                  type="text"
                  className="form-control"
                  id="statutar"
                  // required
                  value={this.state.currentCompany.statutar}
                  onChange={(e) => this.onChangeCompanyData(e,'statutar')}
                  name="statutar"
                />
              </div>


              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Email (štatutár)</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  // required
                  value={this.state.currentCompany.email}
                  onChange={(e) => this.onChangeCompanyData(e,'email')}
                  name="email"
                />
              </div>

              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Telefónne číslo (štatutár)</label>
                <input
                  type="text"
                  className="form-control"
                  id="mesto"
                  // required
                  value={this.state.currentCompany.mobil}
                  onChange={(e) => this.onChangeCompanyData(e,'mobil')}
                  name="mobil"
                />
              </div>


              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Pridelenie 1</label>
                <select class="custom-select"
                  value = {this.state.currentCompany.pridelenie1}
                  onChange={e=>this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,pridelenie1: e.target.value}} })}
                >
                  <option selected>Vyber technika</option>
                  {
                  this.state.allTechnicians && this.state.allTechnicians.sort((a, b) => a.technician_priezvisko > b.technician_priezvisko ? 1:-1).map(tech=>                     
                      <option value={[tech.technician_meno+" "+tech.technician_priezvisko]}>{tech.technician_priezvisko} {tech.technician_meno}</option>
                  )
                  }
                </select>
              </div>

              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Pridelenie 2 (náhradník)</label>
                <select class="custom-select"
                  value = {this.state.currentCompany.pridelenie2}
                  onChange={e=>this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,pridelenie2: e.target.value}} })}
                >
                  <option selected>Vyber technika</option>
                  {
                  this.state.allTechnicians && this.state.allTechnicians.sort((a, b) => a.technician_priezvisko > b.technician_priezvisko ? 1:-1).map(tech=>
                    <option value={[tech.technician_meno+" "+tech.technician_priezvisko]}>{tech.technician_priezvisko} {tech.technician_meno}</option>
                  )
                  }
                </select>
              </div>


              <VykonavaSa onChangeCompanyData = {this.onChangeCompanyData} option_array = {JSON.parse(this.props.location.state.company_vykonava_sa)}/>


              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Popis</label>
                <input
                  type="text"
                  className="form-control"
                  id="popis"
                  // required
                  value={this.state.currentCompany.popis}
                  onChange={(e) => this.onChangeCompanyData(e,'popis')}
                  name="popis"
                />
              </div>


              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Lehota OŠ (v rokoch)</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="perioda_os"
                  // required
                  value={this.state.currentCompany.perioda_os}
                  onChange={(e) => this.onChangeCompanyData(e,'perioda_os')}
                  name="perioda_os"
                />
              </div>
              
              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Lehota AOP (v rokoch)</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="perioda_aop"
                  // required
                  value={this.state.currentCompany.perioda_aop}
                  onChange={(e) => this.onChangeCompanyData(e,'perioda_aop')}
                  name="perioda_aop"
                />
              </div>


              {(this.state.allCourses && this.state.courseAlias2courseName) ? <VlasntnaPeriodaOS onChangeCompanyData = {this.onChangeCompanyData} allCourses = {this.state.allCourses} option_array={Object.keys(JSON.parse(this.props.location.state.company_vlastna_perioda_os)).map(item=>this.state.courseAlias2courseName[item])}/>: null}
              {(this.state.allCourses && this.state.courseAlias2courseName) ? <VlasntnaPeriodaAOP onChangeCompanyData = {this.onChangeCompanyData} allCourses = {this.state.allCourses} option_array={Object.keys(JSON.parse(this.props.location.state.company_vlastna_perioda_aop)).map(item=>this.state.courseAlias2courseName[item])}/>: null}


              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Poznámka</label>
                <input
                  type="text"
                  className="form-control"
                  id="poznamka"
                  // required
                  value={this.state.currentCompany.poznamka}
                  onChange={(e) => this.onChangeCompanyData(e,'poznamka')}
                  name="poznamka"
                />
              </div>


              

              <div className="form-group col-lg-4 " style={{color: "white"}}>
                <label style={{color: "white"}}>Veľkosť</label>
                <br/>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckbox1" 
                    value="option1" 
                    checked = {this.state.currentCompany.velkost_checked[0]}
                    onClick = {()=>  {if (this.state.currentCompany.velkost_checked[0]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [1,0,0,0,0],velkost: 1}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,0,0],velkost: ""}} }) } 
                    }
                    />
                    <label class="form-check-label" for="inlineCheckbox1">1</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckbox2" 
                    value="option2" 
                    checked = {this.state.currentCompany.velkost_checked[1]}
                    onClick = {()=>  {if (this.state.currentCompany.velkost_checked[1]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,1,0,0,0],velkost: 2}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,0,0],velkost: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckbox2">2</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckbox3" 
                    value="option3"  
                    checked = {this.state.currentCompany.velkost_checked[2]}
                    onClick = {()=>  {if (this.state.currentCompany.velkost_checked[2]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,1,0,0],velkost: 3}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,0,0],velkost: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckbox3">3</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckbox4" 
                    value="option4" 
                    checked = {this.state.currentCompany.velkost_checked[3]} 
                    onClick = {()=>  {if (this.state.currentCompany.velkost_checked[3]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,1,0],velkost: 4}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,0,0],velkost: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckbox4">4</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckbox5" 
                    value="option5" 
                    checked = {this.state.currentCompany.velkost_checked[4]}
                    onClick = {()=>  {if (this.state.currentCompany.velkost_checked[4]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,0,1],velkost: 5}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,velkost_checked: [0,0,0,0,0],velkost: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckbox5">5</label>
                  </div>
                </div>


              <div className="form-group col-lg-4 " style={{color: "white"}}>
                <label style={{color: "white"}}>Význam</label>
                <br/>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckboxA" 
                    value="option1" 
                    checked = {this.state.currentCompany.vyznam_checked[0]}
                    onClick = {()=>  {if (this.state.currentCompany.vyznam_checked[0]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [1,0,0,0,0],vyznam: 1}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,0,0],vyznam: ""}} }) } 
                    }
                    />
                    <label class="form-check-label" for="inlineCheckboxA">A</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckboxB" 
                    value="option2" 
                    checked = {this.state.currentCompany.vyznam_checked[1]}
                    onClick = {()=>  {if (this.state.currentCompany.vyznam_checked[1]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,1,0,0,0],vyznam: 2}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,0,0],vyznam: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckboxB">B</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckboxC" 
                    value="option3"  
                    checked = {this.state.currentCompany.vyznam_checked[2]}
                    onClick = {()=>  {if (this.state.currentCompany.vyznam_checked[2]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,1,0,0],vyznam: 3}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,0,0],vyznam: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckboxC">C</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckboxD" 
                    value="option4" 
                    checked = {this.state.currentCompany.vyznam_checked[3]} 
                    onClick = {()=>  {if (this.state.currentCompany.vyznam_checked[3]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,1,0],vyznam: 4}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,0,0],vyznam: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckboxD">D</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input 
                    class="form-check-input-custom" 
                    type="checkbox" 
                    id="inlineCheckboxE" 
                    value="option5" 
                    checked = {this.state.currentCompany.vyznam_checked[4]}
                    onClick = {()=>  {if (this.state.currentCompany.vyznam_checked[4]===0) this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,0,1],vyznam: 5}} })
                                      else  this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,vyznam_checked: [0,0,0,0,0],vyznam: ""}} }) }} 
                    />
                    <label class="form-check-label" for="inlineCheckboxE">E</label>
                  </div>
                </div>
                
                
                <div className="form-group col-lg-4 ">
                </div>

                <div className="form-group col-lg-3">
                  <label style={{color: "white"}}>Kontaktná osoba</label>
                  <input
                    type="text"
                    className="form-control"
                    id="statutar"
                    // required
                    value={this.state.currentCompany.kontakt}
                    onChange={(e) => this.onChangeCompanyData(e,'kontakt')}
                    name="kontakt"
                  />
                </div>

                <div className="form-group col-lg-3">
                  <label style={{color: "white"}}>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    // required
                    value={this.state.currentCompany.kontakt_email}
                    onChange={(e) => this.onChangeCompanyData(e,'kontakt_email')}
                    name="kontakt_email"
                  />
                </div>

                <div className="form-group col-lg-3">
                  <label style={{color: "white"}}>Telefónne číslo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mesto"
                    // required
                    value={this.state.currentCompany.kontakt_mobil}
                    onChange={(e) => this.onChangeCompanyData(e,'kontakt_mobil')}
                    name="kontakt_mobil"
                  />
                </div>
                
                <div className="form-group col-lg-3 ">
                  <label style={{color: "white"}}>Poznámka</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pozn1"
                    value={this.state.currentCompany.kontakt_poznamka}
                    onChange={(e) => this.onChangeCompanyData(e,'kontakt_poznamka')}
                    name="kontakt_poznamka"
                  />
                </div>

                
                <ExtraKontakty
                  deleteExtraContant = {this.deleteExtraContant}
                  onChangeExtraContact = {this.onChangeExtraContact}
                  currentCompany = {this.state.currentCompany}
                />
              
            </Row>
              
            

            <div className="row w-75 mx-auto justify-content-center" style={{marginBottom:30}}>
            <button onClick={ () => {
                                      if (this.final_checks()===false) {console.log('some problem in fill-up');this.setState({showDataCheckPopup: true})}else{this.updateCompany()}
                                    }}
                    className="btn btn-success justify-content-right">
              Uložiť zmeny
            </button>
            <button style = {{marginLeft:10}} onClick={ () => window.location.href="/company"}
                    className="btn btn-secondary justify-content-right">              
                Zahodiť zmeny
            </button>
          </div>

          <SweetAlert 
            show={this.state.updateMessage!==""}
            type={(this.state.updateMessage.slice(0,7)==="Success") ? "success" : "danger" }
            title={(this.state.updateMessage.slice(0,7)==="Success") ? "Hotovo!" : "Pozor!" } 
            onConfirm={() => {
              this.setState({showUpdatePopup: false})
              this.setState({updateMessage: ""})
              window.location.href='/company'

            }
            }
            onCancel={()=>{
              this.setState({showUpdatePopup: false})
              this.setState({updateMessage: ""})
              window.location.href='/company'
            }}
            >
          {this.state.updateMessage.slice(0,7)==="Success" ? "Profil aktualizovaný!" : "Pri aktualizácii profilu sa vyskytla chyba!"}
      </SweetAlert>

      <SweetAlert 
            show={this.state.showDataCheckPopup===true}
            type="danger"
            title="Pozor!"
            onConfirm={() => {
              this.setState({showDataCheckPopup: false})
            }
            }
            onCancel={()=>{
              this.setState({showDataCheckPopup: false})
            }}
            >
            Skontrolujte, či sú vyplnené všetky povinné polia(*), a či majú časové dáta správny formát. 
          </SweetAlert>

          <SweetAlert 
            show={this.state.showCompanynDefinedPopup===true}
            type="danger"
            title="Pozor!"
            onConfirm={() => {
              this.setState({showCompanynDefinedPopup: false})
              this.setState({showSpinner: false});
            }
            }
            onCancel={()=>{
              this.setState({showCompanynDefinedPopup: false})
              this.setState({showSpinner: false});
            }}
            >
            Firma s daným názvom a IČO sa už v databáze nachádza.
          </SweetAlert>



        </Col>
        

        <Col className="my_scroll"  xs={2} style = {{marginTop:"4.5em",marginBottom:"4.5em"}}>
          <h4 style={{color: this.color}}>Osobitné lehoty OŠ (v mesiacoch):</h4>
          {
            this.state.courseAlias2courseName && this.state.currentCompany.vlastna_perioda_os_dict && Object.keys(this.state.currentCompany.vlastna_perioda_os_dict).sort((a,b)=>a.localeCompare(b,'sk')).map(item=>(
              <div className="kurz-chip">
                {this.state.courseAlias2courseName[item]}: 
                <input style = {{width:"50px"}} type="text" class="form-control" id="text_custom" value = {this.state.currentCompany.vlastna_perioda_os_dict[item]} onChange={(e)=>{this.updateCustomDict(e,item,'os');this.final_checks_clear("error_os_"+item)}}/>
                {this.state.checks['error_os_'+item] && this.state.checks['error_os_'+item]!=="" && <p className="error">{this.state.checks['error_os_'+item]}</p>}
              </div>
            ))
          }
          <br/>
          <h4 style={{color: this.color}}>Osobitné lehoty AOP (v mesiacoch):</h4>
          {
            this.state.courseAlias2courseName && this.state.currentCompany.vlastna_perioda_aop_dict && Object.keys(this.state.currentCompany.vlastna_perioda_aop_dict).sort((a,b)=>a.localeCompare(b,'sk')).map(item=>(
              <div className="kurz-chip">
                {this.state.courseAlias2courseName[item]}: 
                <input style = {{width:"50px"}} type="text" class="form-control" id="text_custom" value = {this.state.currentCompany.vlastna_perioda_aop_dict[item]} onChange={(e)=>{this.updateCustomDict(e,item,'aop');this.final_checks_clear("error_aop_"+item)}}/>
                {this.state.checks['error_aop_'+item] && this.state.checks['error_aop_'+item]!=="" && <p className="error">{this.state.checks['error_aop_'+item]}</p>}
              </div>
            ))
          }
        </Col>

      </Row>
    </div>
    )
  }
}