import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import {useRef} from 'react';
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import "./styles.css";
import {Link} from 'react-scroll'



class SideBarItem extends React.Component {
    // constructor(props) {
    //     super(props);
        
    // }
    handleClick = () => {
        console.log('trying to scroll to:',this.props.element_link)
        // if (this.props.element_link!=null){
        //     console.log('scolling to:',this.props.element_link)
        //     this.props.element_link.scrollIntoView()
        // }
        
        // if (this.props.ref_link.current){
        //         const y_scroll = this.props.ref_link.current.offsetTop-100
        //         console.log('scrolling to:',y_scroll)
        //         // this.props.data_column.scrollTo({left: 0, top: y_scroll,behavior: "smooth"})
        //         this.props.ref_link.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                
        //     // this.props.ref_link.current && this.props.data_column.scrollTo({left: 0, top: this.props.ref_link.current.getBoundingClientRect().top + this.props.data_column.pageYOffset -10 ,behavior: "smooth"})
        //     // this.props.ref_link.current && this.props.data_column.scrollTo({left: 0, top: 0,behavior: "smooth"})
        //     }
        
      };

    render() {
        
        
        this.props.ref_link.current && console.log('props.ref:',this.props)
        if (this.props.ref_link.current) console.log('props.ref2:',this.props.ref_link.current.offsetTop)
        return(

                <Row id="row-side-bar" className="form-group fluid" style={{ paddingLeft: 60, marginRight: 0, marginBottom: 0, backgroundColor: this.props.showCourses[this.props.profession] ? "grey" : "#343a40"}}>
                <input type="checkbox" class="form-check-input" id={this.props.profession} onClick = {() => {this.props.changeCourses(this.props.profession);this.handleClick()}} checked={this.props.showCourses[this.props.profession]}/>
                <label class="form-check-label" for={this.props.profession} style={{color: "white",paddingRight:30}}>{this.props.title}</label>
                </Row>
            
        )
    }
}
export default SideBarItem;