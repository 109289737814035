import React, { Component, useEffect, useState } from "react";
import DataService from "../services/tutorial.service";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MultiSelect from "react-multi-select-component";

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import VykonavaSa from './vykonavaSa_firmyFilters.hook'
import Vyznam from './vyznam_firmyFilters.hook'
import Pridelenie1 from './pridelenie1_firmyFilters.hook'
import Pridelenie2 from './pridelenie2_firmyFilters.hook'
import Firmy from './companySelection_osobyFilters.hook'
import Kurzy from './coursesSelection_osobyFilters.hook'
import Datumy from './datumy_osobyFilters.hook'
import Pridelenie1os from './pridelenie1_osobyFilters.hook'
import Pridelenie2os from './pridelenie2_osobyFilters.hook'




const Filter_menu = (props) => {
	
    
	
	function someFunction(){
		return 0
    }

    useEffect(() => {
    
        }, [])


	// console.log('techniciArr:',props.technici)
	// console.log('companies:',props.companies)
	// console.log('courses:',props.courses)
	// console.log('props in filters.hook:',props)
    return(

	<Tabs style={{color: "white"}}   defaultActiveKey="Profily" id="uncontrolled-tab-example" className="mb-3">


		<Tab eventKey="Profily" title="Profily" onEnter = {()=>props.chooseFilters(true,false,false)}>
			<Firmy
				companiesList = {props.companies}
				companiesListInactive = {props.companiesInactive}
				filter_companies = {props.filter_osoby_firmy}
				// filter_osoby_datum = {props.filter_datum}
			/>
			<Pridelenie1os 
				technici = {props.technici}
				filter_pridelenie1 = {props.filter_osoby_pridelenie1}
			/>
			<Pridelenie2os 
				technici = {props.technici}
				filter_pridelenie2 = {props.filter_osoby_pridelenie2}
			/>
			<Kurzy
				courses = {props.courses}
				filter_courses = {props.filter_osoby_courses}
			/>
			<Datumy
				filter_datumy = {props.filter_osoby_datumy}
				coursesSelected = {props.coursesSelected}
			/>

			</Tab>


		<Tab eventKey="Firmy" title="Firmy" onEnter = {()=>props.chooseFilters(false,true,false)}> 
			<VykonavaSa 
				filter_firmy_vykonavaSa = {props.filter_firmy_vykonavaSa}
			/>
			<Vyznam
				filter_firmy_vyznamy = {props.filter_firmy_vyznamy}
			/>
			<Pridelenie1 
				technici = {props.technici}
				filter_pridelenie1 = {props.filter_firmy_pridelenie1}
			/>
			<Pridelenie2 
				technici = {props.technici}
				filter_pridelenie2 = {props.filter_firmy_pridelenie2}
			/>
		  
		</Tab>


		<Tab eventKey="Technici" title="Technici" onEnter = {()=>props.chooseFilters(false,false,true)}>
		  
		</Tab>
	</Tabs>
    )
    
}
export default Filter_menu;

