import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import MultiSelect from "react-multi-select-component";


const Datumy = (props) => {

    var options = [
                
                {'label': 'zmeškané do dnes', 'value': 'zmeškané do dnes'},
                {'label': 'aktuálny mesiac', 'value': 'aktuálny mesiac'},
                {'label': 'aktuálny+nasledujúci mesiac', 'value': 'aktuálny+nasledujúci mesiac'},
                {'label': 'aktuálny+nasledujúce 2 mesiace', 'value': 'aktuálny+nasledujúce 2 mesiace'},
                {'label': 'tento rok', 'value': 'tento rok'},
                {'label': 'zvoľ dátum', 'value': 'zvoľ dátum'},
                ]

    const [rozsahArr1,setRozsahArr1] = useState(props.option_array ? arr2opt(props.option_array) : []);
    const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

    const valuesRozsah = rozsahArr1
    const settersRozsah = setRozsahArr1

    function arr2opt(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push({'label': item, 'value': item}))
        }
        return converted
    }

    function convert_multiselect(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push(item["label"]))
        }
        return converted
    }

    function setEmpty(){
        settersRozsah([])
    }

    useEffect(()=>{
        console.log('datumy selected: ',valuesRozsah)
        if (valuesRozsah.some(item=>item.label==='zvoľ dátum')){
            props.filter_datumy(valuesRozsah,startDate,endDate)
        }else{
            props.filter_datumy(valuesRozsah)
        }
    }
    ,[valuesRozsah,startDate,endDate]
    )

    useEffect(()=>{
        if (props.coursesSelected.length===0){
            settersRozsah([])
        }
    }
    ,[props.coursesSelected]
    )


    // console.log('options chosen2:',valuesRozsah)
    // console.log('options from location state:',props.option_array)
    // console.log('coursesSelected:',props.coursesSelected)
    // console.log('options in datum:',options)
    return(
        <>
        <div> 
                {(options) ? (
        
                    <form className="form-group col-lg-8" >
                    <label style={{color: "white"}}>V období:</label>
                    <MultiSelect 
                    disabled={(props.coursesSelected.length>0)?false:true}
                    options={options}
                    overrideStrings={{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Vyber..."
                    }}
                    value={valuesRozsah}
                    onChange={settersRozsah}
                    labelledBy="Select"
                    onClick = {console.log('show Popup')}
                    
                    />
                    </form>
                ) : null}

               
        </div>
        {(valuesRozsah.some(item=>item.label==='zvoľ dátum'))?(
            <>
            <form className="form-group col-lg-8" style={{color:'white',marginTop:'2em'}}>
            Od:  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>
            </form>
            <form className="form-group col-lg-8" style={{color:'white',marginTop:'2em'}}>
            Do:  <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} />
            </form>
            </>
        ):null}
        </>
    )
    
}
export default Datumy;