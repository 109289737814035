import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';



import MultiSelect from "react-multi-select-component";


const VykonavaSa = (props) => {

    var options = [
                {'label': 'BTS', 'value': 'BTS'},
                {'label': 'školenia', 'value': 'školenia'},
                {'label': 'VVZ', 'value': 'VVZ'},
                {'label': 'revízie', 'value': 'revízie'},
                {'label': 'koordinácia', 'value': 'koordinácia'},
                {'label': 'TPO', 'value': 'TPO'},
                {'label': 'kontroly HP a hydrantov', 'value': 'kontroly HP a hydrantov'},
                {'label': 'PZS', 'value': 'PZS'},
                {'label': 'CO', 'value': 'CO'},
                
                ]

    const [rozsahArr1,setRozsahArr1] = useState(props.option_array ? arr2opt(props.option_array) : []);

    const valuesRozsah = rozsahArr1
    const settersRozsah = setRozsahArr1

    function arr2opt(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push({'label': item, 'value': item}))
        }
        return converted
    }

    function convert_multiselect(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push(item["label"]))
        }
        return converted
    }

    useEffect(()=>{
        props.onChangeCompanyData(convert_multiselect(valuesRozsah),"vykonava_sa","yes")},[valuesRozsah]
        )


    console.log('options chosen:',valuesRozsah)
    console.log('options from location state:',props.option_array)
    return(
        
        <>
                {(options) ? (
        
                    <form className="form-group col-lg-4">
                    <label style={{color: "white"}}>Vykonáva sa:</label>
                    <MultiSelect 
                    options={options}
                    overrideStrings={{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Vyber..."
                    }}
                    value={valuesRozsah}
                    onChange={settersRozsah}
                    labelledBy="Select"
                    
                    />
                    </form>
                ) : null}

               
        </>
    )
    
}
export default VykonavaSa;