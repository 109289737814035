import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8080/api",
  baseURL: "/api",
  // baseURL: "https://ondrej-database-deploy-1.herokuapp.com/api/tutorials",
  // baseURL: process.env.baseURL || "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json",
    // "Content-type": "multipart/form-data"
    authorization: window.sessionStorage.getItem("username")+window.sessionStorage.getItem("password")
  }
});