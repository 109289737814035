import React, { Component} from "react";
import TutorialDataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import SweetAlert from 'react-bootstrap-sweetalert';
import Badge from 'react-bootstrap/Badge';
import { saveAs } from 'file-saver';
import Font, {Text} from 'react-font';
import { CloseButton } from "react-bootstrap";
import "./styles.css";
import { throws } from "assert";
import Switch from 'react-ios-switch';
import { OverlayTrigger, Popover} from 'react-bootstrap';

import { Document, Page } from 'react-pdf';
// import { BlobProvider, PDFDownloadLink,Document } from '@react-pdf/renderer'

import { FaExclamationCircle} from "react-icons/fa";
import {BsCheckCircle,BsCheckCircleFill,BsXCircleFill} from "react-icons/bs";
import {MdCancel} from "react-icons/md"
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Checkbox } from "@progress/kendo-react-inputs";
const {stringToTime} = require('../controls/functions')





const Fs = require('fs')  
const Path = require('path')  
const Axios = require('axios')
export default class PersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.removeTutorial = this.removeTutorial.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    this.searchTitleLocal = this.searchTitleLocal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.getCurrentFull = this.getCurrentFull.bind(this);
    this.download = this.download.bind(this);
    this.archiveManually = this.archiveManually.bind(this);
    // this.componentWillMount = this.componentWillMount.bind(this);
    this.cleanPreukaz = this.cleanPreukaz.bind(this);
  

    this.state = {
      selectCompanyWide: false,
      searchByCompany: false,
      display_odbornosti: true,
      showDeletePopup: false,
      showDeletePopup2: false,
      showDeletePopup2Success: false,
      showArchivePopup: false,
      showActivityFailPopup: false,
      showActivitySuccessPopup: false,
      showArchiveFailedPopup: false,
      showArchiveSuccessPopup: false,
      showSpinnerArchive: false,
      tutorials: [],
      tutorials_all: [],
      profileRetrieved: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
      showTable: true,
      searched: false,
      show: false,
      isHovered: false,
      showPDF: false,

    };
    this.nSkoleni=[1,2,3];
    this.colArr=["#872397","green","orange","#00bcd4","#fccb00"]
    this.fontSize = 14
  }

  async archiveManually(){
    let data = {'id':this.state.currentTutorial.id,'data':this.state.toArchive}
    console.log('archiving:',data)
    try{
      const response = await TutorialDataService.archiveManually(data)
      console.log('archivation processed:',response.status)
      if (response.status === 200) {
        this.setState({showArchiveSuccessPopup:true,toArchive:{}})
      }
      if (response.status === 500) {
        this.setState({showArchiveFailedPopup:true,toArchive:{}})
      }
    }catch(err){
      console.log('manual archivation failed:',err)
      this.setState({showArchiveFailedPopup:true,toArchive:{}})
    }
  }

  cleanPreukaz = ()=>{Object.keys(this.state).map( key => {console.log('key:',key);if (key.includes("preukaz")) {console.log('key:',key);this.setState({[key]: undefined}) } } )  }

  // componentWillMount() {
  //   console.log('in WillMount-getAllCourses');
        
        
  //   TutorialDataService.getAllCourses()
  //     .then(response => {
  //       console.log("received data",response.data);
  //       this.setState({
  //         message: "The courses were obtained successfully!"
  //       });
  //       this.setState({
  //         allCourses: response.data[0]
  //       });

  //     })
  //     .catch(e => {
  //       console.log('The allCourses fetch failed!',e);
  //     });
  // }

  download(url){


    // var FileSaver = require('file-saver');
    // FileSaver.saveAs(url, 'image.jpg',{headers: {'authorization': window.sessionStorage.getItem("username")+window.sessionStorage.getItem("password"),}})

    // var file = new Blob(url, 'image.jpg',{headers: {'authorization': window.sessionStorage.getItem("username")+window.sessionStorage.getItem("password"),}})
    // var file = new File([this.state.id_photo.data],{ type: 'image/*' })
    // let blob = await fetch(url).then(r => r.blob());

    // FileSaver.saveAs(blob,'image.jpg',{headers: {'authorization': window.sessionStorage.getItem("username")+window.sessionStorage.getItem("password"),}})
    
    
    // const path = Path.resolve(__dirname, 'files', 'code.jpg')
    // const writer = Fs.createWriteStream(path)
  
    // const response =  Axios({
    //   url,
    //   method: 'GET',
    //   responseType: 'stream'
    // })
  
    // response.data.pipe(writer)
  
    // return new Promise((resolve, reject) => {
    //   writer.on('finish', resolve)
    //   writer.on('error', reject)
    // })
    // console.log('in download!')
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': window.sessionStorage.getItem("username")+window.sessionStorage.getItem("password")
      }
    }).then(console.log('success'))
    .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "image.jpeg";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });
  
  }


  getCurrentFull(){
    let name;
    console.log('this show:',this.state.show)
    TutorialDataService.get(this.state.currentTutorial.id)
    .then(response => {
      this.setState({
        profileRetrieved: response.data[0][0],
      });
      console.log('fetched full current profile: response:',response.data[0][0]);
      let preukazy_odb = {}
      Object.keys(response.data[0][0]).forEach(key=>{
        // select keys *_zevcislo
        const key_end = key.slice(-8)
        if (key_end === "zevcislo" && response.data[0][0][key]){
          const evcislo = response.data[0][0][key]
          console.log('person has preukaz here:',key,evcislo)
          const alias = key.slice(0,-9)
          if (evcislo in preukazy_odb) preukazy_odb[evcislo].push(alias) 
          else preukazy_odb[evcislo] = [alias] 
        }
      })
      console.log('final preukaz-odb mapping:',preukazy_odb)
      this.setState({preukazy_odb:preukazy_odb})
    })
    .then(
      console.log('retrieved data, gathering data for preukaz-sorted view and for archiving:',this.state.profileRetrieved)
      //
      
      //
    )
    .then( ()=> {
      name = this.state.profileRetrieved.meno+this.state.profileRetrieved.priezvisko+this.state.profileRetrieved["dátum narodenia"]
      console.log("filename of ID photo: ",name)
      TutorialDataService.getFile(name)
      .then(response => {
        console.log('response from getFile: ',response)
        console.log('response stringify:',response.data.slice(-20))
        console.log('extension:',response.data.slice(-4))
        if (response.data.slice(1,4)==="PNG") this.setState({id_photo_ext: ".png"})
        if (response.data.slice(1,4)==="JPG") this.setState({id_photo_ext: ".jpg"})
        if (response.data.slice(1,5)==="JPEG") this.setState({id_photo_ext: ".jpeg"})
        
        this.setState({id_photo: response})
      })
      .catch(error => console.log('error from getFile: ',error))
      }
    )
    .then(()=>{
      this.state.allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(course => {
        if (this.state.profileRetrieved[course.course_alias+"_zevcislo"]) {
          this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((evcislo,index) =>{
            const nameScan = this.state.profileRetrieved.meno+this.state.profileRetrieved.priezvisko
                            +this.state.profileRetrieved["dátum narodenia"]
                            +"_"+course.course_alias
                            +["_preukaz"+(index+1)] + ".pdf"
            console.log('name_scan:',nameScan)
            TutorialDataService.getFile(nameScan)
            .then(response => {
              console.log('response from getFile Scan2: ',response)
              if (response.data!=="") {this.setState({[course.course_alias+"_preukaz_"+(index+1)]: response})}
            })
            .catch(error => console.log('error from getFile Scan: ',error))
          })
        }
      })
    })
    .catch(e => {
      console.log(e);
    });
    
  }


  handleClose(){
    this.setState({show: false});
  }

  handleShow(){
    this.setState({show: true});
    console.log('handleShow:',this.state.show)

    console.log('trying to fetch the data:',this.state.currentTutorial)
    this.getCurrentFull();
  }

  componentDidMount() {
    this.retrieveTutorials();
    
    // retrieve courses
    TutorialDataService.getAllCourses()
    .then(response => {
      console.log("received data",response.data);
      this.setState({
        message: "The courses were obtained successfully!"
      });
      this.setState({
        allCourses: response.data[0]
      });

    })
    .catch(e => {
      console.log('The allCourses fetch failed!',e);
    });
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
      searched: false
    });
    console.log('onChangeSearchTitle: ',searchTitle);
  }

  retrieveTutorials() {
    TutorialDataService.getAll()
      .then(response => {
        this.setState({
          tutorials: response.data,
          tutorials_all: response.data
        });
        console.log("retrieveTutorials: success");
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index
    });
    console.log('##setting currect tutorial');
    this.props.setProfile(tutorial);
    console.log('##set currect tutorial to',tutorial);    

  }

  setProfileToSend(){
  this.props.setProfile(this.state.currentTutorial);
  }

  removeAllTutorials() {
    TutorialDataService.deleteAll()
      .then(response => {
        console.log(response.data);
        this.refreshList();
      })
      .catch(e => {
        console.log(e);
      });
  }

  changeActivity() {
    // change activity of profile(s)
    console.log('changing activity of profile(s):')
    const change_ids = Object.keys(this.state.profiles_checked).filter(item=>this.state.profiles_checked[item] === true)
    TutorialDataService.change_multiple(change_ids)
      .then(response => {
        console.log('activity of multiple profiles changed');
        this.setState({showActivitySuccessPopup: true})
      })
      .catch(e => {
        console.log('activity change failed with',e);
        this.setState({showActivityFailPopup: true})
      });
  }

  removeTutorial() {
    // standard removal of a single profile
    if (this.state.nchecked<2){  
      console.log('profile:',this.state.currentTutorial.id, this.state.currentIndex);
      TutorialDataService.delete(this.state.currentTutorial.id)
        .then(response => {
          console.log('removed single profile:',response.data);
          this.setState({showDeletePopup2Success: true})
          // this.refreshList();
        })
        .catch(e => {
          console.log('deletion failed with',e);
          this.setState({showDeletePopup2: true})
        });
    }else{
      console.log('removing multiple profiles:')
      const remove_ids = Object.keys(this.state.profiles_checked).filter(item=>this.state.profiles_checked[item] === true)
      TutorialDataService.delete_multiple(remove_ids)
        .then(response => {
          console.log('multiple profiles deleted:',response.data);
          this.setState({showDeletePopup2Success: true})
          // this.refreshList();
        })
        .catch(e => {
          console.log('deletion failed with',e);
          this.setState({showDeletePopup2: true})
        });
    }
  }

  searchTitle() {
    TutorialDataService.findByName(this.state.searchTitle)
      .then(response => {
        this.setState({
          tutorials: response.data,
          searched: true
        });
        console.log('searchTitle response:',response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  isRightSubset = (s1, s2) => {
    if (s1.size != s2.size) return false;
    const arr1 = [...s1];
    const arr2 = [...s2];
  
    for (let x of arr1) {
  
      if (!arr2.some(item=>item.includes(x))) {
        return false;
      }else{
        const idx = arr2.indexOf(x)
        // console.log('index,arr2',idx,arr2)
        if (idx>=0){
          // console.log('index,arr2',idx,arr2)
          arr2.splice(idx,1)
        }else{
          if (arr1.length>1 && x!==arr1[arr1.length-1]) return false
        }
      }
      }
    return true;
    }

  countCheckedProfiles() {
    var count = 0
    for (const [key, value] of Object.entries(this.state.profiles_checked)) {
     if (value === true) count += 1 
    }
    console.log('nchecked:',count)
    this.setState({nchecked: count})
  }
  
  searchTitleLocal() {
    // process search string
    const search = (this.state.searchTitle)?this.state.searchTitle.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" "):""
    console.log('searched string:',search)
    
    let data_send = []
    this.state.tutorials_all.forEach(item=>{
      
      let out = null
      if (this.state.searchByCompany === false){
        const meno_parsed = item.meno.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
        const priezvisko_parsed = item.priezvisko.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
        const target = [...meno_parsed,...priezvisko_parsed]
        // console.log('matching:',search,target)
        out = this.isRightSubset(search,target)  
      }else{
        // console.log('item,zamestnávateľ',item,item["zamestnávateľ"])
        if (item["zamestnávateľ"]){
          const company = item["zamestnávateľ"].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
          const target = [...company]
          // console.log('matching:',search,target)
          out = this.isRightSubset(search,target)  
        }else{
          out = false
        }
      }
      // console.log('target,search,out:',target,search,out)
      if (out===true) data_send.push(item)
    
    })
        
    this.setState({
      tutorials: data_send,
      searched: true,
      profiles_checked: Object.assign({}, ...data_send.map((x) => ({[x.id]: false}))),
      nchecked: 0
    });
    // console.log('searchTitle string:',this.state.searchTitle,' results:',data_send);
  }

  sort_profiles(a,b){
    if (a.priezvisko !== b.priezvisko) {
      return a.priezvisko > b.priezvisko ? 1:-1
    }else{
      if (a.meno !== b.meno)  {
        return a.meno > b.meno ? 1:-1
      } else {
        return a["dátum narodenia"]> b["dátum narodenia"]? 1:-1
      }
    }
  }

  sort_profiles_company(a,b){
    if (a["zamestnávateľ"] !== b["zamestnávateľ"]){
      return a["zamestnávateľ"] > b["zamestnávateľ"] ? 1:-1
    }else{
      if (a.priezvisko !== b.priezvisko) {
        return a.priezvisko > b.priezvisko ? 1:-1
      }else{
        if (a.meno !== b.meno)  {
          return a.meno > b.meno ? 1:-1
        } else {
          return a["dátum narodenia"]> b["dátum narodenia"]? 1:-1
        }
      }
    }
  }

  


  render() {

  function Overlay(props) {
  const state = true
  if (state) {
  return <OverlayTrigger 
    trigger={['hover','focus']} 
    placement="right" 
    overlay={
      <Popover>
        <Popover.Title as="h3">Profil neaktívny</Popover.Title>
      </Popover>
    }
    >
    {(state)?<FaExclamationCircle color = "red"/>:null} 
  </OverlayTrigger>
  } else {
    return null
  }
}

    const { searchTitle, tutorials, currentTutorial, currentIndex } = this.state;
    // console.log('tuts:',tutorials)
    const {vals} = this.state.profileRetrieved
    console.log('searched title:',searchTitle)
    // console.log('profileRetrieved:',this.state.profileRetrieved,this.state.profileRetrieved.id)
    console.log('full state in personalProfile:',this.state)
    // if (this.state.profileRetrieved["zz_ba1_zrozsah"]) {console.log('vlba:',Object.values(JSON.parse(this.state.profileRetrieved["zz_ba1_zrozsah"])).every(x=>x.length===0) )}
    console.log('object keys:',Object.keys([]),Object.keys({}),Object.keys(this.state.profileRetrieved).length)
  
    return(
      <div className="list row justify-content-center" style={{marginTop: 50}}>
        <div  className="col-12" style={{textAlign: "right"}}>
          <div>
            <label style={{color:"white", marginBottom:"1em",marginRight: "1em"}}>Prehľadávať podľa firmy:</label>
            <Switch
              className="align-middle"
              style={{marginRight: "3em",}}
              checked={this.state.searchByCompany}
              handleColor="white"
              offColor="grey"
              onChange={() => {
                this.setState(function(prevState) {
                  return {
                    searchByCompany: !prevState.searchByCompany,
                    searchTitle: ""
                  };
                });
              }}
              onColor="rgb(76, 217, 100)"
            />
          </div>
          <div>
            <label style={{color:"white", marginBottom:"3em",marginRight: "1em"}}>Vybrať celé firmy:</label>
            <Switch
              disabled = {(this.state.searchByCompany === false)?true:false}
              className="align-middle"
              style={{marginRight: "3em",}}
              checked={this.state.selectCompanyWide}
              handleColor="white"
              offColor="grey"
              onChange={() => {
                this.setState(function(prevState) {
                  return {
                    selectCompanyWide: !prevState.selectCompanyWide,
                    searchTitle: ""
                  };
                });
              }}
              onColor="rgb(76, 217, 100)"
            />
          </div>
         
        </div>
        <div className="col-md-8 ">
          <div className="input-group mb-3">
            
            {/* Search by title field */}
            <input
              disabled={(this.state.tutorials_all.length == 0)?true:false}
              style={{borderRadius: '5px'}}
              type="text"
              className="form-control"
              placeholder={(this.state.searchByCompany)?"Hľadaj podľa firmy":"Hľadaj podľa mena/priezviska"}
              value={(this.state.tutorials_all.length == 0)?"Načítavam...":searchTitle}
              // onChange={this.onChangeSearchTitle}
              onChange={(e)=>{this.setState({
                                searchTitle: e.target.value,
                                searched: false
                            },
                            // this.searchTitle); 
                            this.searchTitleLocal); 
                        }}              
                        
            />

            
            <Link to={{pathname: "/add"}}  className="btn btn-secondary" style={{marginLeft: 30}}>Nový profil</Link>

          </div>
        </div>

        {/* tutorial list  */}
        <div className=" w-100">
          <h4 className="justify-content-left" style={{color: "white",marginTop: 60}}>Výsledky vyhľadávania:
          </h4>

          <ul className="list-group scroll_custom" style={{marginBottom:30,maxHeight:"60vh",overflowY:"scroll"}}>
            {tutorials && this.state.searched && 
              tutorials.sort((a, b) => {
                if (this.state.searchByCompany === false){
                  return this.sort_profiles(a,b)
                }else{
                  return this.sort_profiles_company(a,b)
                }
              }).map((tutorial, index) => (
                <li
                  className={
                    "list-group-item" +
                    (index === currentIndex ? " active" : "")
                  }
                  onClick={() => this.setActiveTutorial(tutorial, index)}
                  onDoubleClick={() => this.handleShow()}
                  // 
                  key={tutorial.id}
                >
                  <div>
                    <input 
                      id = {index}
                      name = {"checkbox"+index}
                      type = "checkbox"
                      className="list-profiles"
                      checked = {this.state.profiles_checked[tutorial.id]}
                      onChange = {()=>{
                        // tick all the profiles of a given company
                        if (this.state.searchByCompany === true && this.state.selectCompanyWide){
                          const sharedCompanyProfiles = this.state.tutorials_all.filter(item=> item["zamestnávateľ"] && item["zamestnávateľ"] === tutorial["zamestnávateľ"])
                          console.log('sharedCompanyProfiles:',sharedCompanyProfiles)
                          let profiles_checked_tmp = JSON.parse(JSON.stringify(this.state.profiles_checked))
                          sharedCompanyProfiles.forEach(item=>profiles_checked_tmp[item.id] = !profiles_checked_tmp[item.id])
                          this.setState({profiles_checked: profiles_checked_tmp},()=>this.countCheckedProfiles())
                        }else this.setState({
                          profiles_checked: {...this.state.profiles_checked,[tutorial.id]:!this.state.profiles_checked[tutorial.id]}
                          },
                          ()=>this.countCheckedProfiles())
                      }}
                    />
                    <label for = {index}>
                      {(!this.state.searchByCompany)?[tutorial.priezvisko+' '+tutorial.meno + '  ']:[tutorial.priezvisko+' '+tutorial.meno + ' ('+tutorial["zamestnávateľ"] + ' )  ']} 
                      {/* {(tutorial.active===false)?<FaExclamationCircle style={{fontSize: "20px", color: "red", cursor: "pointer"}}/>:null} */}
                      {(tutorial.active===false)?<Overlay/>:null}
                    </label>
                  </div>
                </li>
              ))}
          </ul>

          
          {currentTutorial ? (
            <>
              <div style={{marginBottom: 30}} className="text-right">
                <br/>
                {(this.state.searchTitle!=="" && this.state.nchecked>0) ? 
                <Button className="m-2 btn btn-sm btn-warning" onClick={ () => this.changeActivity()} style={{fontSize:16}}>
                  Zmeniť aktivitu
                </Button> : null}
                
                {(this.state.searchTitle!=="" && this.state.nchecked>0) ? 
                <Button className="m-2 btn btn-sm btn-danger" onClick={ () => this.setState({showDeletePopup: true})} style={{fontSize:16}}>
                  Odstrániť
                </Button> : null}

                {(this.state.searchTitle!=="") ? 
                <Button className="m-2 btn btn-sm btn-secondary" onClick={this.handleShow} style={{fontSize:16}}>
                  Zobraziť osobné údaje
                </Button> : null}

                

              </div>

              
                <SweetAlert
                  show={this.state.showDeletePopup}
                  warning
                  showCancel
                  confirmBtnText="Áno, chcem vymazať!"
                  cancelBtnText="Zrušiť"
                  confirmBtnBsStyle="danger"
                  title="Chcete naozaj vymazať vybrané profily?"
                  onConfirm={() => {
                    console.log('confirm');          
                    this.removeTutorial();
                    this.setState({showDeletePopup: false})
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showDeletePopup: false})
                  }}
                  focusCancelBtn
                >
                  Vymazané profily už nebude možné obnoviť!
                </SweetAlert>

                <SweetAlert
                  show={this.state.showDeletePopup2}
                  warning
                  confirmBtnText="Ok"
                  confirmBtnBsStyle="danger"
                  title="Pri vymazávaní profilov sa vyskytla chyba!"
                  onConfirm={() => {
                    console.log('confirm');          
                    this.setState({showDeletePopup2: false})
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showDeletePopup2: false})
                  }}
                  focusCancelBtn
                >
                  Prosím, nahláste tento problém.
                </SweetAlert>

                <SweetAlert
                  show={this.state.showActivityFailPopup}
                  warning
                  confirmBtnText="Ok"
                  confirmBtnBsStyle="danger"
                  title="Pri zmene aktivity profilov sa vyskytla chyba!"
                  onConfirm={() => {
                    console.log('confirm');          
                    this.setState({showActivityFailPopup: false})
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showActivityFailPopup: false})
                  }}
                  focusCancelBtn
                >
                  Prosím, nahláste tento problém.
                </SweetAlert>

                <SweetAlert
                  show={this.state.showActivitySuccessPopup}
                  success
                  confirmBtnText="Ok"
                  confirmBtnBsStyle="success"
                  title="Aktivita profilov úspešne zmenená"
                  onConfirm={() => {
                    console.log('confirm');          
                    this.setState({showActivitySuccessPopup: false})
                    window.location.href="/profile"
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showActivitySuccessPopup: false})
                    window.location.href="/profile"
                  }}
                  focusCancelBtn
                >
                  
                </SweetAlert>

                <SweetAlert
                  show={this.state.showDeletePopup2Success}
                  success
                  confirmBtnText="Ok"
                  confirmBtnBsStyle="success"
                  title="Profily boli úspešne odstránené z databázy"
                  onConfirm={() => {
                    console.log('confirm');          
                    this.setState({showDeletePopup2Success: false})
                    window.location.href="/profile"
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showDeletePopup2Success: false})
                    window.location.href="/profile"
                  }}
                  focusCancelBtn
                >
                  
                </SweetAlert>
                

              <Modal className="my-modal my-nice-scrollbar" 
                     styles={{ backgroundColor: "black" }}  
                     show={this.state.show} 
                     onHide={()=>{this.handleClose();this.setState({profileRetrieved: [],currentTutorial: null,id_photo: "", display_odbornosti:true}); this.cleanPreukaz()}} 
                     animation={true}  
                     size='lg'
                >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {(this.state.id_photo && this.state.id_photo!=="") ? 
                      <div
                        className="imageContainer"
                        onMouseOver={() => this.setState({isHovered: true})}
                        onMouseLeave={() => this.setState({isHovered: false})}
                      >
                        <img style = {{width: "200px",height: "200px", border: "4px solid black"}}
                          // src={require('../files/smith_icon.png').default}
                          src={this.state.id_photo.data!==''?this.state.id_photo.config.baseURL+this.state.id_photo.config.url:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtj_sjTE-ysDaNvfV3aNpRE1m2bPYzYC9ALg&usqp=CAU"}
                          className="card-img-top"
                          alt="..."
                        />
                        {this.state.isHovered && this.state.id_photo.data!=="" && (
                          <Button className="btn btn-custom"
                            size="sm"
                            style={{
                              // borderColor:(this.state.isHovered) ? "black" : "white",
                              borderWidth:"3px",
                              backgroundColor: "#503483",
                              borderTopLeftRadius: "5px",
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              position: "absolute",
                              top: "182px",
                              left: "139px",
                            }}
                            variant="outline-secondary"
                            // onClick={() => this.download('https://cdn.britannica.com/15/189715-050-4310222B/Dubai-United-Arab-Emirates-Burj-Khalifa-top.jpg')}
                            onClick={() => this.download(this.state.id_photo.config.baseURL+this.state.id_photo.config.url)}
                          >
                            Stiahnuť
                          </Button>
                        )}
                      </div>
                    :  
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    }
                  </Modal.Title>

                  <div text-align='left' className='mx-auto justify-content-center' style={{color:'white',borderLeft: 0}}>
                  {(this.state.currentTutorial.active===false)?<h4 style ={{color:"red" }}>Profil neaktívny</h4>:null}
                    <span style={{fontWeight: 'bold'}}>Osobné údaje:</span>
                    <br/>
                    Meno a priezvisko: {(currentTutorial.titul) ? (currentTutorial.titul) : null} {currentTutorial.meno} {currentTutorial.priezvisko}
                    <br/>
                    Dátum narodenia: {currentTutorial["dátum narodenia"]}
                    <br/>
                    <br/>
                    <span style={{fontWeight: 'bold'}}>Adresa:</span>
                    <br/>
                    {(currentTutorial["ulica"]) ? (currentTutorial["ulica"]+","):null} {(currentTutorial["mesto"]) ? (currentTutorial["mesto"]+",") : null} {currentTutorial["PSC"]}
                    <br/>
                    <br/>
                    <span style={{fontWeight: 'bold'}}>Kontakt:</span>
                    <br/>
                    Email: {currentTutorial["email"]}
                    <br/>
                    Mobil: {currentTutorial["mobil"]} 
                    <br/>
                    <br/>
                    <span style={{fontWeight: 'bold'}}>Zamestnávateľ:</span>
                    <br/>
                    {currentTutorial.zamestnávateľ}
                    <br/>
                    <br/>
                    <span style={{fontWeight: 'bold'}}>Pobočka:</span>
                    <br/>
                    {currentTutorial.pobocka}
                    <br/>
                    <br/>
                    Profesia: 
                    <br/>
                    {/* <textarea style={{borderRadius: "5px", marginLeft:"0em", backgroundColor: "white"}} value={currentTutorial["profesia"]} disabled> </textarea>  */}
                    <span style={{borderRadius: "5px", marginLeft:"0em",fontStyle: "italic"}}> {currentTutorial["profesia"]}</span> 
                    <br/>
                    
                    Poznámka:
                    <br/>
                    {/* <textarea style={{borderRadius: "5px", marginLeft:"0em", backgroundColor: "white"}} value={currentTutorial["poznamka"]} disabled> </textarea>  */}
                    <span style={{borderRadius: "5px", marginLeft:"0em",fontStyle: "italic"}}> {currentTutorial["poznamka"]}</span> 
                    <br/>
                  </div>

                </Modal.Header>
                        
                <Modal.Body className="show-grid my-nice-scrollbar">

                  <Container style={{color:'white'}}>

                    {this.state.profileRetrieved.length===0 ? (
                      console.log('spinner should be shown'),
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      ) : null}
                    
                    
                    {(Object.keys(this.state.profileRetrieved).length === 0)?
                      null:
                      <BootstrapSwitchButton 
                        checked={this.state.display_odbornosti} 
                        onstyle="primary" 
                        offstyle="info" 
                        onlabel='Odbornosti'
                        offlabel='Preukazy'
                        onChange={()=>this.setState({display_odbornosti: !this.state.display_odbornosti})}
                        width = {120}
                      />
                    }
                    



                    {this.state.display_odbornosti && this.state.allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(course => (                          
                      (this.state.profileRetrieved[course.course_alias+"_zskuska"]) ? (
                      <div>
                        <Row>
                          <Col className="col-7" style={{fontWeight: 'bold',marginTop:40}}>
                            {course.course_nazov} {(this.state.profileRetrieved[course.course_alias+"_active"] === true)?<BsCheckCircleFill style={{color: "#55dd55"}}/>:<BsXCircleFill style={{color: "#ed3838"}}/>}
                          </Col>
                          <Col className="col-5 text-right" style={{marginTop:40,marginBottom:5}}>
                              { (this.state.profileRetrieved[course.course_alias+"_zevcislo"] && this.state.profileRetrieved[course.course_alias+"_zevcislo"]!=="") ? 
                                this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((evcislo,index) =>
                                (this.state[course.course_alias+"_preukaz_"+(index+1)]) ?
                                <button className="btn-custom-scans"
                                  style = {{backgroundColor: this.colArr[index], opacity: 1}}
                                  // onClick = {()=>this.setState({showPDF: true})}
                                  onClick = {()=>{
                                    if (this.state[course.course_alias+"_preukaz_"+(index+1)].data.slice(1,4)==="PDF") window.open(this.state[course.course_alias+"_preukaz_"+(index+1)].config.baseURL+this.state[course.course_alias+"_preukaz_"+(index+1)].config.url, "_blank",'noopener,noreferrer')
                                    else this.download(this.state[course.course_alias+"_preukaz_"+(index+1)].config.baseURL+this.state[course.course_alias+"_preukaz_"+(index+1)].config.url)//console.log("preukaz is not in a .pdf format")
                                  }
                                }

                                >
                                   Stiahnuť preukaz {/*{index+1}*/}
                                </button> : null
                              ): null}
                              
                          </Col>

                        </Row>



                      {(this.state.profileRetrieved[course.course_alias+"_zrozsah"] && this.state.profileRetrieved[course.course_alias+"_zrozsah"]!=="null") ? (                      
                        <Row className="border border-secondary" style={{marginLeft:2,marginRight:2,paddingTop:10,paddingBottom:20}}>
                          <br/>
                            <p className="w-100" style={{fontWeight: 'bold',textAlign:"center"}}>Základný kurz</p>
                          <br/>
                          <br/>
                          <Row className="w-100">


                            <Col className="border-right border-secondary" style={{marginLeft:10, borderTop: '5px'}}>
                              <Row>
                                <Col className="col-5">Dátum skúšky:</Col> 
                                <Col className="col-7"><span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {this.state.profileRetrieved[course.course_alias+"_zskuska"]} </span> </Col>
                                <br/>
                              </Row>
                              <br/>
                              {(this.state.profileRetrieved[course.course_alias+"_zevcislo"]) ? (
                                (this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").length===1) ? ( 
                                  <Row>
                                    <Col className="col-5">Ev. číslo:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((cislo,index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize, overflowWrap: "anywhere"}} class="badge badge-pill badge-primary"> {cislo} </span>)}</Col>
                                  <br/>
                                  </Row>
                                ) :
                                (
                                  (this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").length>1) ? ( 
                                    <Row>
                                    <Col className="col-5">Ev. čísla:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((cislo, index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize, overflowWrap: "anywhere"}} class="badge badge-pill badge-primary"> {cislo} </span>)}</Col>
                                    <br/>
                                    </Row>
                                  ) : null
                                )
                              ) 
                              : null
                              // (<Row><Col>Ev. číslo:</Col></Row>) 
                              }
                              <br/>



                              {(this.state.profileRetrieved[course.course_alias+"_zvydanie"]) ? (
                                (this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").length===1) ? ( 
                                  <Row>
                                  <Col className="col-5">Dátum vydania:</Col> 
                                  <Col className="col-7">{this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").map(datum => <span style={{marginRight:5, backgroundColor:this.colArr[0], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                  <br/>
                                  </Row>
                                ) :
                                (
                                  (this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").length>1) ? ( 
                                    <Row>
                                    <Col className="col-5">Dátum vydania:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").map((datum, index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                    <br/>
                                    </Row>
                                  ) : null
                                )
                              ) 
                              : null
                              // (<Row><Col>Preukaz od:</Col></Row>) 
                              }
                              <br/>
                              <Row>
                                <Col className="col-5">Poznámka:</Col> 
                                <Col className="col-7"><span style={{marginRight:5}} > {this.state.profileRetrieved[course.course_alias+"_poznamka"]} </span> </Col>
                                <br/>   
                              </Row>
                            </Col>

                            <Col>
                              <br/>
                                <>
                                Rozsahy:<br/>
                                {Object.entries(JSON.parse(this.state.profileRetrieved[course.course_alias+"_zrozsah"])).map(([key,value])=>(
                                  (value.length>0)?(
                                  <Row className="align-items-center">
                                    <Col className="col-7"><Text italic>- {key}</Text></Col> 
                                    <Col className="col-5">{value.map((evcisla,index) => <span style={{marginRight:5, backgroundColor:this.colArr[this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").indexOf(evcisla)], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {evcisla} </span>)}</Col> 
                                    <br/>
                                  </Row>
                                  ):null
                                )
                                )}
                                </>

                            </Col>
                          </Row>
                          <br/>
                          <br/>
                        </Row>
                      ):(
                        <Row className="border border-secondary" style={{marginLeft:2,marginRight:2,paddingTop:10,paddingBottom:20}}>
                          <br/>
                            <p className="w-100" style={{fontWeight: 'bold',textAlign:"center"}}>Základný kurz</p>
                          <br/>
                          <br/>
                          <Row className="w-100">




                            <Col className="border-right border-secondary" style={{marginLeft:10, borderTop: '5px'}}>
                              <Row>
                                <Col className="col-5">Dátum skúšky:</Col> 
                                <Col className="col-7"><span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {this.state.profileRetrieved[course.course_alias+"_zskuska"]} </span> </Col>
                                <br/>
                              </Row>
                              <br/>
                              {(this.state.profileRetrieved[course.course_alias+"_zevcislo"]) ? (
                                (this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").length===1) ? ( 
                                  <Row>
                                    <Col className="col-5">Ev. číslo:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((cislo,index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize, overflowWrap: "anywhere"}} class="badge badge-pill badge-primary"> {cislo} </span>)}</Col>
                                  <br/>
                                  </Row>
                                ) :
                                (
                                  (this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").length>1) ? ( 
                                    <Row>
                                    <Col className="col-5">Ev. čísla:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((cislo, index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize, overflowWrap: "anywhere"}} class="badge badge-pill badge-primary"> {cislo} </span>)}</Col>
                                    <br/>
                                    </Row>
                                  ) : null
                                )
                              ) 
                              : null
                              // (<Row><Col>Ev. číslo:</Col></Row>) 
                              }
                              <br/>

                            </Col>

                            <Col>

                              {(this.state.profileRetrieved[course.course_alias+"_zvydanie"]) ? (
                                (this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").length===1) ? ( 
                                  <Row>
                                  <Col className="col-7">Dátum vydania:</Col> 
                                  <Col className="col-5">{this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").map(datum => <span style={{marginRight:5, backgroundColor:this.colArr[0], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                  <br/>
                                  </Row>
                                ) :
                                (
                                  (this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").length>1) ? ( 
                                    <Row>
                                    <Col className="col-7">Dátum vydania:</Col> 
                                    <Col className="col-5">{this.state.profileRetrieved[course.course_alias+"_zvydanie"].split(";;").map((datum, index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                    <br/>
                                    </Row>
                                  ) : null
                                )
                              ) 
                              : null
                              // (<Row><Col>Preukaz od:</Col></Row>) 
                              }
                              <br/>
                              <Row>
                                <Col className="col-7">Poznámka:</Col> 
                                <Col className="col-5"><span style={{marginRight:5}} > {this.state.profileRetrieved[course.course_alias+"_poznamka"]} </span> </Col>
                                <br/>   
                              </Row>
                            </Col>

                          </Row>
                          <br/>
                          <br/>
                        </Row>
                      )
                      }
                      <br/>
                      {/* <Col  className="col-5"> */}
                      {(course.course_OS) ? (
                        <Row className="border border-secondary" style={{marginLeft:2,marginRight:2,paddingTop:10,paddingBottom:20}}>
                          <br/>
                            <p className="w-100" style={{fontWeight: 'bold',textAlign:"center"}}>Opakované školenie</p>
                          <br/>
                        
                          <Row className="w-100">
                            <Col className="border-right border-secondary" style={{marginLeft:10, borderTop: '5px'}}>
                              <Row>
                                <Col className="col-5">Aktuálne OŠ:</Col> 
                                {(!(this.state.profileRetrieved[course.course_alias+"_zevcislo"]) || this.state.profileRetrieved[course.course_alias+"_zevcislo"]==="") ? 
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predOS"])[0].sort(stringToTime).slice(0,1).map((date,index)=> <span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {date} </span>)}</Col>
                                : 
                                
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predOS"]).map((predOS,index)=> ((predOS.length>0) ? 
                                  <span style={{marginRight:5, backgroundColor:this.colArr[index-1], fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {predOS.sort(stringToTime)[0]} </span>:null)    )}</Col>
                                }
                                <br/>
                              </Row>
                            </Col>
                          
                            <Col>
                              <Row>
                                <Col className="col-7">Predchádzajúce OŠ:</Col> 
                                {(!(this.state.profileRetrieved[course.course_alias+"_zevcislo"]) || this.state.profileRetrieved[course.course_alias+"_zevcislo"]==="") ? 
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predOS"])[0].sort(stringToTime).slice(1,JSON.parse(this.state.profileRetrieved[course.course_alias+"_predOS"])[0].length).map((date,index)=> <span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {date} </span>)}</Col>
                                : 
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predOS"]).map((predOS,index)=> ((predOS.length>0) ? 
                                  (predOS.sort(stringToTime).slice(1,predOS.length).map(date=><span style={{marginRight:5, backgroundColor:this.colArr[index-1], fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {date} </span>) ): null )
                                  )
                                  }</Col>
                                }
                                <br/>
                              </Row>
                            </Col>
                          </Row>
                          <br/>
                        </Row>
                      ) : null}


                      <br/>
                      {(course.course_AOP) ? (
                        <Row className="border border-secondary" style={{marginLeft:2,marginRight:2,paddingTop:10,paddingBottom:20}}>
                          <br/>
                            <p className="w-100" style={{fontWeight: 'bold',textAlign:"center"}}>Aktualizačná odborná príprava</p>
                          <br/>

                          <Row className="w-100">
                            <Col className="border-right border-secondary" style={{marginLeft:10, borderTop: '5px'}}>
                              <Row>
                                <Col className="col-5">Aktuálna AOP:</Col> 
   
                                  {(!(this.state.profileRetrieved[course.course_alias+"_zevcislo"]) || this.state.profileRetrieved[course.course_alias+"_zevcislo"]==="") ? 
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predAOP"])[0].sort(stringToTime).slice(0,1).map((date,index)=> <span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {date} </span>)}</Col>

                                : 
                                
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predAOP"]).map((predAOP,index)=> ((predAOP.length>0) ? 
                                  <span style={{marginRight:5, backgroundColor:this.colArr[index-1], fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {predAOP.sort(stringToTime)[0]} </span>:null)    )}</Col>
                                }
                                <br/>
                              </Row>
                            </Col>


                            <Col>
                              <Row>
                                <Col className="col-7">Predchádzajúca AOP:</Col> 
                                  {(!(this.state.profileRetrieved[course.course_alias+"_zevcislo"]) || this.state.profileRetrieved[course.course_alias+"_zevcislo"]==="") ? 
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predAOP"])[0].sort(stringToTime).slice(1,JSON.parse(this.state.profileRetrieved[course.course_alias+"_predAOP"])[0].length).map((date,index)=> <span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {date} </span>)}</Col>
                                : 
                                  <Col className="col-5">{JSON.parse(this.state.profileRetrieved[course.course_alias+"_predAOP"]).map((predAOP,index)=> ((predAOP.length>0) ? 
                                  (predAOP.sort(stringToTime).slice(1,predAOP.length).map(date=><span style={{marginRight:5, backgroundColor:this.colArr[index-1], fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {date} </span>) ): null )
                                  )
                                  }</Col>
                                }
                                <br/>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      ) : null}
                          
                          
                      <p style={{marginLeft:-48,marginRight:-48}} ><hr style={{height: 7}}/></p>
                      </div>
                      ):null)
                  )}





                  {!this.state.display_odbornosti && Object.keys(this.state.preukazy_odb).sort().map(evcislo_preukaz => 
                    (<div>
                      <Row>
                        <Col className="col-7" style={{fontWeight: 'bold',marginTop:40, fontSize: 20}}>
                          Ev. číslo: {evcislo_preukaz}
                        </Col>
                        <Col className="col-5 text-right" style={{marginTop:40,marginBottom:5}}>
                          <button className="btn-custom-scans"
                              style = {{backgroundColor: "orange", opacity: 1}}
                              onClick = {()=>{this.setState({showArchivePopup:true,toArchive:{'aliases':this.state.preukazy_odb[evcislo_preukaz],'evcislo':evcislo_preukaz}})}}
                            >
                              {(this.state.showSpinnerArchive === true)?<span class="spinner-border spinner-border-sm" style={{}}></span>:"Archivovať"}
                          </button>
                        </Col>
                      
                      </Row>
                      
                      
                      {this.state.preukazy_odb[evcislo_preukaz].sort().map(course_alias=>
                      (this.state.profileRetrieved[course_alias+"_zskuska"]) ? (
                      <div>
                        <Row>
                          <Col className="col-7" style={{fontWeight: 'bold',marginTop:40}}>
                            {this.state.allCourses.filter(item=>item.course_alias === course_alias)[0].course_nazov} {(this.state.profileRetrieved[course_alias+"_active"] === true)?<BsCheckCircleFill style={{color: "#55dd55"}}/>:<BsXCircleFill style={{color: "#ed3838"}}/>}
                          </Col>
                          <Col className="col-5 text-right" style={{marginTop:40,marginBottom:5}}>
                              { (this.state.profileRetrieved[course_alias+"_zevcislo"] && this.state.profileRetrieved[course_alias+"_zevcislo"]!=="") ? 
                                this.state.profileRetrieved[course_alias+"_zevcislo"].split(";;").map((evcislo,index) =>
                                (this.state[course_alias+"_preukaz_"+(index+1)]) ?
                                <button className="btn-custom-scans"
                                  style = {{backgroundColor: this.colArr[index], opacity: 1}}
                                  // onClick = {()=>this.setState({showPDF: true})}
                                  onClick = {()=>{
                                    if (this.state[course_alias+"_preukaz_"+(index+1)].data.slice(1,4)==="PDF") window.open(this.state[course_alias+"_preukaz_"+(index+1)].config.baseURL+this.state[course_alias+"_preukaz_"+(index+1)].config.url, "_blank",'noopener,noreferrer')
                                    else this.download(this.state[course_alias+"_preukaz_"+(index+1)].config.baseURL+this.state[course_alias+"_preukaz_"+(index+1)].config.url)//console.log("preukaz is not in a .pdf format")
                                  }
                                }

                                >
                                  Stiahnuť preukaz {/*index+1*/}
                                </button> : null
                              ): null}
                              
                          </Col>

                        </Row>



                        {(this.state.profileRetrieved[course_alias+"_zrozsah"] && this.state.profileRetrieved[course_alias+"_zrozsah"]!=="null") ? (                      
                          <Row className="border border-secondary" style={{marginLeft:2,marginRight:2,paddingTop:10,paddingBottom:20}}>
                            <br/>
                              <p className="w-100" style={{fontWeight: 'bold',textAlign:"center"}}>Základný kurz</p>
                            <br/>
                            <br/>
                            <Row className="w-100">


                              <Col className="border-right border-secondary" style={{marginLeft:10, borderTop: '5px'}}>
                                <Row>
                                  <Col className="col-5">Dátum skúšky:</Col> 
                                  <Col className="col-7"><span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {this.state.profileRetrieved[course_alias+"_zskuska"]} </span> </Col>
                                  <br/>
                                </Row>
                                <br/>
                                
                                {(this.state.profileRetrieved[course_alias+"_zvydanie"]) ? (
                                  (this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").length===1) ? ( 
                                    <Row>
                                    <Col className="col-5">Dátum vydania:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").map(datum => <span style={{marginRight:5, backgroundColor:this.colArr[0], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                    <br/>
                                    </Row>
                                  ) :
                                  (
                                    (this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").length>1) ? ( 
                                      <Row>
                                      <Col className="col-5">Dátum vydania:</Col> 
                                      <Col className="col-7">{this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").map((datum, index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                      <br/>
                                      </Row>
                                    ) : null
                                  )
                                ) 
                                : null
                                // (<Row><Col>Preukaz od:</Col></Row>) 
                                }
                                <br/>
                                <Row>
                                  <Col className="col-5">Poznámka:</Col> 
                                  <Col className="col-7"><span style={{marginRight:5}} > {this.state.profileRetrieved[course_alias+"_poznamka"]} </span> </Col>
                                  <br/>   
                                </Row>
                              </Col>

                              <Col>
                                <br/>
                                  <>
                                  Rozsahy:<br/>
                                  {Object.entries(JSON.parse(this.state.profileRetrieved[course_alias+"_zrozsah"])).map(([key,value])=>(
                                    (value.length>0)?(
                                    <Row className="align-items-center">
                                      <Col className="col-7"><Text italic>- {key}</Text></Col> 
                                      <Col className="col-5">{value.map((evcisla,index) => <span style={{marginRight:5, backgroundColor:this.colArr[this.state.profileRetrieved[course_alias+"_zevcislo"].split(";;").indexOf(evcisla)], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {evcisla} </span>)}</Col> 
                                      <br/>
                                    </Row>
                                    ):null
                                  )
                                  )}
                                  </>

                              </Col>
                            </Row>
                            <br/>
                            <br/>
                          </Row>
                        ):(
                          <Row className="border border-secondary" style={{marginLeft:2,marginRight:2,paddingTop:10,paddingBottom:20}}>
                            <br/>
                              <p className="w-100" style={{fontWeight: 'bold',textAlign:"center"}}>Základný kurz</p>
                            <br/>
                            <br/>
                            <Row className="w-100">




                              <Col className="border-right border-secondary" style={{marginLeft:10, borderTop: '5px'}}>
                                <Row>
                                  <Col className="col-5">Dátum skúšky:</Col> 
                                  <Col className="col-7"><span style={{marginRight:5, fontSize: this.fontSize}} class="badge badge-pill badge-secondary"> {this.state.profileRetrieved[course_alias+"_zskuska"]} </span> </Col>
                                  <br/>
                                </Row>
                                <br/>
                                
                                {(this.state.profileRetrieved[course_alias+"_zvydanie"]) ? (
                                  (this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").length===1) ? ( 
                                    <Row>
                                    <Col className="col-5">Dátum vydania:</Col> 
                                    <Col className="col-7">{this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").map(datum => <span style={{marginRight:5, backgroundColor:this.colArr[0], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                    <br/>
                                    </Row>
                                  ) :
                                  (
                                    (this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").length>1) ? ( 
                                      <Row>
                                      <Col className="col-7">Dátum vydania:</Col> 
                                      <Col className="col-5">{this.state.profileRetrieved[course_alias+"_zvydanie"].split(";;").map((datum, index) => <span style={{marginRight:5, backgroundColor:this.colArr[index], fontSize: this.fontSize}} class="badge badge-pill badge-primary"> {datum} </span>)}</Col>
                                      <br/>
                                      </Row>
                                    ) : null
                                  )
                                ) 
                                : null
                                }
                                <br/>

                              </Col>

                              <Col>

                                
                                <Row>
                                  <Col className="col-7">Poznámka:</Col> 
                                  <Col className="col-5"><span style={{marginRight:5}} > {this.state.profileRetrieved[course_alias+"_poznamka"]} </span> </Col>
                                  <br/>   
                                </Row>
                              </Col>

                            </Row>
                            <br/>
                            <br/>
                          </Row>
                        )
                        }
                        <br/>
                            
                        {/* <p style={{marginLeft:-48,marginRight:-48}} ><hr style={{height: 7}}/></p> */}
                      </div>
                      ):null)}
                      <p style={{marginLeft:-48,marginRight:-48}} ><hr style={{height: 7}}/></p>
                    </div>
                    )
                  )}
                     
                  </Container>
                  
                </Modal.Body>
                  
                <Modal.Footer>
            
                  <div className="text-right">
                    <Link 
                      to={{pathname: "/edit_profile", state: this.state.profileRetrieved, whole: this.state}}  
                      className={(Object.keys(this.state.profileRetrieved).length === 0)?"btn btn-primary mt-auto disabled":"btn btn-primary mt-auto"}
                    >
                      Upraviť
                    </Link>
                  </div>

                </Modal.Footer>
              </Modal>
            </>
          ): null}

        </div>

        <SweetAlert
          show={this.state.showArchivePopup}
          danger
          showCancel
          confirmBtnText="Áno, archivovať!"
          cancelBtnText="Zrušiť"
          confirmBtnBsStyle="danger"
          title="Chcete naozaj archivovať vybraný preukaz?"
          onConfirm={() => {
            this.setState({showArchivePopup: false,showSpinnerArchive:true})
            this.archiveManually()
          }
          }
          onCancel={()=>{
            this.setState({showArchivePopup: false})
          }}
          focusCancelBtn
        >
          Dáta príslušné vybranému preukazu budú odstránené z osobného profilu!
        </SweetAlert>

        <SweetAlert
          show={this.state.showArchiveSuccessPopup}
          success
          confirmBtnText="Ok"
          confirmBtnBsStyle="primary"
          // title="Chcete naozaj archivovať vybraný preukaz?"
          onConfirm={() => {
            this.setState({showArchiveSuccessPopup: false, showSpinnerArchive: false})
            window.location.href="/profile"
          }
          }
          onCancel={()=>{
            this.setState({showArchiveSuccessPopup: false, showSpinnerArchive: false})
            window.location.href="/profile"
          }}
          focusCancelBtn
        >
          Preukaz úspešne archivovaný!
        </SweetAlert>

        <SweetAlert
          show={this.state.showArchiveFailedPopup}
          danger
          confirmBtnText="Ok"
          confirmBtnBsStyle="primary"
          // title="Chcete naozaj archivovať vybraný preukaz?"
          onConfirm={() => {
            this.setState({showArchiveFailedPopup: false, showSpinnerArchive: false})
          }
          }
          onCancel={()=>{
            this.setState({showArchiveFailedPopup: false, showSpinnerArchive: false})
          }}
          focusCancelBtn
        >
          Pri archivovaní preukazu sa vyskytla chyba!
        </SweetAlert>

      </div>
    );
  }
}