import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import "./styles.css";


class SideBarItem extends React.Component {

    render() {
        return(
            <Row className="form-group fluid" style={{ paddingLeft: 60, marginRight: 0, marginBottom: 0, backgroundColor: (this.props.showCourses[this.props.profession] === true) ? "grey" : "#343a40"}}>
            <input type="checkbox" defaultChecked={this.props.defaultChecked} class="form-check-input" id={this.props.profession} onClick = {() => this.props.changeCourses(this.props.profession)} checked = {(this.props.showCourses[this.props.profession] === true) ? true : false}/>
            <label class="form-check-label" for={this.props.profession} style={{color: "white",paddingRight:30}}>{this.props.title}</label>
            </Row>
        )
    }
}
export default SideBarItem;