import React, { Component } from "react";
// import { View } from "react-native";
import {View, PropTypes} from 'react-view';
import {Link } from "react-router-dom";


import { BsFillCalendarWeekFill } from "react-icons/bs";
import { BsCalendar3 } from "react-icons/bs";
import { BsFillPersonBadgeFill } from "react-icons/bs";

import { RiBuilding2Fill } from "react-icons/ri";


import { GiTeacher } from 'react-icons/gi';


import icon_calendar from "./../Calendar-1559935.jpeg";


export default class InitialCards extends Component {

  render() {

    return (
     
  
    <div className="d-flex" style={{minWidth:'100vw'}}>
    {/* <div> */}
      <br/><br/>
      <div className="row w-85 mx-auto align-items-center justify-content-center" style={{marginTop:"0em"}}>

        <div className="row">
          <div className="col" style={{padding: "3em"}}>
            {/* <form style={{width:"280px"}}> */}
            <form>
              <div className="card text-white link" style={{width: '280px',backgroundColor:"black",margin: "auto"}}>
              <Link onClick = {() => this.hide_cards} to = '/profile' className="image">
                  <BsFillPersonBadgeFill className="icon__initial"/>
                  <div className="icon__initial__text">Osobný profil</div>
                  <div className="image__overlay image__overlay--blur" onClick = {() => this.hide_cards} href = '/profile'>
                    <div className="image__title">Osobný profil</div>
                    <p className="image__description">
                      Prezeranie, modifikovanie a pridávanie osobných údajov do datábazy osôb
                    </p>
                    <Link onClick = {() => this.hide_cards} to = '/profile' className="stretched-link">Vstúpiť</Link>
                  </div>
              </Link>
              </div>
              </form>
          </div>

          <div className="col" style={{padding: "3em"}}> 
            <form >
              <div className="card text-white" style={{width: '280px',backgroundColor:"black",margin: "auto"}}>
                <Link onClick = {() => this.hide_cards} to = '/company' className="image">
                  <RiBuilding2Fill className = "icon__initial"/> 
                  <div className = "icon__initial__text">Firemný profil</div> 
                  <div className="image__overlay image__overlay--blur" onClick = {() => this.hide_cards} >
                    <div className="image__title">Firemný profil</div>
                    <p className="image__description">
                    Prezeranie firemných detailov, pridávanie, modifikovanie firemných údajov
                    </p>
                    <Link onClick = {() => this.hide_cards} to = '/company' className="stretched-link">Vstúpiť</Link>
                  </div>
                </Link>
              </div>
              </form>
          </div>
        </div>

        <div className="row">
          <div className="col" style={{padding: "3em"}}>
            <form >
              <div className="card text-white" style={{width: '280px', backgroundColor: 'black',margin: "auto"}}>
                <Link onClick = {() => this.hide_cards} to = '/courses' className="image">
                {/* <Link onClick = {() => this.hide_cards} to = '/' className="image"> */}
                  <GiTeacher className = "icon__initial"/> 
                  <div className = "icon__initial__text">Kurzy</div>
                  <div className="image__overlay image__overlay--blur" onClick = {() => this.hide_cards} href = '/profile'>
                    <div className="image__title">Kurzy</div>
                    <p className="image__description">
                      Vytváranie nových kurzov a školení  a zostavovanie príslušných zoznamov účastníkov  
                    </p>
                    <Link onClick = {() => this.hide_cards} to = '/courses' className="stretched-link">Vstúpiť</Link>
                    {/* <Link onClick = {() => this.hide_cards} to = '/' className="stretched-link">Vstúpiť</Link> */}
                  </div>
                </Link>
              </div>
              </form>
          </div>

          <div className="col" style={{padding: "3em"}}>
            <form >
              <div className="card text-white" style={{width: '280px',backgroundColor: "black",margin: "auto"}}>
                <Link onClick = {() => this.hide_cards} to = '/filters' className="image">
                {/* <Link onClick = {() => this.hide_cards} to = '/' className="image"> */}
                  <BsCalendar3 className="icon__initial"/>
                  <div className="icon__initial__text">Filtrovanie</div>
                  <div className="image__overlay image__overlay--blur" onClick = {() => this.hide_cards} href = '/profile'>
                    <div className="image__title">Filtrovanie</div>
                    <p className="image__description">
                      Filtrovanie osobých a firemných databáz na základe termínov školení a kurzov
                    </p>
                    <Link onClick = {() => this.hide_cards} to = '/filters' className="stretched-link">Vstúpiť</Link>
                    {/* <Link onClick = {() => this.hide_cards} to = '/' className="stretched-link">Vstúpiť</Link> */}
                  </div>
                </Link>
              </div>
              </form>
          </div>
        </div>
      </div>
      
      <br/><br/>

    </div>

    );
  }
}