
export function getStocks(items,what) {
	let items_local = []
	items.map(item=>items_local.push({name: item[what], abbr: item.id}))
	return items_local
  }


export function matchStocks(state, value) {
	return (
	  state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 
	);
  }