import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "./styles.css";
const {stringToTime} = require('../controls/functions')

class OS extends React.Component {
    
    render() {
        // console.log('props on formOS.component.js:',this.props)
        return(
            <>
                <div className="row w-100 mx-auto justify-content-left">
                <h5 style={{color: "white",marginTop:20}}>Opakované školenie</h5>
                </div>
                <br/>
                
                {/* <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Dátum</label>
                <input
                    type="text"
                    className="form-control"
                    id={this.props.nazov}
                    value={this.props.currentTutorial[this.props.nazov].opakovane['skuska'+this.props.npreukaz]}
                    onChange={(e) => this.props.onChangeUniversal(e,this.props.nazov,'opakovane','skuska'+this.props.npreukaz)}
                    name={this.props.nazov}
                />
                </div> */}

                <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Predchádzajúce OŠ</label>
                    <input
                    type="text"
                    className="form-control"
                    id={this.props.nazov}
                    value={this.props.currentTutorial[this.props.nazov].opakovane["predAOP"+this.props.npreukaz]}
                    onChange={(e) => {this.props.onChangeUniversal(e,this.props.nazov,'opakovane','predAOP'+this.props.npreukaz);this.props.final_checks_clear("error_"+this.props.nazov)}}
                    onKeyDown={(e) => this.props.handleKeyDown(e,this.props.nazov,'opakovane',this.props.npreukaz)}
                    onPaste={(e) => this.props.handlePaste(e,this.props.nazov,'opakovane',this.props.npreukaz)}
                    name={this.props.nazov}
                    placeholder = "DD.MM.RRRR"
                    /> 
                {this.props.currentTutorial[this.props.nazov].opakovane.error && <p className="error">{this.props.currentTutorial[this.props.nazov].opakovane.error}</p>}
                </div>

                <div className="form-group col-lg-4 ">
                {/* <label style={{color: "white"}}>Zoznam zadaných OŠ</label> */}
                <label style={{color: "white"}}></label>
                <Row>
                    {this.props.currentTutorial[this.props.nazov].opakovane['predAOParr'+this.props.npreukaz].sort(stringToTime).map(date => (
                    <div className="tag-item"  key={date}>
                    {date}
                    <button
                        type="button"
                        className="button"
                        onClick={() =>  this.props.handleDelete(date,this.props.nazov,'opakovane',this.props.npreukaz)}
                    >
                        &times;
                    </button>
                    </div>
                    ))}
                </Row>
                </div>
            </>  
        )
    }
}
export default OS;




