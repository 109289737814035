import React, { Component, useState, useEffect, useRef, useLayoutEffect } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';



import MultiSelect from "react-multi-select-component";


const Specifikacia_os_aop = (props) => {

    var options = arrangeCourses(props.allCourses)

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
  
      console.log("componentDidUpdateFunction");
    });
    


    const [rozsahArr1,setRozsahArr1] = useState(props.option_array ? arr2opt(props.option_array) : []);

    const valuesRozsah = rozsahArr1
    const settersRozsah = setRozsahArr1

    function arr2opt(array){
        // console.log('typ:',props.typ)
        // console.log('array:',array)
        if (props.typ!=="Základný kurz"){
            var converted = []
            if (array.length>0){
                array.map(item => converted.push({'label': item, 'value': item}))
            }
        }else{
            // console.log('typ2:',props.typ)
            var converted = []
            if (array.length>0){
                array.map(item => {
                    // console.log('item:',item)
                    if (item.rozsah) {
                        // console.log("item.rozsah:",item.split(" "))
                            item.rozsah.split(';;').map(subitem => converted.push({'label': item.split(" ")[0]+": "+subitem, 'value': subitem}))
                    }
                    else converted.push({'label': item, 'value': item})
                // console.log('item in zakladny kurz:',item)
                })
            }

        }
        // console.log('options,initSpec:',converted,props.initSpec)
        return converted
    }

    function arrangeCourses(allCourses){
        var converted = []
        var zdvihacie = false
        if (allCourses && allCourses.length>0){
            if (props.typ==="Opakované školenie") allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(item => {
                if (item.course_OS===true) {
                    // console.log("item.course_nazov:",item.course_nazov.slice(0,2))
                    if (item.course_nazov.slice(0,2) === "ZZ"){
                        // console.log('here: ZZ')
                        if (zdvihacie===false){
                            // console.log('nastavujem zdvihacie...',zdvihacie)
                            zdvihacie = true
                            // setZdvihacie(true)
                            converted.push({'label': "ZZ Zdvíhacie zariadenia", 'value': "ZZ Zdvíhacie zariadenia"})
                            // console.log('converted:',converted)
                        }
                    }
                    else{
                        converted.push({'label': item.course_nazov, 'value': item.course_nazov}) 
                    }
                }
            })

            if (props.typ==="Aktualizačná odborná príprava") allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(item => {
                if (item.course_AOP===true) {
                    // console.log("item.course_nazov:",item.course_nazov.slice(0,2))
                    if (item.course_nazov.slice(0,2) === "ZZ"){
                        // console.log('here: ZZ')
                        if (zdvihacie===false){
                            // console.log('nastavujem zdvihacie...',zdvihacie)
                            zdvihacie = true
                            // setZdvihacie(true)
                            converted.push({'label': "ZZ Zdvíhacie zariadenia", 'value': "ZZ Zdvíhacie zariadenia"})
                            // console.log('converted:',converted)
                        }
                    }
                    else{
                        converted.push({'label': item.course_nazov, 'value': item.course_nazov}) 
                    }
                }
            })

            if (props.typ==="Základný kurz") allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(item => {
                if (!item.course_rozsah) converted.push({'label': item.course_nazov, 'value': item.course_nazov,'disabled':(props.initSpec && props.initSpec.includes(item.course_nazov))?true:false}) 
                else {
                    // console.log('item.split:',item.course_rozsah.split(';;'),item.course_nazov.split(" "))
                    item.course_rozsah.split(';;').map(subitem => {
                        const label = item.course_nazov.split(" ")[0]+" "+item.course_nazov.split(" ")[1]+": "+subitem
                        converted.push({'label': label, 'value': label,'disabled':(props.initSpec && props.initSpec.includes(label))?true:false})
                    })  
                }
            })
        }
        return converted
    }

    function convert_multiselect(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push(item["value"]))
        }
        return converted
    }

    useEffect(()=>{
        console.log('CHANGE!!!...valuesRozsah:',valuesRozsah,firstUpdate.current)
        if (!props.comingBack) props.onChangeCourseData(convert_multiselect(valuesRozsah),"specifikacia","yes")},[valuesRozsah]
        )

    useEffect(()=>{
        props.final_checks_clear("error_specifikacia")},[valuesRozsah]
        )



    // console.log('options chosen:',valuesRozsah,convert_multiselect(valuesRozsah))
    console.log('props in os_aop_specifikacia:',props)
    // console.log('first update:',firstUpdate.current)
    console.log('coming back in aop_os:',props.comingBack)
    return(
        
        <>
                {(options) ? (
        
                    <form className="form-group col-lg-4 required" >
                    <label style={{color: "white"}}>
                        Špecifikácia:
                        {/* {(!props.database)?
                        <div className="spinner-border" role="status" style={{color:'grey', width:"1.2em",height:"1.2em"}}>
                            <span className="sr-only">Loading...</span>
                        </div>:null} */}
                    </label>
                    <MultiSelect 
                    isLoading = {(!props.database || !props.allCourses)?true:false}
                    style = {{color: !"red" }}
                    disabled = {(props.typ==="" || props.typ==="Vyber typ" || !props.database || !props.allCourses) ? true : false}
                    options={options}
                    overrideStrings={(props.database && props.allCourses)?{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Vyber..."
                    }:{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Nahrávam dáta..."
                    }}
                
                    // value={valuesRozsah}
                    value = {arr2opt(props.option_array)}
                    onChange={settersRozsah}
                    labelledBy="Select"
                    />
                    {props.error_specifikacia && props.error_specifikacia!=="" && <p className="error">{props.error_specifikacia}</p>}
                    </form>
                ) : null}
        </>
    )
    
}
export default Specifikacia_os_aop;