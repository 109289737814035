import React, { Component, useState, useEffect, useRef } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SweetAlert from 'react-bootstrap-sweetalert';
import Font, {Text} from 'react-font';



import MultiSelect from "react-multi-select-component";


const Vyznam = (props) => {

    var options = [
                {'label': 'A', 'value': 'A'},
                {'label': 'B', 'value': 'B'},
                {'label': 'C', 'value': 'C'},
                {'label': 'D', 'value': 'D'},
                {'label': 'E', 'value': 'E'},
                ]

    const [rozsahArr1,setRozsahArr1] = useState(props.option_array ? arr2opt(props.option_array) : []);

    const valuesRozsah = rozsahArr1
    const settersRozsah = setRozsahArr1

    function arr2opt(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push({'label': item, 'value': item}))
        }
        return converted
    }

    function convert_multiselect(array){
        var converted = []
        if (array.length>0){
            array.map(item => converted.push(item["label"]))
        }
        return converted
    }

    useEffect(()=>{
        props.filter_firmy_vyznamy(valuesRozsah)},[valuesRozsah]
        )


    // console.log('options chosen:',valuesRozsah)
    // console.log('options from location state:',props.option_array)
    return(
        
        <>
                {(options) ? (
        
                    <form className="form-group col-lg-8">
                    <label style={{color: "white"}}>Význam:</label>
                    <MultiSelect 
                    options={options}
                    overrideStrings={{
                        "allItemsAreSelected": "Všetky položky vybrané.",
                        "clearSearch": "Zruš hľadanie",
                        "noOptions": "Prázdny výber",
                        "search": "Hľadaj..",
                        "selectAll": "Vyber všetko",
                        "selectSomeItems": "Všetky kategórie"
                    }}
                    value={valuesRozsah}
                    onChange={settersRozsah}
                    labelledBy="Select"
                    
                    />
                    </form>
                ) : null}

               
        </>
    )
    
}
export default Vyznam;