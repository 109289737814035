import React, { Component } from "react";
import DataService from "../services/tutorial.service";
import { Redirect } from 'react-router';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import "./../components/styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import Specifikacia_os_aop from  './os_aop_specifikacia.hook';

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaGem, FaHeart, FaAlgolia, FaCheck} from "react-icons/fa";

import ReactChipInput from "react-chip-input";

import Example from "./popover.hook"
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FaExclamationCircle} from "react-icons/fa";
import { BsArrowRightCircle, BsWindowSidebar} from "react-icons/bs";

// import swal from 'sweetalert';
import swal from '@sweetalert/with-react';
import { CheckboxSelectionComponent } from "ag-grid-community";

import {noPreukazAllowed} from '../controls/custom_os_aop.variable';

const {checkDatesAopOsAutoSuggestion} = require('../controls/functions');






export default class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.createCourse = this.createCourse.bind(this);
    this.createCourse2 = this.createCourse2.bind(this);
    this.createLoading = this.createLoading.bind(this);
    this.createSuccess = this.createSuccess.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.deleteCourse2 = this.deleteCourse2.bind(this);
    this.deleteLoading = this.deleteLoading.bind(this);
    this.deleteSuccess = this.deleteSuccess.bind(this);
    this.updateCourse = this.updateCourse.bind(this);  
    this.updateLoading = this.updateLoading.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.getCourseAlias = this.getCourseAlias.bind(this);
    this.getPersonsLineInTable = this.getPersonsLineInTable.bind(this);
    
    this.componentWillMount = this.componentWillMount.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onChangeCourseData = this.onChangeCourseData.bind(this);
    this.final_checks = this.final_checks.bind(this);
    this.final_checks_clear = this.final_checks_clear.bind(this);
    this.final_checks_clearAll = this.final_checks_clearAll.bind(this);
    this.cancel = this.cancel.bind(this);
    this.cancelLocal = this.cancelLocal.bind(this);
    this.searchByName = this.searchByName.bind(this);
    this.searchByNameLocal = this.searchByNameLocal.bind(this);
    this.setActivePerson = this.setActivePerson.bind(this);
    this.check_aop_os =  this.check_aop_os.bind(this);
    this.check_zs_rz = this.check_zs_rz.bind(this);
    this.show_default_attendees = this.show_default_attendees.bind(this);
    this.getCurrentFull = this.getCurrentFull.bind(this);
    this.future_true_dates = this.future_true_dates.bind(this);
    this.future_pos_diff = this.future_pos_diff.bind(this);
    this.changePreukazInCourse = this.changePreukazInCourse.bind(this);
    this.addChip = this.addChip.bind(this);
    this.removeChip = this.removeChip.bind(this);
    this.convertArr2doubleCollonStr = this.convertArr2doubleCollonStr.bind(this);
    this.getActivePreukazy = this.getActivePreukazy.bind(this);
    this.getRelevantPreukazy = this.getRelevantPreukazy.bind(this);
    this.showLocalCourseError = this.showLocalCourseError.bind(this);
    this.getLocalCourseAliasesFromCourseName = this.getLocalCourseAliasesFromCourseName.bind(this);
    this.getCourseNameFromLocalCourseAliases = this.getCourseNameFromLocalCourseAliases.bind(this);
    this.rozsirit = this.rozsirit.bind(this);
    this.rsSelected = this.rsSelected.bind(this);
    this.nonObligatoryLicensedCoursesAllHaveEvcisla = this.nonObligatoryLicensedCoursesAllHaveEvcisla.bind(this);
    this.rzAllowed = this.rzAllowed.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // this.feasible_courses = this.feasible_courses.bind(this);
    this.isCourseActive = this.isCourseActive.bind(this);
    this.setState = this.setState.bind(this);
    this.wrapperRef = React.createRef();

    this.color = "white"
    this.state = {
      showProgressAlert: false,
      showSpinner: false,
      deadlines: {},
      default_attendees_unconditioned: {},
      search_future_events: "",
      search_past_events: "",
      rsAllow: null,
      rsPreukazy: [],
      rsPreukazyNew: [],
      rsPreukazyNewError: [],
      feasible_preukazy: [],
      scope_preukazy: {},
      noPreukazAllowed: noPreukazAllowed,
      types: ["Základný kurz", "Opakované školenie", "Aktualizačná odborná príprava"],
      showCreatePopup: false,
      createMessage: "",
      createMessage2: "",
      deleteMessage: "",
      deleteMessage2: "",
      showDeletePopup: false,
      showZSalreadyPresentPopup: false,
      showMaxPreukazWarning: false,
      show2stepAlert: false,
      show1stepAlert: false,
      showNewEvcisla: false,
      npreukaz_max: 1,
      updateMessage: "",
      currentCourse: {
        nazov: "",
        specifikacia: [],
        datum: "",
        predseda: "",
        prisediaci1: "",
        prisediaci2: "",
        poznamka: "",
        typ: "",
        ucastnici: [],
        ucastnici_rozsirit: [],
      },
      checks: {},
      searchedByName: [],
      searchByName: "",
      searchCompany: "",
      currentIndex: -1,
      currentPerson: "",
      noCourseMatching: "",
      tooWideLicense: false,
      bitWideLicense: false,
      toEdit: false,
      chips: [],
      showNameList: false,
      showCompanyList: false,
      };
    
  }

  isCourseActive(person,course_name){
    const alias = this.state.courseName2courseAlias[course_name]
    let isActive = null
    if (alias){ // e.g. when preukaz has been renamed, alias is not recognized
      // console.log('person,course name,alias:',person,course_name,alias)
      const table_filtered = this.state.database.filter(table => table.table_name === alias+"s")[0]
      // console.log('table_filtered:',table_filtered)
      // console.log('table_filtered.table_name:',table_filtered.table_name)
      // console.log('table_filtered.data:',table_filtered.data)
      const table_rows = table_filtered.data
      const line = table_rows.filter(row=>row[alias+"_id"] === person.id)[0]
      isActive = line[alias+"_active"]
      // console.log('line, isActive, name, surname:',line,isActive,person.meno,person.priezvisko)
    }else{
      isActive = false
    }
    return isActive
  }

  handleClickOutside(event) {
    console.log('wrapperRef:',this.wrapperRef)
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      // alert("You clicked outside of me!");
      this.setState({showNameList: false,showCompanyList: false})
    }
  }

  getWholeDatabseHelperDicts(){
    let dict_course_alias2table = {}
    this.state.database.map((table,tidx)=>{
      let dict_person_id2line_idx = {}
      // console.log('haha:',table)
      table['data'].map((line,idx)=>{
        // console.log('hahah:',line)
        // console.log('hahaha:',table['table_name'].slice(0,-1)+'_id')
        dict_person_id2line_idx[line[table.table_name.slice(0,-1)+'_id']] = idx
      })
      table['id2lineindex'] = dict_person_id2line_idx
      dict_course_alias2table[table.table_name] = table
    })
    this.setState({database_fast_access:dict_course_alias2table})
  }

  componentWillMount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    console.log('in DidMount-getAllCourses');

    if (window.sessionStorage.getItem("createCourseState")) {
      console.log("coming back")
      if (this.state.toEdit && this.state.toEdit===true) this.setState({toEdit: false})
      var n = 0
      const statedata = JSON.parse(window.sessionStorage.getItem("createCourseState"))
      console.log('statedata:',statedata)
      await Promise.all(Object.keys(statedata).map(key=>{
        if (key==='toEdit') this.setState({'toEdit': false})
        else this.setState({[key]: statedata[key]})
        n+=1
      }))
      console.log('n, length:',n,Object.keys(this.state).length)
      if (n===Object.keys(statedata).length) {
        
        // and now update whole state in case of any changes:
        // technicians should not change
        // DataService.getAllTechnicians()
        //   .then(response => {
        //     console.log("received technicians data:",response.data);
        //     this.setState({
        //       message: "The techicians were obtained successfully!"
        //     });
        //     this.setState({
        //       allTechnicians: response.data[0]
        //     });
        //   })
        //   .catch(e => {
        //     console.log('The technicians fetch failed!',e);
        //   });

          DataService.getAllCompanies()
          .then(response => {
            console.log("received companies",response.data);
      
            this.setState({
              allCompanies: response.data[0]
            });
      
          })
          .catch(e => {
            console.log('The allCompanies fetch failed!',e);
          });

          // courses should not change 
          // DataService.getAllCourses()
          // .then(response => {
          //   console.log("received data",response.data);
          //   this.setState({
          //     allCourses: response.data[0]
          //   });
          // })
          // .catch(e => {
          //   console.log('The courses fetch failed!',e);
          // });

        DataService.getWholeDatabase()
        .then(response => {
          // this.setState({database: response.data},function() {this.build_tree()})
          // this.setState({database: response.data},()=>{
          //   console.log('removing createCourseState, this.state:',this.state);
          //   window.sessionStorage.removeItem("createCourseState")})
          this.setState({database: response.data, personal_details: response.data.filter(item=>item.table_name === 'personal_details')[0].data},()=>this.getWholeDatabseHelperDicts())
          
          console.log("received full database data",response.data);

          var skolenia2 = []
          const skolenia = response.data.filter(function(table){return table['table_name']==='skolenies'})[0]['data']
          skolenia && console.log('skolenia222:',skolenia)
          skolenia && skolenia.map(skolenie=>{
            var skol = JSON.parse(skolenie['skolenie_dict'])
            skol['skolenie_id'] = skolenie['skolenie_id']
            skolenia2.push(skol)
          })
          this.setState({skolenia: skolenia2})
        })
        .then(()=>{
          console.log('getting all license numbers...')
          let evcislaAll = []
          let evcislaDict = {}
          this.state.database && this.state.allCourses.map(course =>{
            const table  = this.state.database.filter(tab => tab.table_name===course.course_alias+"s")[0]
            // console.log('zevcisla in table:',table)
            table && table.data.map(table_entry=>{
              // console.log('zevcislo to split:',table_entry[course.course_alias+ "_zevcislo"])
              const zevcisla = (table_entry[course.course_alias+ "_zevcislo"])?table_entry[course.course_alias+ "_zevcislo"].split(";;"):[]
              // console.log('current evcisla:',zevcisla)
              if (zevcisla.length>0){
                evcislaAll = evcislaAll.concat(zevcisla)
                //store more data into dictionary
                zevcisla.map(evcislo=>{
                  if (!(evcislo in evcislaDict)){
                    evcislaDict[evcislo] = {[table_entry[course.course_alias+ "_id"]]: [course.course_nazov]}
                  }else{
                    if (table_entry[course.course_alias+ "_id"] in evcislaDict[evcislo]){
                      evcislaDict[evcislo][table_entry[course.course_alias+ "_id"]].push(course.course_nazov)
                    }else{
                      evcislaDict[evcislo][table_entry[course.course_alias+ "_id"]] = [course.course_nazov]
                    }
                  }
                })
              }
            })
            // console.log('after this table, zevcisla: ',evcislaAll)
          })
          this.setState({allEvcisla: evcislaAll,evcislaDict: evcislaDict})
        })
        .then(()=>{
          console.log('removing createCourseState after coming back...',window.sessionStorage.getItem("createCourseState"))
          window.sessionStorage.removeItem("createCourseState")
          // this.setState({doNotShowGoingBack:true})
          console.log('removed createCourseState after coming back...',window.sessionStorage.getItem("createCourseState"))
        })
        .then(()=>{
          let courseName2courseAlias = {}
          let courseAlias2courseName = {}
          this.state.allCourses.forEach(item=>{
            courseName2courseAlias[item.course_nazov] = item.course_alias
            courseAlias2courseName[item.course_alias] = item.course_nazov
          })
          this.setState({courseName2courseAlias: courseName2courseAlias,courseAlias2courseName: courseAlias2courseName})
        })
        .catch(e => {
          console.log('The full data fetch failed!',e);
        })
        .finally(()=>{
          console.log('state status after coming back from profile module:',this.state)
        })

      }
    }
    else{
        
      DataService.getAllTechnicians()
      .then(response => {
        console.log("received technicians data:",response.data);
        this.setState({
          message: "The techicians were obtained successfully!"
        });
        this.setState({
          allTechnicians: response.data[0]
        });
      })
      .catch(e => {
        console.log('The technicians fetch failed!',e);
      });


      DataService.getAllCompanies()
      .then(response => {
        console.log("received companies",response.data);
  
        this.setState({
          allCompanies: response.data[0]
        });
  
      })
      .catch(e => {
        console.log('The allCompanies fetch failed!',e);
      });


      DataService.getAllCourses()
      .then(response => {
        console.log("received data",response.data);
        this.setState({
          allCourses: response.data[0]
        });
      })
      .catch(e => {
        console.log('The courses fetch failed!',e);
      });

      DataService.getWholeDatabase()
      .then(response => {
        this.setState({database: response.data, personal_details: response.data.filter(item=>item.table_name === 'personal_details')[0].data},()=>this.getWholeDatabseHelperDicts())
        console.log("received full database data",response.data);

        var skolenia2 = []
        const skolenia = response.data.filter(function(table){return table['table_name']==='skolenies'})[0]['data']
        skolenia && console.log('skolenia222:',skolenia)
        skolenia && skolenia.map(skolenie=>{
          var skol = JSON.parse(skolenie['skolenie_dict'])
          // console.log('skoleniee:',skolenie)
          if (skol) skol['skolenie_id'] = skolenie['skolenie_id']
          if (skol) skolenia2.push(skol)
          // skolenia2.push(JSON.parse(skolenie['skolenie_dict']))
        })
        this.setState({skolenia: skolenia2})
      })
      .then(()=>{
        console.log('getting all license numbers...')
        let evcislaAll = []
        let evcislaDict = {}
        this.state.database && this.state.allCourses.map(course =>{
          const table  = this.state.database.filter(tab => tab.table_name===course.course_alias+"s")[0]
          // console.log('zevcisla in table:',table)
          table && table.data.map(table_entry=>{
            // console.log('zevcislo to split:',table_entry[course.course_alias+ "_zevcislo"])
            const zevcisla = (table_entry[course.course_alias+ "_zevcislo"])?table_entry[course.course_alias+ "_zevcislo"].split(";;"):[]
            // console.log('current evcisla:',zevcisla)
            if (zevcisla.length>0){
              evcislaAll = evcislaAll.concat(zevcisla)
              //store more data into dictionary
              zevcisla.map(evcislo=>{
                if (!(evcislo in evcislaDict)){
                  evcislaDict[evcislo] = {[table_entry[course.course_alias+ "_id"]]: [course.course_nazov]}
                }else{
                  if (table_entry[course.course_alias+ "_id"] in evcislaDict[evcislo]){
                    evcislaDict[evcislo][table_entry[course.course_alias+ "_id"]].push(course.course_nazov)
                  }else{
                    evcislaDict[evcislo][table_entry[course.course_alias+ "_id"]] = [course.course_nazov]
                  }
                }
              })
            }
          })
          // console.log('after this table, zevcisla: ',evcislaAll)
        })
        this.setState({allEvcisla: evcislaAll,evcislaDict: evcislaDict})
      })
      .then(()=>{
        let courseName2courseAlias = {}
        let courseAlias2courseName = {}
        this.state.allCourses.forEach(item=>{
          courseName2courseAlias[item.course_nazov] = item.course_alias
          courseAlias2courseName[item.course_alias] = item.course_nazov
        })
        this.setState({courseName2courseAlias: courseName2courseAlias,courseAlias2courseName: courseAlias2courseName})
      })
      .catch(e => {
        console.log('The full data fetch failed!',e);
      });
    }
  }

  getCurrentFull(person_id){
    let name;
    DataService.get(person_id)
    .then(response => {
      this.setState({
        profileRetrieved: response.data[0][0],
      });
      console.log('fetched full current profile: response:',response.data[0][0]);
    })
    .then(
      console.log('retrieved data:',this.state.profileRetrieved)
    )
    //now retrieve the id photo
    .then( ()=> {
      name = this.state.profileRetrieved.meno+this.state.profileRetrieved.priezvisko+this.state.profileRetrieved["dátum narodenia"]
      console.log("filename of ID photo: ",name)
      DataService.getFile(name)
      .then(response => {
        console.log('response from getFile: ',response)
        this.setState({id_photo: response})
        console.log('extension:',response.data.slice(1,4))
        if (response.data.slice(1,4)==="PNG") this.setState({id_photo_ext: ".png"})
        if (response.data.slice(1,4)==="JPG") this.setState({id_photo_ext: ".jpg"})
        if (response.data.slice(1,5)==="JPEG") this.setState({id_photo_ext: ".jpeg"})
      })
      .catch(error => console.log('error from getFile: ',error))
      }
    )
    //now retrieve the licenses (scans)
    .then(()=>{
      this.state.allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(course => {
        if (this.state.profileRetrieved[course.course_alias+"_zevcislo"]) {
          this.state.profileRetrieved[course.course_alias+"_zevcislo"].split(";;").map((evcislo,index) =>{
            const nameScan = this.state.profileRetrieved.meno+this.state.profileRetrieved.priezvisko
                            +this.state.profileRetrieved["dátum narodenia"]
                            +"_"+course.course_alias
                            +["_preukaz"+(index+1)] + ".pdf"
            console.log('name_scan:',nameScan)
            DataService.getFile(nameScan)
            .then(response => {
              console.log('response from getFile Scan: ',response)
              if (response.data!=="") {this.setState({[course.course_alias+"_preukaz_"+(index+1)]: response})}
            })
            .catch(error => console.log('error from getFile Scan: ',error))
          })
        }
      })
    })
    .then(res => {
      this.setState({toEdit: true})
      // window.sessionStorage.setItem("createCourseState",JSON.stringify(this.state))
      window.sessionStorage.setItem("createCourseState",this.setStateExceptDatabase())
    })
    .catch(e => {
      console.log(e);
    });
    
  }

  show_default_attendees(noCondition=false){
    console.log('specifikacia entering show_default_attendees:',this.state.currentCourse.specifikacia)
    // returns: array of dictionaries of persons by default selected to attend the course
    var default_attendees = []
    if (this.state.currentCourse.typ===this.state.types[0] || this.state.currentCourse.typ===this.state.types[1]){
      var persons = this.state.database && this.state.database.filter(function(table) {return table['table_name']==='personal_details'})[0]['data']
      console.log('persons',persons)
      

      var start = window.performance.now()
      persons.map((person)=>{
        if (person.active===true){
          const person_status = this.check_aop_os(person['id'],false, person['zamestnávateľ'], noCondition)
          // console.log('person and status:',person, person_status)
          if (person_status && person_status['preukazy_obnovit'].length > 0) {
            default_attendees.push({...person_status,...person})
          }
        }
      })
      var end = window.performance.now()
      console.log('person loop in show_default_attendees in s:',(end - start)/1000)
    }
    if (noCondition === true) this.setState({default_attendees:default_attendees})
    if (noCondition == 'truefalse'){
      const count_default_attendees = default_attendees.filter(item => item['default_attendee'] === true).length
      const count_attendees = default_attendees.filter(item => item['attendee'] === true).length
      console.log('show_default attendee experiment - default att., att.:',count_default_attendees, count_attendees)
    }
    return default_attendees
  }

  check_aop_os(person_id,manual,zamestnavatel,noCondition = false) {
    // param person_id: id of a person to be checked
    // param manual: true indicates manual adding of course attendees
    //               false indicates automated initial offer of candidates for a specific course
    // param threshold: number of months to remain until the next necessary course attendance
    // returns: dictionary of a form {'personal_id': person_id, 'preukazy_obnovit': ['evcislo1','evcislo2','course_elektrotechnik']}
    //          where later is array of evcislo to renew. If the license(=preukaz) does not exist for a given course (evcislo = ""),
    //         assign directly name of course instead of evcislo

    // console.log('entering check_aop_os for person_id: ',person_id)
    const profile_time = false
    if (profile_time == true) var t0 = window.performance.now()

    // var persons = this.state.database.filter(function(table) {return table['table_name']==='personal_details'})[0]['data']
    // var t05 = window.performance.now()
    const company = this.state.allCompanies.filter(item=>item.company_nazov === zamestnavatel)[0]
    // const company = zamestnavatel
    
    var relevant = {}
    var preukazy = []
    var exceeding = {} //specifications exceeding current scope of actual course

    var default_attendee_replace_noCondition = false
    var attendee_replace_noCondition = false
    //include custom_os_aop from controls (3 roky os)
    // var time_to_course = 2 // in reality needs to be = course_date - (last_attendace + period)
    // var threshold = 3
    
    if (profile_time == true) var t1 = window.performance.now()
    if (profile_time == true) var tsum1 = 0
    if (profile_time == true) var tsum2 = 0
    if (profile_time == true) var tsum3 = 0

    let deadlines = this.state.deadlines
    this.state.allCourses.map((course) => {
      if (profile_time == true) var t21 = window.performance.now()
      // var table = this.state.database.filter(function(tab) {return tab['table_name']===course['course_alias']+'s'})[0]['data']
      if (profile_time == true) var t22 = window.performance.now()
      var line = []

      // for (let i=0;i<table.length;i++){
      //   if (table[i][course['course_alias']+'_id']===person_id){
      //     line = [table[i]]      
      //     break
      //   }
      // }
      // line = table.filter(item=>item[course['course_alias']+'_id']===person_id) // option 2, app. same as for loop
      
      // this is an effort to replace for loop above with the well-prepared O(1) operation using helper dictionaries defined on the DidMount
      const line_idx = this.state.database_fast_access[course['course_alias']+'s'].id2lineindex[person_id]
      // console.log('hash line_idx:',line_idx,this.state.database_fast_access[course['course_alias']+'s'],this.state.database_fast_access[course['course_alias']+'s'].id2lineindex)
      line = (line_idx === undefined)?[]:[this.state.database_fast_access[course['course_alias']+'s'].data[line_idx]]


      
      if (profile_time == true) var t23 = window.performance.now()
      //if the person is present in the table of the course->...
      if (line.length>0 && line[0][course['course_alias']+'_zskuska'].length>0) { 
        // console.log('person_id:',person_id, ' table:',table, ' line: ',line)
        var evcisla = (line[0][course['course_alias']+'_zevcislo'])?line[0][course['course_alias']+'_zevcislo'].split(';;'):['']; 
        evcisla.map(evcislo=>{
          // collect all license evcisla of a given person, avoid repetition (if preukaz does not exist, replace by full course name)
          if (!(preukazy.includes(evcislo))) {
            if (evcislo!=="") preukazy.push(evcislo)
            else preukazy.push(course['course_nazov'])
          }
        })
        // console.log('evcisla, preukazy:',evcisla,preukazy,'course_nazov:',course['course_nazov'])

        // console.log('before course range check: ',course['course_nazov'],this.state.currentCourse.specifikacia,this.state.currentCourse.specifikacia.includes(course['course_nazov']))
        let course_name = (course['course_nazov'].slice(0,2)==="ZZ")?"ZZ Zdvíhacie zariadenia":course['course_nazov']
        //preukaz might be relevant when the course is within specifikacia
        if (this.state.currentCourse.specifikacia.includes(course_name)){
          // console.log('course match')
          // console.log('mapping evcisla of matching courses...')
          evcisla.map((evcislo,index)=>{
            // automatically offer only such persons for whom their license expires until threshold
            const typ = (this.state.currentCourse.typ === this.state.types[0])?'aop':'os'
            const checkDateAuto = checkDatesAopOsAutoSuggestion(company,line[0],course,['zmeškané do dnes','aktuálny+nasledujúci mesiac'],(evcislo!=="")?evcislo:course.course_nazov,typ)
            // console.log('checkDatesAopOsAutoSuggestion:',company,line[0],course,evcislo,checkDateAuto)
            // if (time_to_course < threshold) {
            if (noCondition === false && (checkDateAuto[0]!=='no' || manual === true)) {
              if (evcislo!=="") relevant[evcislo] = true 
              else {
                evcisla[index] = course['course_nazov'];
                relevant[course['course_nazov']] = true
              }
              // let deadlines = this.state.deadlines
              if (!(person_id in deadlines)) deadlines[person_id] = {}
              deadlines[person_id][evcisla[index]] = checkDateAuto[1]
              // this.setState({deadlines:deadlines})
            }
            if (noCondition === true) {
              if (evcislo!=="") relevant[evcislo] = true 
              else {
                evcisla[index] = course['course_nazov'];
                relevant[course['course_nazov']] = true
              }
              // let deadlines = this.state.deadlines
              if (!(person_id in deadlines)) deadlines[person_id] = {}
              deadlines[person_id][evcisla[index]] = checkDateAuto[1]
              // this.setState({deadlines:deadlines})
            }
            if (noCondition == 'truefalse'){
              // this option is to replace the noCondition true+false duplication in show_default_attendees() call
              default_attendee_replace_noCondition = true
              if (evcislo!=="") relevant[evcislo] = true 
              else {
                evcisla[index] = course['course_nazov'];
                relevant[course['course_nazov']] = true
              }
              // let deadlines = this.state.deadlines
              if (!(person_id in deadlines)) deadlines[person_id] = {}
              deadlines[person_id][evcisla[index]] = checkDateAuto[1]

              if (checkDateAuto[0]!=='no' || manual === true){
                attendee_replace_noCondition = true
              }
              default_attendee_replace_noCondition = true
            }

          })
        }
        else{
          // console.log('person has ZS from course: ',course['course_nazov'],' but the course is not within the course list')
          evcisla.map(evcislo=>{
            if (evcislo==="") evcislo = course['course_nazov']
            if (!(evcislo in exceeding)) exceeding[evcislo] = []
            exceeding[evcislo].push(course['course_nazov'])
            if (!relevant[evcislo]) relevant[evcislo] = false // be careful not to overwrite relevant preukaz when there are two courses on a single one and the fisrt one is in specifikacia and the second is not
          })
        }
      }
      if (profile_time == true) var t24 = window.performance.now()
      // console.log('int21, int22, int23 in s:',(t22-t21)/1000,(t23-t22)/1000,(t24-t23)/1000)
      if (profile_time == true) tsum1 += (t22-t21)/1000
      if (profile_time == true) tsum2 += (t23-t22)/1000
      if (profile_time == true) tsum3 += (t24-t23)/1000
    })
    this.setState({deadlines:deadlines})
    // console.log('course loop timing:',tsum1,tsum2,tsum3)

    if (profile_time == true) var t2 = window.performance.now()
    if (profile_time == true) console.log('[timing]: O(#companies), O(#courses)*[inside], inside parts 1,2,3:',(t1-t0)/1000,(t2-t1)/1000,tsum1,tsum2,tsum3)
    // console.log('int1, int1.5, int2 in s:',(t05-t0)/1000,(t1-t05)/1000,(t2-t1)/1000)

    // console.log('relevant,exceeding:',relevant,exceeding,Object.keys(relevant),Object.keys(exceeding))
    if (Object.keys(relevant).length===0 || Object.keys(relevant).filter(function(key){return (relevant[key]!==false)}).length===0){
      // person does not have Zakladny kurz, therefore cannot be added into currentCourse.ucastnici
      if (manual===true) {
        // console.log('here warning that nothing in specifikacia kurzu matches this persons zakadne kurzy')
        this.setState({noCourseMatching: "noMatch",person_id_tmp: person_id})
      }
    }
    else{ 
      // person has Zakladny kurz, but has some exceeding courses on relevant license card and therefore cannot be added 

      
      const relevantBUTnonexceeding = Object.keys(relevant).filter(function(key){return (relevant[key]===true && (!exceeding[key] || exceeding[key].length===0))})
      // if (Object.keys(relevant).filter(function(key){return relevant[key]===true && exceeding[key] && exceeding[key].length>0}).length>0) {
      if (relevantBUTnonexceeding.length===0) {
        // console.log('here warning that one needs to extend the specifikacia range to renew given licenses',Object.keys(relevant).filter(function(key){return relevant[key]===true && exceeding[key] && exceeding[key].length>0})) 
        if (manual===true) this.setState({tooWideLicense: true,person_id_tmp: person_id,relevant: relevant, exceeding: exceeding})
        //delete from list of all possible persons having ZS from a given courses given that condition is ignored

      }
      // person has Zakladny kurz and no exceeding courses on relevant license -> can be added among currentCourse.ucastnici
      else{
    
        if (relevantBUTnonexceeding.length<Object.keys(relevant).filter(key => relevant[key]===true).length){
          
          if (manual===true) this.setState({bitWideLicense: true,person_id_tmp: person_id,relevant: relevant, exceeding: exceeding})
        }


                  

        var spec = this.state.currentCourse.specifikacia
        var obnovit = relevantBUTnonexceeding
        // var obnovit = preukazy.filter(function(item){if ((spec.includes(relevant[item]) || relevant[item]===true)) return true; else return false})
        // console.log('forming output:',relevant,obnovit)
        if (obnovit[0]==='') obnovit[0] = relevant['']
        if (profile_time == true) var t3 = window.performance.now()
        if (profile_time == true) console.log('[timing] rest of the function:',(t3-t2)/1000)
        return {'person_id': person_id, 'preukazy_obnovit': obnovit, 'attendee': attendee_replace_noCondition, 'default_attendee':default_attendee_replace_noCondition}
      }
    }
  }

  convertArr2doubleCollonStr(arr){
    console.log('arr:',arr)
    var len = arr.length
    var retArr=null
    if (len===0) return ""
    retArr = arr[0]
    for (var i=0; i<len - 1; i++){retArr+=[";;"+arr[i+1]]}
    return retArr
  }

  getActivePreukazy(localCourseList, preukazy){
    let activePreukazy = []
    preukazy.map(preukaz=>{
      let used = false
      localCourseList.map(localCourse=>{
        if (localCourse.onPreukaz.includes(preukaz)){
          used = true
        }
      })
      if (used === true){
        activePreukazy.push(preukaz)
      }
    })
    return activePreukazy
  }

  getRelevantPreukazy(localCourseList,subitems){
    let relevantPreukazy = []
    localCourseList.map(localCourse=>{
      let localCourse_unaliased = localCourse.course_name.split(" ").slice(2).join(" ")
      console.log('localCourseUnaliased: ',localCourse_unaliased)
      localCourse.onPreukaz.map(preukaz=>{
        if (!relevantPreukazy.includes(preukaz) && subitems.includes(localCourse_unaliased)){
          relevantPreukazy.push(preukaz)
        }
      })
    })
    return relevantPreukazy
  }

  check_zs_rz(person_id,onCreate=false){
    let person_create = []
    let localCourseList = []
    let preukazy = []
    let activePreukazy = []
    let noAdd = false
    let encountered = []
    let ucastnik = this.state.currentCourse.ucastnici.filter(ucastnik=>ucastnik.id===person_id)[0]

    if (ucastnik){
      localCourseList = ucastnik.localCourseList
      preukazy = ucastnik.preukazy
      activePreukazy = this.getActivePreukazy(localCourseList,preukazy)
    }

    this.state.allCourses.map(item => {
      let subitems = null
      let line = null
      // console.log('zs-course:',item)
      if (item.course_rozsah) {
        subitems = item.course_rozsah.split(";;") // original rozsahy
        }
      line = this.getPersonsLineInTable(item.course_alias,person_id)
      line && console.log('profile in table:',line)
      console.log('...',line,!subitems, this.state.currentCourse.specifikacia.includes(item.course_nazov),item.course_nazov)
      if (line && !subitems && this.state.currentCourse.specifikacia.includes(item.course_nazov)){
        // some course (with no subitems) has already ZS in database!
        this.setState({showZSalreadyPresentPopup: true, person_id_tmp: person_id})
        encountered.push(item.course_nazov)
        noAdd = true
      }

      if (!line && !subitems && this.state.currentCourse.specifikacia.includes(item.course_nazov) && onCreate===true){
        const localCourse = ucastnik.localCourseList.filter(item2=>item2.course_name===item.course_nazov)[0]
        let append = null
        if (localCourse){
          let zvydanie = []
          let zevcislo = []
          let zvydanieStr = null
          let zevcisloStr = null
          localCourse.onPreukaz.map(evcislo => {
            zevcislo.push(evcislo)
            zvydanie.push(this.state.currentCourse.datum)
          })
          zvydanieStr = this.convertArr2doubleCollonStr(zvydanie)
          zevcisloStr = this.convertArr2doubleCollonStr(zevcislo)
          console.log('zvydanieStr, zevcisloStr:',zvydanieStr,zevcisloStr)

          append = {
            'zskuska': this.state.currentCourse.datum, //already in the table - does not need to be modified, relevant is date of issuing 
            'zvydanie': zvydanieStr,
            'zevcislo': zevcisloStr,
            'action' : 'create',
            'course_alias': item.course_alias,
          }
        }
        if (append && onCreate===true){
          console.log('appending data:',append)
          person_create.push(append) 
        }
        
      }

      if (line && subitems){
        let firstTimeHere = true
        // person is present in a table for course with subitems
        
        const line_details = JSON.parse(line[item.course_alias + "_zrozsah"])
        const predOS = JSON.parse(line[item.course_alias + "_predOS"])
        const predAOP = JSON.parse(line[item.course_alias + "_predAOP"])
        console.log('line details:',line_details)
        // console.log('line details:',line_details,Object.keys(line_details))
        
        let zrozsah = line_details
        let append=null
        //check for separate subitems
        subitems.map((subitem)=>{

          //subitemAlias -> subitem cast into form used in this module
          //line_details -> parsed rozsahy for a given person
          //courseList -> local course list of a given person
          const subitemAlias = item.course_nazov.split(" ")[0]+" "+item.course_nazov.split(" ")[1]+": "+subitem

          if (this.state.currentCourse.specifikacia.includes(subitemAlias)){
            // this subitem is lectured in actual skolenie - we need to check if there is ZS already or not
            // console.log('some checks')
            
            if (line_details[subitem].length>0){
              //there are some evcisla present -> already ZS
              console.log('encountered: ',subitem)
              this.setState({showZSalreadyPresentPopup: true, person_id_tmp: person_id})
              noAdd = true
              encountered.push(subitemAlias)
            }

            if (line_details[subitem].length===0 && onCreate===true){
              //when creating profile, build-up array of dicts to be created/updated
              // localCourseList = this.state.currentCourse.ucastnici.filter(ucastnik => ucastnik.id===person_id)[0].localCourseList
              console.log('if localCourseList has been reduced: ',localCourseList.filter(item => item.course_name === subitemAlias)[0])
              
              if (localCourseList.filter(item => item.course_name === subitemAlias)[0]){
                const onPreukaz = localCourseList && localCourseList.filter(item2=>item2.course_name===subitemAlias)[0].onPreukaz
                onPreukaz && console.log('onPreukaz:',onPreukaz)
                zrozsah[subitem] = onPreukaz
                
                if (firstTimeHere===true) {
                  firstTimeHere=false
                  let zvydanie = (line[item.course_alias + "_zvydanie"])?line[item.course_alias + "_zvydanie"].split(";;"):[]
                  let zevcislo = (line[item.course_alias + "_zevcislo"])?line[item.course_alias + "_zevcislo"].split(";;"):[]
                  
                  let zvydanieStr = null
                  let zevcisloStr = null
                  let relevantPreukazy = this.getRelevantPreukazy(localCourseList,subitems)
                  
                  console.log('preukazy:',preukazy)
                  relevantPreukazy.map(evcislo => {
                    zevcislo.push(evcislo)
                    zvydanie.push(this.state.currentCourse.datum)
                  })
                  zvydanieStr = this.convertArr2doubleCollonStr(zvydanie)
                  zevcisloStr = this.convertArr2doubleCollonStr(zevcislo)
                  console.log('zvydanieStr, zevcisloStr:',zvydanieStr,zevcisloStr)

                  append = {
                    'zvydanie': zvydanieStr,
                    'zevcislo': zevcisloStr,
                    'action' : 'update',
                    'course_alias': item.course_alias,
                    'predOS': JSON.stringify(predOS),
                    'predAOP': JSON.stringify(predAOP),
                  }
                }
              } 
            }
          }
        })
        if (append && onCreate===true){
          console.log('item,...,',item,zrozsah,append)
          append['zrozsah'] = JSON.stringify(zrozsah)
          console.log('appending data:',append)
          person_create.push(append)
        }
      }

      if (!line && subitems){
        let firstTimeHere = true
        // person is present in a table for course with subitems
        
        let zrozsah = {}
        subitems.map(sitem=>zrozsah[sitem] = [])
        let append=null
        //check for separate subitems
        subitems.map((subitem)=>{

          //subitemAlias -> subitem cast into form used in this module
          //line_details -> parsed rozsahy for a given person
          //courseList -> local course list of a given person
          const subitemAlias = item.course_nazov.split(" ")[0]+" "+item.course_nazov.split(" ")[1]+": "+subitem

          if (this.state.currentCourse.specifikacia.includes(subitemAlias)){
            // this subitem is lectured in actual skolenie - we need to check if there is ZS already or not
      
            if (onCreate===true){
              //when creating profile, build-up array of dicts to be created/updated
              console.log('if localCourseList has been reduced: ',localCourseList.filter(item => item.course_name === subitemAlias)[0])

              if (localCourseList.filter(item => item.course_name === subitemAlias)[0]){
                const onPreukaz = localCourseList && localCourseList.filter(item2=>item2.course_name===subitemAlias)[0].onPreukaz
                onPreukaz && console.log('onPreukaz:',onPreukaz)
                zrozsah[subitem] = onPreukaz

                if (firstTimeHere===true) {
                  firstTimeHere=false
                  let zvydanie = []
                  let zevcislo = []
                  let zvydanieStr = null
                  let zevcisloStr = null
                  let relevantPreukazy = this.getRelevantPreukazy(localCourseList,subitems)
                  console.log('preukazy:',preukazy)
                  console.log('relevant preukazy:',relevantPreukazy)
                  relevantPreukazy && relevantPreukazy.map(evcislo => {
                    zevcislo.push(evcislo)
                    zvydanie.push(this.state.currentCourse.datum)
                  })
                  zvydanieStr = this.convertArr2doubleCollonStr(zvydanie)
                  zevcisloStr = this.convertArr2doubleCollonStr(zevcislo)
                  console.log('zvydanieStr, zevcisloStr:',zvydanieStr,zevcisloStr)
                  
                  append = {
                    'zskuska': this.state.currentCourse.datum, //already in the table - does not need to be modified, relevant is date of issuing 
                    'zvydanie': zvydanieStr,
                    'zevcislo': zevcisloStr,
                    'action' : 'create',
                    'course_alias': item.course_alias,
                  }
                }
              }
            }
          }
        })

        if (append && onCreate===true){
          console.log('item,...,',item,zrozsah,append)
          append['zrozsah'] = JSON.stringify(zrozsah)
          console.log('appending data:',append)
          person_create.push(append)
        }
      }

    })

    this.setState({zsEncountered:encountered})
    if (onCreate===false) {
      this.state.currentCourse.specifikacia.map(spec=>localCourseList.push({'course_name': spec, 'onPreukaz': []}))
      console.log('only activating profile...')
    }
    if (noAdd === false) {
      console.log('returning from check_zs_rs...')
      if (onCreate === false){
        return {'person_id': person_id, localCourseList: localCourseList, preukazy: preukazy}
      }
      if (onCreate === true){
        let ucastnik = this.state.currentCourse.ucastnici.filter(ucastnik => ucastnik.id===person_id)[0]
        console.log('ucastnik22:',ucastnik)
        if (ucastnik){
          ucastnik.localCourseList = localCourseList
          ucastnik.preukazy = preukazy
          ucastnik.activePreukazy = activePreukazy
          ucastnik.preukazy_vytvorit = person_create
          let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
          const index = currentCourse_tmp.ucastnici.findIndex(item=>item.person_id===ucastnik.person_id)
          currentCourse_tmp.ucastnici[index] = ucastnik
          this.setState({currentCourse: currentCourse_tmp})
        }
        return {'person_id': person_id, 'preukazy_vytvorit': person_create, localCourseList: localCourseList, preukazy: preukazy, activePreukazy: activePreukazy}
      }
    }
  }

  onChangeCourseData(e,vstup,direct='no'){
    const entry = (direct==='yes') ? e : e.target.value;
    console.log('what:',vstup)
    console.log('entry,e:',entry,e)
    console.log('this.state.currentCourse',this.state.currentCourse)

    if (vstup==='specifikacia' && this.state.currentCourse.typ === this.state.types[2]){
      const del = this.state.currentCourse.specifikacia.filter(item=>!entry.includes(item))
      const add = entry.filter(item=>!this.state.currentCourse.specifikacia.includes(item))
      console.log('change in specifikacia: add, del',add,del)
      if (del.length>0){
        let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        this.state.currentCourse.ucastnici.map((ucastnik,index)=>{
          let localCourseListUpdated = ucastnik.localCourseList.filter(item=> !del.includes(item.course_name))
          currentCourse_tmp.ucastnici[index].localCourseList = localCourseListUpdated
        })
        this.setState({currentCourse: currentCourse_tmp})
      }

      if (add.length>0){
        console.log('adding...')
        let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        
        // currentCourse_tmp.ucastnici = (this.state.aktualizovat===true)?this.state.currentCourse_asStored.ucastnici:[]
        // this.setState({currentCourse: currentCourse_tmp})
      }
    }

    if (vstup==='typ'){
      this.setState(function(prevState){return {
        currentCourse: {
          ...prevState.currentCourse,
          'ucastnici': [],
          'specifikacia': [],
        }}
      })
    }

    this.setState(function(prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
            [vstup]: entry
        }
      };
    },()=>{
      this.state.currentCourse.ucastnici.forEach(ucastnik=>{
        console.log('re-checking ucastnik:',ucastnik)
        this.check_zs_rz(ucastnik.id)
      })
      if (vstup==='specifikacia' && this.state.currentCourse.typ !== this.state.types[2]) {
        console.log('vstup:',vstup, 'executing default attendee finder...',this.state.database,window.sessionStorage.getItem("createCourseState"));
        
        const combined_attendees = this.show_default_attendees('truefalse')
        const attendees = combined_attendees.filter(item => item['attendee'] === true)
        const default_attendees = combined_attendees.filter(item => item['default_attendee'] === true)
        
        this.setState(function(prevState){return {
          currentCourse: {
            ...prevState.currentCourse,
            'ucastnici': (attendees) ? attendees : []
          },
          default_attendees:default_attendees}
        })
      }
    })
    
    if (vstup === 'datum' && this.state.currentCourse.typ === this.state.types[2]){
      console.log('vstup datum:',vstup)
      //if datum is set after definition of a profile to extend (rozsirit), then zskuska/zvydanie are not modified -> fixing this here
      let ucastnici_rozsirit_tmp = JSON.parse(JSON.stringify(this.state.currentCourse.ucastnici_rozsirit))
      ucastnici_rozsirit_tmp && ucastnici_rozsirit_tmp.forEach((ucastnik_rozsirit,idx)=>{
        let rozsirit_summary = ucastnik_rozsirit.rozsirit_summary
        rozsirit_summary.forEach((item,index)=>{
          //archive items do not contain datum of skolenie
          if (item.action!=='archive'){
            console.log('update date:',rozsirit_summary[index].preukazy_vytvorit[item.alias+'_zskuska'],entry)
            // only on create not on update line
            if (item.action==='create'){
              rozsirit_summary[index].preukazy_vytvorit[item.alias+'_zskuska'] = entry
            }
            // if no preukaz, zvydanie cannot be set to date, rather must be left empty (however, ucastnici_rozsirit always have evcislo)
            if (rozsirit_summary[index].preukazy_vytvorit[item.alias+'_zevcislo']!==""){
              rozsirit_summary[index].preukazy_vytvorit[item.alias+'_zvydanie'] = entry
            }
          }
        })
        ucastnici_rozsirit_tmp[idx].rozsirit_summary = rozsirit_summary
      })
      this.setState(function(prevState){return {
        currentCourse: {
          ...prevState.currentCourse,
          'ucastnici_rozsirit': ucastnici_rozsirit_tmp
        }}
      })
    }
  }

  getLocalCourseAliasesFromCourseName(course){
    let aliases = []
    if (course.course_rozsah){
      const rozsahy = course.course_rozsah.split(";;")
      const prefix1 = course.course_nazov.split(" ")[0]
      const prefix2 = course.course_nazov.split(" ")[1]
      rozsahy && rozsahy.map(rozsah=>{
        const alias = prefix1 + " " + prefix2 + ": " + rozsah
        aliases.push(alias)
      })
    }
    else{
      aliases.push(course.course_nazov)
    }
    return aliases
  }

  getCourseNameFromLocalCourseAliases(alias){
    let toreturn = null
    for (var j=0;j<this.state.allCourses.length;j++){
      var item = this.state.allCourses[j]
      // console.log('item,alias',item.course_nazov,alias,item.course_nazov===alias)
      if (item.course_nazov===alias){
        console.log('returning1')
        toreturn = item.course_nazov
        break
      }
      if (item.course_nazov.split(" ")[0] === alias.split(" ")[0]){
        console.log('returning2')
        toreturn = item.course_nazov
        break
      }
    }
    return toreturn
  }
  getCourseAliasFromLocalCourseAliases(alias){
    let toreturn = null
    for (var j=0;j<this.state.allCourses.length;j++){
      var item = this.state.allCourses[j]
      // console.log('item,alias',item.course_nazov,alias,item.course_nazov===alias)
      if (item.course_nazov===alias){
        console.log('returning1')
        toreturn = item.course_alias
        break
      }
      if (item.course_nazov.split(" ")[0] === alias.split(" ")[0] && item.course_nazov.split(" ")[1]+":" === alias.split(" ")[1]){
        console.log('returning2',item.course_nazov.split(" "),alias.split(" "))
        toreturn = item.course_alias
        break
      }
    }
    return toreturn
  }

  final_checks(){
    let ok = true
    if (!this.state.currentCourse.nazov || this.state.currentCourse.nazov==="") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_name": "Názov kurzu nezadaný!"}}})
      ok=false
    }

    //non-empty surname
    if (!this.state.currentCourse.specifikacia || this.state.currentCourse.specifikacia.length===0) {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_specifikacia": "Nebol zvolený žiadny konkrétny kurz!"}}})
      ok=false
    }

    //non-empty date of birth
    if (!this.state.currentCourse['datum'] || this.state.currentCourse['datum']==="") {
      console.log('datum narodenia null alebo ""')
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_datum": "Dátum konania školenia/kurzu nezadaný!"}}})
      ok=false
    }

    //correct format of date of birth
    else{
      if (!this.isDate(this.state.currentCourse['datum'])) {
        this.setState(function(prevState) {return {checks: {...prevState.checks,"error_datum": "Zlý formát, zadaj ako DD.MM.RRRR"}}})
        ok=false
      }
    }

    if (!this.state.currentCourse.predseda || this.state.currentCourse.predseda==="" || this.state.currentCourse.predseda==="Vyber technika") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_predseda": "Potrebné zvoliť predsedu komisie!"}}})
      ok=false
    }

    if (!this.state.currentCourse.typ || this.state.currentCourse.typ==="" || this.state.currentCourse.typ==="Vyber typ") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_typ": "Potrebné zvoliť typ školenia!"}}})
      ok=false
    }

    if (!this.state.currentCourse.specifikacia || this.state.currentCourse.specifikacia.length===0) {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_specifikacia": "Potrebné zvoliť špecifikáciu!"}}})
      ok=false
    }

    if (this.state.currentCourse.typ === this.state.types[2]){
      let data2backend = []
      this.state.currentCourse.ucastnici.map((ucastnik,id)=>{
        let ucastnikFinal=null
        //if updating ZS
        if (this.state.currentCourse_asStored && this.state.currentCourse_asStored.ucastnici.filter(item=>item.id===ucastnik.id).length===0){
          ucastnikFinal = this.check_zs_rz(ucastnik.id,true)
          ucastnikFinal && data2backend.push(ucastnikFinal)
        }
        //if creating new ZS
        if (!this.state.currentCourse_asStored){
          ucastnikFinal = this.check_zs_rz(ucastnik.id,true)
          ucastnikFinal && data2backend.push(ucastnikFinal)
        }
      })
      console.log('data2backend:',data2backend)

      data2backend.map(item=>{
        item.localCourseList.map(localCourse =>{
          let alias = this.showLocalCourseError(item.person_id,localCourse.course_name)
          if (localCourse.onPreukaz.length===0 && !this.state.noPreukazAllowed.includes(alias)){
            this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+item.person_id+"_"+localCourse.course_name]: "Kurz vyžaduje preukaz a teda zadané evidenčné číslo!"}}})
            ok=false
          }
        })
      })
      // this.setState({data2backend: data2backend})

      //check if number of 5 preukaz per course is not exceeded
      let tooManyPr_persons = []
      let tooManyPr_courses = []
      this.state.currentCourse.ucastnici.map(ucastnik=>{
        this.state.allCourses.map(course=>{
          const line = this.getPersonsLineInTable(course.course_alias,ucastnik.id)
          const aliases = this.getLocalCourseAliasesFromCourseName(course)
          const proceed = ucastnik.localCourseList.filter(item => aliases.includes(item.course_name))
          console.log('line,aliases,proceed:',line,aliases,proceed)
          let evcisla=[]
          if (line){
            evcisla = (line[course.course_alias+"_zevcislo"])?line[course.course_alias+"_zevcislo"].split(";;"):[]
            console.log('evcisla in checks from database:',evcisla)
          }
          let local_evcisla = []
          if (proceed.length>0){
            // local_evcisla = (ucastnik.activePreukazy)?ucastnik.activePreukazy:[]
            proceed.map(item=>local_evcisla.push(...item.onPreukaz))
            console.log('evcisla in checks from current course:',local_evcisla)
          }
          const union = Array.from(new Set([...evcisla, ...local_evcisla]));
          if (union.length>0){
            console.log('union of evcisla:',union)
          }
          
          if (union.length>this.state.npreukaz_max){
            tooManyPr_persons.push([ucastnik.priezvisko + " " + ucastnik.meno])
            tooManyPr_courses.push(course.course_nazov)
            ok = false
          }
        
        })
      })
      if (tooManyPr_persons.length>0) {
        this.setState({tooManyPr_persons: tooManyPr_persons, tooManyPr_courses: tooManyPr_courses, showMaxPreukazWarning: true})
      }
      // this.setState(function(prevState) {return {currentCourse: {...prevState.currentCourse,data2backend: data2backend}}})
    }
    if (this.state.currentCourse.ucastnici.length + this.state.currentCourse.ucastnici_rozsirit.length === 0) {ok = false}

    if (this.state.currentCourse.ucastnici.length + this.state.currentCourse.ucastnici_rozsirit.length > 50) {ok = false}

    
  return ok
  } 

  final_checks_clear(err) {
    this.setState(function(prevState) {return {checks: {...prevState.checks,[err]: ""}}})
  }

  final_checks_clearAll() {
    this.setState({checks: {}})
  }

  
  
  isDate(date) {
    return /^(\d{2})(\/|-|\.)(\d{2})(\/|-|\.)(\d{4})$/.test(date);
  }

  
  getCourseAlias(courseName){
    const table = this.state.allCourses.filter(function(item){return item.course_nazov === courseName})[0]
    const alias = table.course_alias
    console.log('table, alias:',table,alias)
    return alias
  }

  getPersonsLineInTable(alias,person_id){
    var table = this.state.database.filter(function(tab) {return tab['table_name']===alias+'s'})[0]['data']
    var line = table.filter(function(line) {return line[alias+'_id']===person_id})
    // console.log('line in getPersonsLineInTable:',line[0],JSON.stringify(line[0]))
    // console.log('line in getPersonsLineInTable:',line[0],JSON.parse(JSON.stringify(line[0])))
    return (line[0])?JSON.parse(JSON.stringify(line[0])):line[0]
  }

  createCourse() {
    // creates course within the separate table 'courses' to keep the evidence at separate place 
    console.log("CREATING COURSE...")
    var data = this.state.currentCourse
    console.log('data to store in createCourse with length: ',data, JSON.stringify(data).length)
    DataService.createCourse(data)
      .then(response => {
        console.log('write response:',response.data);
        this.setState({createMessage: "Success: The course was created successfully"})
      })
      .catch(e => {
        console.log(e);
        this.setState({createMessage: "Error: The course failed to be created"})
      });

    if (this.state.currentCourse.typ!==this.state.types[2]){
      const dataToUpdate = this.createCourse2()
      DataService.handleCourse(dataToUpdate)
      .then(response => {
        console.log('write response:',response.data);
        this.setState({createMessage2: "Success: The dates in database were updated successfully"})
      })
      .catch(e => {
        console.log(e);
        this.setState({createMessage2: "Error: The course data update failed"})
      });
    }
    else{
      console.log('data2backend defined:',this.state.currentCourse.data2backend)

      let data2backend = []
      this.state.currentCourse.ucastnici.map((ucastnik,id)=>{
        const ucastnikFinal = this.check_zs_rz(ucastnik.id,true)
        ucastnikFinal && data2backend.push(ucastnikFinal)
      })

      // append data to rozsirit preukazy:
      this.state.currentCourse.ucastnici_rozsirit.map(person=>{
        let preukazy_vytvorit = []
        person.rozsirit_summary.map(action=>{
          action.preukazy_vytvorit['action'] = action.action
          action.preukazy_vytvorit['course_alias'] = action.alias
          preukazy_vytvorit.push(action.preukazy_vytvorit)
        })
        const dict = {'person_id':person.personal_details.id, 'preukazy_vytvorit':preukazy_vytvorit}
        data2backend.push(dict)
      })
      // -------------------------------------------

      
      DataService.handleCourseZS_RS(data2backend)
      .then(response => {
        console.log('write response:',response.data);
        this.setState({createMessage2: "Success: The dates in database were updated successfully"})
      })
      .catch(e => {
        console.log(e);
        this.setState({createMessage2: "Error: The course data update failed"})
      });
    }
  }

  createCourse2(filterDates = false){
    // updates the data of personal profiles involved
    let updateCourseData = [] // array of shape:
                              // [{id_person: 11,
                              //   predOS: JSON.stringify([predOSarr0,...,predOSarr5]), //or predAOP: JSON.stringify([predAOParr0,...,predAOParr5])
                              //   course_alias: some_alias},...]
    let course_names = this.state.allCourses.map(item => item.course_nazov)
    let nopreukaz = -1 // whether person has license card (with unique evcislo or just course attendance)
    
    //prepare list of courses to update
    let courses_to_update = this.state.currentCourse.specifikacia
    let ZZcourses = course_names.filter(function(item){return item.slice(0,2)==="ZZ"})
    if (courses_to_update.includes("ZZ Zdvíhacie zariadenia")){
      courses_to_update = courses_to_update.filter(function(item){return item!=="ZZ Zdvíhacie zariadenia"})
      courses_to_update = Array.prototype.concat(courses_to_update,ZZcourses)
    }


    console.log('course names: ',course_names)
    console.log('course to update: ',courses_to_update)

    const profilesToUpdate = this.state.currentCourse.ucastnici
    const dateToDelete = (filterDates === true) ? this.state.currentCourse_asStored.datum : null

    profilesToUpdate.map(ucastnik =>

      ucastnik.preukazy_obnovit.map(preukaz_obnovit=>{

        console.log('preukaz_obnovit:',preukaz_obnovit)

        if (course_names.includes(preukaz_obnovit)) nopreukaz = true
        else nopreukaz = false

        if (nopreukaz===true) {
          console.log('nopreukaz true')
          //no preukaz so only one course to update

          if (this.state.currentCourse.typ === this.state.types[1]) {//OS
            const table_alias = this.getCourseAlias(preukaz_obnovit) 
            console.log(ucastnik.meno,' ',ucastnik.priezvisko, ' license to renew: ',preukaz_obnovit)
            const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
            const predOS = JSON.parse(line[table_alias+"_predOS"])
            if (filterDates == true) predOS[0] = predOS[0].filter(date => date !== dateToDelete)
            predOS[0].push(this.state.currentCourse.datum)
            updateCourseData.push({'id': ucastnik.person_id,
                                  'predOS': JSON.stringify(predOS),
                                  'course_alias':table_alias,
                                  'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
          }

          if (this.state.currentCourse.typ === this.state.types[0]) {//AOP
            const table_alias = this.getCourseAlias(preukaz_obnovit) 
            const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
            const predAOP = JSON.parse(line[table_alias+"_predAOP"])
            if (filterDates == true) predAOP[0] = predAOP[0].filter(date => date !== dateToDelete)
            predAOP[0].push(this.state.currentCourse.datum)
            updateCourseData.push({'id': ucastnik.person_id,
                                  'predAOP': JSON.stringify(predAOP),
                                  'course_alias':table_alias,
                                  'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
          }
        }


        if (nopreukaz===false){
          console.log('nopreukaz false')
          if (this.state.currentCourse.typ === this.state.types[1]) {//OS
            console.log('check all courses in courses_to_update lines for preukaz_obnovit.\
            If you find one, then check which is the preukaz within evcisla to get which array predOSarr1...predOSarr5 to update. Then similar to above')
            courses_to_update.map(course =>{
              const table_alias = this.getCourseAlias(course) 
              // console.log('table alias: ',table_alias)
              const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
              console.log('line:',line)
              let evcisla_ucastnik = null
              if (line) evcisla_ucastnik = line[table_alias+'_zevcislo'].split(';;')
              if (line && evcisla_ucastnik.includes(preukaz_obnovit)){
                const idx = evcisla_ucastnik.indexOf(preukaz_obnovit)
                console.log(ucastnik.meno, ' ', ucastnik.priezvisko,' found license to renew: ',preukaz_obnovit, 'in ',evcisla_ucastnik, 'idx:', idx)
              
                let inputAlreadyPresent = updateCourseData.filter(function(item){return (item.id===ucastnik.person_id && item.course_alias === table_alias)})
                console.log('input already there?...',inputAlreadyPresent)
                let predOS = "";
                if (inputAlreadyPresent && inputAlreadyPresent.length!==0){
                  // if updateCourseData contains already element with same person_id and and course alias, update this line instead putting new one
                  const idxPresent = updateCourseData.indexOf(inputAlreadyPresent[0])
                  predOS = JSON.parse(inputAlreadyPresent[0].predOS)
                  if (filterDates == true) predOS[idx + 1] = predOS[idx + 1].filter(date => date !== dateToDelete)
                  predOS[idx + 1].push(this.state.currentCourse.datum)
                  console.log('updated OS course: ',predOS)
                  updateCourseData[idxPresent]['predOS'] = JSON.stringify(predOS)
                }else{
                  // otherwise append new line into list of updates
                  predOS = JSON.parse(line[table_alias+"_predOS"])
                  if (filterDates == true) predOS[idx + 1] = predOS[idx + 1].filter(date => date !== dateToDelete)
                  predOS[idx + 1].push(this.state.currentCourse.datum)
                  console.log('updated OS course: ',predOS)
                  updateCourseData.push({'id': ucastnik.person_id,
                                        'predOS': JSON.stringify(predOS),
                                        'course_alias':table_alias,
                                        'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
                }
              }
            })
          }
          if (this.state.currentCourse.typ === this.state.types[0]) {//AOP
            console.log('check all courses in courses_to_update lines for preukaz_obnovit.\
            If you find one, then check which is the preukaz within evcisla to get which array predAOParr1...predAOParr5 to update. Then similar to above')
            courses_to_update.map(course =>{
              const table_alias = this.getCourseAlias(course) 
              const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
              console.log('line:',line)
              let evcisla_ucastnik = null
              if (line) evcisla_ucastnik = line[table_alias+'_zevcislo'].split(';;')
              if (line && evcisla_ucastnik.includes(preukaz_obnovit)){
                const idx = evcisla_ucastnik.indexOf(preukaz_obnovit)
                console.log(ucastnik.meno, ' ', ucastnik.priezvisko,' found license to renew: ',preukaz_obnovit, 'in ',evcisla_ucastnik, 'idx:', idx)
              
                let inputAlreadyPresent = updateCourseData.filter(function(item){return (item.id===ucastnik.person_id && item.course_alias === table_alias)})
                console.log('input already there?...',inputAlreadyPresent)
                let predAOP = "";
                if (inputAlreadyPresent && inputAlreadyPresent.length!==0){
                  // if updateCourseData contains already element with same person_id and and course alias, update this line instead putting new one
                  const idxPresent = updateCourseData.indexOf(inputAlreadyPresent[0])
                  predAOP = JSON.parse(inputAlreadyPresent[0].predAOP)
                  if (filterDates == true) predAOP[idx + 1] = predAOP[idx + 1].filter(date => date !== dateToDelete)
                  predAOP[idx + 1].push(this.state.currentCourse.datum)
                  console.log('updated OS course: ',predAOP)
                  updateCourseData[idxPresent]['predAOP'] = JSON.stringify(predAOP)
                }else{
                  // otherwise append new line into list of updates
                  predAOP = JSON.parse(line[table_alias+"_predAOP"])
                  if (filterDates == true) predAOP[idx + 1] = predAOP[idx + 1].filter(date => date !== dateToDelete)
                  predAOP[idx + 1].push(this.state.currentCourse.datum)
                  console.log('updated AOP course: ',predAOP)
                  updateCourseData.push({'id': ucastnik.person_id,
                                        'predAOP': JSON.stringify(predAOP),
                                        'course_alias':table_alias,
                                        'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
                }
              }
            })
          }
        }
      })
    )
    console.log('updateCourseData:',updateCourseData)
    return updateCourseData
  }

  createLoading(){
    // console.log('entering createLoading...',this.state.createMessage, this.state.createMessage2)
      if (this.state.createMessage!=="" && this.state.createMessage2!=="") {
        // this.setState({showSpinner: false})
        return true
      }
      else return false
  }

  createSuccess(){
    // console.log('entering createSuccess...')
      if (this.state.createMessage.slice(0,7)==="Success" && this.state.createMessage2.slice(0,7)==="Success") {
        return true
      }
      else return false
  }



  updateCourse() {
    console.log("UPDATING COURSE...")
    var data = this.state.currentCourse
    console.log('data to store in updateCourse: ',data)

    //update separate data in table skolenies
    DataService.updateCourse(data)
      .then(response => {
        console.log('Success: The course was updated successfully:',response.data);
        this.setState({updateMessage: "Success: The course was updated successfully"})
      })
      .catch(e => {
        console.log(e);
        this.setState({updateMessage: "Error: The course failed to be updated"})
      });

    //now handle the data in personal profiles: simplest (not optimal) solution is to delete data from stored course and add from the actualized one

    if (this.state.currentCourse.typ!==this.state.types[2]){
      // fist delete the old data 
      const dataToDelete = this.deleteCourse2()
      DataService.handleCourse(dataToDelete)
      .then(response => {
        console.log('Success: The dates in database were updated=deleted successfully:',response.data);
        this.setState({deleteMessage2: "Success: The dates in database were updated successfully"})
      })
      .then(response =>{
        const dataToAdd = this.createCourse2(true)
        DataService.handleCourse(dataToAdd)
        .then(response => {
          console.log('Success: The dates in database were updated=created successfully:',response.data);
          this.setState({createMessage2: "Success: The dates in database were updated successfully"})
        })
        .catch(e => {
          console.log(e);
          this.setState({createMessage2: "Error: The course data update failed"})
      });

      })
      .catch(e => {
        console.log(e);
        this.setState({deleteMessage2: "Error: The course data update failed"})
      });
    }else{
      console.log('develop update routine for ZS/RS')
      {
        let data2backend = []
        this.state.currentCourse.ucastnici.map((ucastnik,id)=>{
        let ucastnikFinal=null
          if (this.state.currentCourse_asStored.ucastnici.filter(item=>item.id===ucastnik.id).length===0){
            console.log('ucastnik will be treated:',ucastnik)
            ucastnikFinal = this.check_zs_rz(ucastnik.id,true)
            ucastnikFinal && data2backend.push(ucastnikFinal)
          }
        })
        console.log('data2backend update:',data2backend)
        // this.state.currentCourse.data2backend && DataService.handleCourseZS_RS(this.state.currentCourse.data2backend)
        DataService.handleCourseZS_RS(data2backend)
        .then(response => {
          console.log('write response in course update data2backend:',response.data);
          this.setState({createMessage2: "Success: The dates in database were updated successfully"})
        })
        .catch(e => {
          console.log(e);
          this.setState({createMessage2: "Error: The course data update failed"})
        });
      }
      // this.setState({createMessage2: "Success", deleteMessage2: "Success"})
    }
  }


  updateLoading(){
    // console.log('entering updateLoading...',this.updateMessage,this.state.createMessage2, this.state.deleteMessage2)
    if (this.state.currentCourse.typ!==this.state.types[2]){
      if (this.state.updateMessage!=="" && this.state.createMessage2!=="" && this.state.deleteMessage2!=="") {
        return true
      }
      else {return false}
    }else{
      if (this.state.updateMessage!=="" && this.state.createMessage2!=="") {
        return true
      }
      else {return false}
    }
  }

  updateSuccess(){
    // console.log('entering updateSuccess...')
    if (this.state.currentCourse.typ!==this.state.types[2]){
      if (this.state.updateMessage.slice(0,7)==="Success" && this.state.createMessage2.slice(0,7)==="Success" && this.state.deleteMessage2.slice(0,7)==="Success") {
        return true
      }
      else {return false}
    }else{
      if (this.state.updateMessage.slice(0,7)==="Success" && this.state.createMessage2.slice(0,7)==="Success") {
        return true
      }
      else {return false}
    }
  }


  deleteCourse() {
    // deletes course within the separate table 'courses' to keep the evidence at separate place 
    console.log("DELETING COURSE...")
    var skolenie_id = this.state.currentCourse_asStored.skolenie_id
    console.log('data to delete in deleteCourse: ',skolenie_id)

    DataService.deleteCourse(skolenie_id)
      .then(response => {
        console.log('write response:',response.data);
        this.setState({deleteMessage: "Success: The course was deleted successfully"})
      })
      .catch(e => {
        console.log(e);
        this.setState({deleteMessage: "Error: The course failed to be deleted"})
      });

    if (this.state.currentCourse.typ!==this.state.types[2]){
      const dataToUpdate = this.deleteCourse2()
      DataService.handleCourse(dataToUpdate)
      .then(response => {
        console.log('write response:',response.data);
        this.setState({deleteMessage2: "Success: The dates in database were updated successfully"})
      })
      .catch(e => {
        console.log(e);
        this.setState({deleteMessage2: "Error: The course data update failed"})
      });
    }else{
      console.log('develop delete fo ZS/RS')
      this.setState({deleteMessage2: "Success"})
    }
  }

  deleteCourse2(){
    // updates the data of personal profiles involved
    let updateCourseData = [] // array of shape:
                              // [{id_person: 11,
                              //   predOS: JSON.stringify([predOSarr0,...,predOSarr5]), //or predAOP: JSON.stringify([predAOParr0,...,predAOParr5])
                              //   course_alias: some_alias},...]
    let course_names = this.state.allCourses.map(item => item.course_nazov)
    let nopreukaz = -1 // whether person has license card (with unique evcislo or just course attendance)
    
    //prepare list of courses to update
    let courses_to_update = this.state.currentCourse_asStored.specifikacia
    let ZZcourses = course_names.filter(function(item){return item.slice(0,2)==="ZZ"})
    if (courses_to_update.includes("ZZ Zdvíhacie zariadenia")){
      courses_to_update = courses_to_update.filter(function(item){return item!=="ZZ Zdvíhacie zariadenia"})
      courses_to_update = Array.prototype.concat(courses_to_update,ZZcourses)
    }


    console.log('course names: ',course_names)
    console.log('course to update: ',courses_to_update)

    const profilesToUpdate = this.state.currentCourse_asStored.ucastnici
    const dateToDelete = this.state.currentCourse_asStored.datum

    profilesToUpdate.map(ucastnik =>

      ucastnik.preukazy_obnovit.map(preukaz_obnovit=>{

        console.log('preukaz_obnovit:',preukaz_obnovit)

        if (course_names.includes(preukaz_obnovit)) nopreukaz = true
        else nopreukaz = false

        if (nopreukaz===true) {
          console.log('nopreukaz true')
          //no preukaz so only one course to update

          if (this.state.currentCourse_asStored.typ === this.state.types[1]) {//OS
            const table_alias = this.getCourseAlias(preukaz_obnovit) //preukaz_obnovit=course_name
            console.log(ucastnik.meno,' ',ucastnik.priezvisko, ' license to renew: ',preukaz_obnovit)
            const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
            const predOS = JSON.parse(line[table_alias+"_predOS"])
            predOS[0] = predOS[0].filter(function(date) {return date !== dateToDelete})
            updateCourseData.push({'id': ucastnik.person_id,
                                  'predOS': JSON.stringify(predOS),
                                  'course_alias':table_alias,
                                  'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
          }

          if (this.state.currentCourse_asStored.typ === this.state.types[0]) {//AOP
            const table_alias = this.getCourseAlias(preukaz_obnovit) 
            const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
            const predAOP = JSON.parse(line[table_alias+"_predAOP"])
            predAOP[0] = predAOP[0].filter(function(date) {return date !== dateToDelete})
            updateCourseData.push({'id': ucastnik.person_id,
                                  'predAOP': JSON.stringify(predAOP),
                                  'course_alias':table_alias,
                                  'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
          }
        }


        if (nopreukaz===false){
          console.log('nopreukaz false')
          if (this.state.currentCourse_asStored.typ === this.state.types[1]) {//OS
            console.log('check all courses in courses_to_update lines for preukaz_obnovit.\
            If you find one, then check which is the preukaz within evcisla to get which array predOSarr1...predOSarr5 to update. Then similar to above')
            courses_to_update.map(course =>{
              const table_alias = this.getCourseAlias(course) 
              // console.log('table alias: ',table_alias)
              const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
              console.log('line:',line)
              let evcisla_ucastnik = null
              if (line) evcisla_ucastnik = line[table_alias+'_zevcislo'].split(';;')
              if (line && evcisla_ucastnik.includes(preukaz_obnovit)){
                const idx = evcisla_ucastnik.indexOf(preukaz_obnovit)
                console.log(ucastnik.meno, ' ', ucastnik.priezvisko,' found license to renew: ',preukaz_obnovit, 'in ',evcisla_ucastnik, 'idx:', idx)
              
                let inputAlreadyPresent = updateCourseData.filter(function(item){return (item.id===ucastnik.person_id && item.course_alias === table_alias)})
                console.log('input already there?...',inputAlreadyPresent)
                let predOS = "";
                if (inputAlreadyPresent && inputAlreadyPresent.length!==0){
                  // if updateCourseData contains already element with same person_id and and course alias, update this line instead putting new one
                  const idxPresent = updateCourseData.indexOf(inputAlreadyPresent[0])
                  predOS = JSON.parse(inputAlreadyPresent[0].predOS)
                  predOS[idx + 1] = predOS[idx + 1].filter(date => date !== dateToDelete)
                  console.log('updated OS course: ',predOS)
                  updateCourseData[idxPresent]['predOS'] = JSON.stringify(predOS)
                }else{
                  // otherwise append new line into list of updates
                  predOS = JSON.parse(line[table_alias+"_predOS"])
                  console.log('backup state currentCourse:',this.state.currentCourse_asStored,this.state.currentCourse_asStored.datum)
                  console.log('what to filter:',predOS[idx+1],'exclude: ',dateToDelete)
                  predOS[idx+1] = predOS[idx + 1].filter(function(date) {return (date !== dateToDelete)})
                  console.log('updated OS course: ',predOS)
                  updateCourseData.push({'id': ucastnik.person_id,
                                        'predOS': JSON.stringify(predOS),
                                        'course_alias':table_alias,
                                        'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
                }
              }
            })
          }
          if (this.state.currentCourse_asStored.typ === this.state.types[0]) {//AOP
            console.log('check all courses in courses_to_update lines for preukaz_obnovit.\
            If you find one, then check which is the preukaz within evcisla to get which array predAOParr1...predAOParr5 to update. Then similar to above')
            courses_to_update.map(course =>{
              const table_alias = this.getCourseAlias(course) 
              const line = this.getPersonsLineInTable(table_alias,ucastnik.person_id)
              console.log('line:',line)
              let evcisla_ucastnik = null
              if (line) evcisla_ucastnik = line[table_alias+'_zevcislo'].split(';;')
              if (line && evcisla_ucastnik.includes(preukaz_obnovit)){
                const idx = evcisla_ucastnik.indexOf(preukaz_obnovit)
                console.log(ucastnik.meno, ' ', ucastnik.priezvisko,' found license to renew: ',preukaz_obnovit, 'in ',evcisla_ucastnik, 'idx:', idx)
              
                let inputAlreadyPresent = updateCourseData.filter(function(item){return (item.id===ucastnik.person_id && item.course_alias === table_alias)})
                console.log('input already there?...',inputAlreadyPresent)
                let predAOP = "";
                if (inputAlreadyPresent && inputAlreadyPresent.length!==0){
                  // if updateCourseData contains already element with same person_id and and course alias, update this line instead putting new one
                  const idxPresent = updateCourseData.indexOf(inputAlreadyPresent[0])
                  predAOP = JSON.parse(inputAlreadyPresent[0].predAOP)
                  predAOP[idx + 1] = predAOP[idx + 1].filter(function(date) {return date !== dateToDelete})
                  console.log('updated OS course: ',predAOP)
                  updateCourseData[idxPresent]['predAOP'] = JSON.stringify(predAOP)
                }else{
                  // otherwise append new line into list of updates
                  predAOP = JSON.parse(line[table_alias+"_predAOP"])
                  predAOP[idx + 1] = predAOP[idx + 1].filter(function(date) {return date !== dateToDelete})
                  console.log('updated AOP course: ',predAOP)
                  updateCourseData.push({'id': ucastnik.person_id,
                                        'predAOP': JSON.stringify(predAOP),
                                        'course_alias':table_alias,
                                        'person': ucastnik.meno + ' ' + ucastnik.priezvisko})
                }
              }
            })
          }
        }
      })
    )
    // console.log('updateCourseData:',updateCourseData)
    return updateCourseData
  }

  deleteLoading(){
    // console.log('entering deleteLoading...',this.state.deleteMessage, this.state.deleteMessage2)
      if (this.state.deleteMessage!=="" && this.state.deleteMessage2!=="") {
        return true
      }
      else return false
  }

  deleteSuccess(){
    // console.log('entering deleteSuccess...')
      if (this.state.deleteMessage.slice(0,7)==="Success" && this.state.deleteMessage2.slice(0,7)==="Success") {
        return true
      }
      else return false
  }


  cancel(course) {
    let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
    currentCourse_tmp.specifikacia = this.state.currentCourse.specifikacia.filter(function(item) { 
              return item !== course 
            })

      this.setState({currentCourse:currentCourse_tmp},()=>{
      
      const combined_attendees = this.show_default_attendees('truefalse')
      const attendees = combined_attendees.filter(item => item['attendee'] === true)
      const default_attendees = combined_attendees.filter(item => item['default_attendee'] === true)

      console.log('attendees in cancel()',attendees,currentCourse_tmp.specifikacia)
      if (this.state.currentCourse.typ!==this.state.types[2]){
        this.setState(function(prevState){return {
          currentCourse: {
            ...prevState.currentCourse,
            'ucastnici': (attendees) ? attendees : []
          },
          default_attendees: default_attendees
        }
        })
      }
      else{
        // let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        // let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        this.state.currentCourse.ucastnici.map((ucastnik,index)=>{
          let localCourseListUpdated = ucastnik.localCourseList.filter(item => item.course_name!==course)
          // console.log('localCourseListUpdated:',localCourseListUpdated)
          currentCourse_tmp.ucastnici[index].localCourseList = localCourseListUpdated
          // currentCourse_tmp.ucastnici.filter(item=>item.person_id===ucastnik.person_id).localCourseList = localCourseListUpdated
        })
        this.setState({currentCourse: currentCourse_tmp})
      }
    })
  }
   
  cancelLocal(course,person_id) {
    console.log('in cancelLocal: person_id',person_id)
    let ucastnik = this.state.currentCourse.ucastnici.filter(function(person) {console.log('person.person_id:',person.person_id); return person.person_id === person_id})[0]
    let localCourseListUpdated = null
    localCourseListUpdated = ucastnik.localCourseList.filter(localCourse => localCourse.course_name !== course)
    // let currentCourse_tmp = {...this.state.currentCourse}
    let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
    console.log('cancel Local tmp:',currentCourse_tmp)
    if (localCourseListUpdated.length===0){
      console.log('in cancelLocal - deleting local course and whole ucastnik profile')
      //remove the person from the ucastnici list
      currentCourse_tmp.ucastnici = currentCourse_tmp.ucastnici.filter(ucastnik=>ucastnik.id!==person_id)
    }else{
      console.log('in cancelLocal:',currentCourse_tmp.ucastnici,ucastnik,currentCourse_tmp.ucastnici.indexOf(ucastnik))
      currentCourse_tmp.ucastnici.filter(item=>item.person_id===ucastnik.person_id)[0].localCourseList = localCourseListUpdated
    }
    this.setState({currentCourse: currentCourse_tmp})
  }

  searchByName() {
    DataService.findByName(this.state.searchByName)
      .then(response => {
        this.setState({
          searchedByName: response.data,
          searched: true
        });
        console.log('searchTitle string:',this.state.searchByName,' response:',response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  isRightSubset = (s1, s2) => {
    if (s1.size != s2.size) return false;
    const arr1 = [...s1];
    const arr2 = [...s2];
  
    for (let x of arr1) {
  
      if (!arr2.some(item=>item.includes(x))) {
        return false;
      }else{
        const idx = arr2.indexOf(x)
        // console.log('index,arr2',idx,arr2)
        if (idx>=0){
          // console.log('index,arr2',idx,arr2)
          arr2.splice(idx,1)
        }else{
          if (arr1.length>1 && x!==arr1[arr1.length-1]) return false
        }
      }
      }
    return true;
    }

  searchByNameLocal() {
        // process search string
        const search = (this.state.searchByName)?this.state.searchByName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" "):""
        console.log('searched string:',search)
        
        let data_send = []
        this.state.personal_details && this.state.personal_details.forEach(item=>{

          const meno_parsed = item.meno.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
          const priezvisko_parsed = item.priezvisko.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
          const target = [...meno_parsed,...priezvisko_parsed]
          const out = this.isRightSubset(search,target)
          // console.log('target,search,out:',target,search,out)
          if (out===true) data_send.push(item)
        })
            
        this.setState({
          searchedByName: data_send,
          searched: true
        });
        console.log('searchTitle string:',this.state.searchByName,' results:',data_send);
  }
  
  searchCompany(toSearch) {
    console.log('searchCompany:',toSearch)
    toSearch!=="" && DataService.findCompanyByName(toSearch)
      .then(response => {
        this.setState({
          companies: response.data,
          companies_searched: true
        });
        console.log('searchCompany response:',response.data);
      })
      .catch(e => {
        console.log(e);
      });

    toSearch==="" && this.state.allCompanies && this.setState({
        companies: this.state.allCompanies,
        companies_searched: true
      });
  }

  setActivePerson(person, index) {
    // person: a single personal profile from personal_details table from database
    // index: index if this profile within the searched list of profiles
    // -- in short, this function includes or excludes a personal profile within a given course.
    // if a profile is unselected, the person's data are deleted from ucastnici array, 
    // if the person is selected, this.check_aop_os or this.check_zs_rz are run and the results of theses are attached to personal profile data
    // and included into ucastnici/ucastnici_rozsirit array
    
    console.log('entering setActivePerson:',person, index)

    var change = 0
    var all_searched = this.state.searchedByName //all profiles found by 
    var person_status = null;
    // is this kurz os/aop or zs/rs?
    const aop_os = (this.state.currentCourse.typ === this.state.types[0] || this.state.currentCourse.typ === this.state.types[1])?true:false
    const zs_rs = (this.state.currentCourse.typ === this.state.types[2])?true:false

    if (this.state.currentCourse.ucastnici.filter(function(ucastnik) {return ucastnik.id === person.id}).length === 1 || this.state.currentCourse.ucastnici_rozsirit.filter(function(ucastnik) {return ucastnik.personal_details.id === person.id}).length === 1) all_searched[index].checked = true
    if ("checked" in all_searched[index]) {
      
      if (change===0) {
        if (all_searched[index].checked===true) all_searched[index].checked = false
        else {
          if (aop_os===true) {person_status = this.check_aop_os(person.id,true,person['zamestnávateľ'])} //OS/AOP
          if (zs_rs===true) {person_status = this.check_zs_rz(person.id)} //ZS/RS
          if (person_status && aop_os===true && person_status['preukazy_obnovit'].length > 0) all_searched[index].checked = true
          if (person_status && zs_rs===true && person_status.localCourseList.length > 0) all_searched[index].checked = true
        } 
        change=1
      }
      if (change === 1 && all_searched[index].checked===true) {
        var currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        currentCourse_tmp.ucastnici.push({...person_status, ...person})
        this.setState({currentCourse: currentCourse_tmp})
      } 
      else if (change === 1){
        var currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        currentCourse_tmp.ucastnici = this.state.currentCourse.ucastnici.filter(function(item){return item.id!==person.id})
        currentCourse_tmp.ucastnici_rozsirit = this.state.currentCourse.ucastnici_rozsirit.filter(function(item){return item.personal_details.id!==person.id})
        this.setState({currentCourse: currentCourse_tmp})
      }
    }
    else {
      if (aop_os===true) {person_status = this.check_aop_os(person.id,true,person['zamestnávateľ'])} //OS/AOP
      if (zs_rs===true) {person_status = this.check_zs_rz(person.id)} //ZS/RS
      if ((person_status && aop_os===true && person_status['preukazy_obnovit'].length > 0) ||
         (person_status && zs_rs===true && person_status.localCourseList.length > 0)) {
        all_searched[index].checked = true
        var currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        currentCourse_tmp.ucastnici.push({...person_status, ...person})
        this.setState({currentCourse: currentCourse_tmp})
      }
    }
    this.setState({searchedByName: all_searched})
  }


  future_true_dates(date){
    //returns 1 if date is in future and -1 if in the past

    if (!this.isDate(date)) return "wrong format"
    let today = new Date()
    let day_now = today.getDate()
    let month_now = today.getMonth()+1
    let year_now = today.getFullYear()

    let date_split  =date.split('.')
    let day_check = Number(date_split[0])
    let month_check = Number(date_split[1])
    let year_check = Number(date_split[2])
    // console.log('now:',day_now,month_now,year_now)
    // console.log('check:',day_check,month_check,year_check)

    if (year_now > year_check) return -1
    if (year_now < year_check) return 1
    if (year_now === year_check) {
      if (month_now > month_check) return -1
      if (month_now < month_check) return 1
      if (month_now === month_check) {
        if (day_now > day_check) return -1
        if (day_now < day_check) return 1
        if (day_now === day_check) return 1
      }
    }
  }

  future_pos_diff(date,date_ref = null){
    //returns 1 if date is in future and -1 if in the past

    let day_now, month_now, year_now

    if (!this.isDate(date)) return "wrong format"
    if (date_ref){
      let date_now_split  = date_ref.split('.')
      day_now = Number(date_now_split[0])
      month_now = Number(date_now_split[1])
      year_now = Number(date_now_split[2])
    }
    else{
      let today = new Date()
      day_now = today.getDate()
      month_now = today.getMonth()+1
      year_now = today.getFullYear()
    }

    let date_split  =date.split('.')
    let day_check = Number(date_split[0])
    let month_check = Number(date_split[1])
    let year_check = Number(date_split[2])
    console.log('now:',day_now,month_now,year_now)
    console.log('check:',day_check,month_check,year_check)
    console.log('check diff:',(year_check - year_now)*365 ,(month_check - month_now)*30 ,(day_check - day_now))
    let diff = (year_check - year_now)*365 + (month_check - month_now)*30 + (day_check - day_now)
    return diff
  }

  addChip = (value,person) => {
    const chips = person.preukazy.slice();
    if (this.state.allEvcisla.includes(value)){
      this.setState(function(prevState) {return {checks: {...prevState.checks,["error_evcislo_"+person.id]: "Evidenčné číslo už v databáze existuje!"}}})
    }else{
      if (value===""){
        this.setState(function(prevState) {return {checks: {...prevState.checks,["error_evcislo_"+person.id]: "Evidenčné číslo nemôže byť prázdne!"}}})
      }
      else{
        chips.push(value);
        let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
        const currentPersonIndex = currentCourse_tmp.ucastnici.findIndex(item=>item.person_id === person.person_id)
        currentCourse_tmp.ucastnici[currentPersonIndex].preukazy = chips
        this.setState({ currentCourse: currentCourse_tmp, allEvcisla: [...this.state.allEvcisla, value]});
      }
    }
  };
  addChip2 = (value) => {
    console.log('entering addChip2',value)
    if (this.state.allEvcisla.includes(value)){
      console.log('ev cislo uz pouzite',value)
      // this.setState({rsPreukazyNewError: [...this.state.rsPreukazyNewError,`Preukaz ${value} už v databáze existuje`]})
      return `Preukaz ${value} už v databáze existuje`
    }else{
      if (value===""){
        // this.setState({rsPreukazyNewError: [...this.state.rsPreukazyNewError,`Ev. číslo nemôže byť prázdne`]})
        return `Ev. číslo nemôže byť prázdne`
      }else{
        // this.setState({rsPreukazyNewError: [...this.state.rsPreukazyNewError, "ok"]})
        return "ok"
      }
    }
  };
  removeChip = (index,person) => {
    console.log('in remove chip: ',index )
    const preukazToDelete = person.preukazy[index]
    const chips = person.preukazy.slice();
    chips.splice(index, 1);

    let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
    const currentPersonIndex = currentCourse_tmp.ucastnici.findIndex(item=>item.person_id === person.person_id)
    currentCourse_tmp.ucastnici[currentPersonIndex].localCourseList.map((localCourse,index)=>{
      currentCourse_tmp.ucastnici[currentPersonIndex].localCourseList[index].onPreukaz = currentCourse_tmp.ucastnici[currentPersonIndex].localCourseList[index].onPreukaz.filter(pr => pr!==preukazToDelete)
    })
    currentCourse_tmp.ucastnici[currentPersonIndex].preukazy = chips
    this.setState({ currentCourse: currentCourse_tmp, allEvcisla: this.state.allEvcisla.filter(item=>item!==preukazToDelete) });
  };

  changePreukazInCourse(person,localCourse,localCourse_idx,preukaz){
    // console.log('previous states:',this.state.currentCourse.ucastnici[0].localCourseList,this.state.currentCourse_asStored.ucastnici[0].localCourseList)
    if (person.localCourseList[localCourse_idx].onPreukaz.includes(preukaz)){
      person.localCourseList[localCourse_idx].onPreukaz = person.localCourseList[localCourse_idx].onPreukaz.filter(pr => pr!==preukaz)
    }
    else{
      person.localCourseList[localCourse_idx].onPreukaz.push(preukaz)
    }
    // let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
    // const index = currentCourse_tmp.ucastnici.findIndex(item=>item.person_id === person.person_id)
    // currentCourse_tmp.ucastnici[index] = person
    
    // this.setState({currentCourse: currentCourse_tmp},console.log('previous states after:',this.state.currentCourse.ucastnici[0].localCourseList,this.state.currentCourse_asStored.ucastnici[0].localCourseList))
  }

  showLocalCourseError(person_id,localCourseName){
    console.log('localName:',localCourseName)
    let alias = null
    this.state.allCourses.map(course=>{
      console.log('course.rozsah:',course.course_rozsah)
      if (!course.course_rozsah){
        if (course.course_nazov===localCourseName){
          alias = course.course_alias
        }
      }
      else{
        let rozsahy = course.course_rozsah.split(";;")
        if (rozsahy.includes(localCourseName.split(" ").slice(2).join(" "))){
          alias = course.course_alias
        }
      }
    })
    console.log("infered alias: ",localCourseName, alias)
    console.log("returns: ",this.state.checks["error_"+person_id+"_"+alias],this.state.checks["error_"+person_id+"_"+alias]!=="",this.state.checks["error_"+person_id+"_"+alias] && this.state.checks["error_"+person_id+"_"+alias]!=="")
    return alias
  }
  getFeasibleSubitems(course_nazov,evcislo,table_entry_rozsahy,getLocalName = true){
    const rozsahy = JSON.parse(table_entry_rozsahy)
    // console.log('parsed rozsahy:',rozsahy)
    let subitems = []
    Object.keys(rozsahy).map(key=>{
      if (rozsahy[key].includes(evcislo)){
        if (getLocalName===true){
          const prefix1 = course_nazov.split(" ")[0]
          const prefix2 = course_nazov.split(" ")[1]
          const localName = prefix1 + " " + prefix2 + ": " + key
          subitems.push(localName)
        }else{
          subitems.push(rozsahy[key])
        }
      }
    })
    return subitems
  }

  getFeasiblePreukazy(person_id){
    // searches course tables in the database for id - only those with evcislo
    var preukazy = []
    var courses = []
    var preukazy_scope = {}
    var problematic_scope = {}
    this.state.allCourses.map((course) => {
      const alias = course.course_alias
      // const courseLocalAlias = this.getLocalCourseAliasesFromCourseName(course.course_nazov)
      const line = this.getPersonsLineInTable(alias,person_id)
      if (line && line[alias+'_zskuska'].length>0) { 
        var evcisla = (line[alias+'_zevcislo'])?line[alias+'_zevcislo'].split(';;'):['']; 
        console.log('evcisla,alias:',evcisla,alias)
        evcisla.map(evcislo=>{
          if (line[alias+'_zrozsah'] && line[alias+'_zrozsah']!="null"){
            // console.log('rozsahy detected:',line[alias+'_zrozsah'])
            //podrozsahy - check if evcislo in relevant ones
            const feasibleSubitems = this.getFeasibleSubitems(course.course_nazov,evcislo,line[alias+'_zrozsah'])
            console.log('feasible subitems:',feasibleSubitems)
            const feasible = feasibleSubitems.some(subitem=>this.state.currentCourse.specifikacia.includes(subitem))
            const problematicSubitems = feasibleSubitems.filter(subitem=>!this.state.currentCourse.specifikacia.includes(subitem))
            console.log('is feasible:',feasible,evcislo)
            // if (!(preukazy.includes(evcislo)) && evcislo!=="" && feasible===true) {
            if (evcislo!=="" && feasible===true) {
              if (!preukazy.includes(evcislo)) {preukazy.push(evcislo)}
              courses.push(alias)
              if (!(evcislo in preukazy_scope)){
                preukazy_scope[evcislo] = []
              }
              feasibleSubitems.map(item=>preukazy_scope[evcislo].push(item))
            }
            if (evcislo!=="" && problematicSubitems.length>0) {
              if (!(evcislo in problematic_scope)){
                problematic_scope[evcislo] = []
              }
              problematicSubitems.map(item=>problematic_scope[evcislo].push(item))
            }
            

          }else{
            //no podrozsahy - preukaz is feasible
            const feasible = this.state.currentCourse.specifikacia.includes(course.course_nazov)
            console.log('is feasible:',feasible,evcislo,line)
            // if (!(preukazy.includes(evcislo)) && evcislo!=="" && feasible===true) {
            if (evcislo!=="" && feasible===true) {
              if (!(preukazy.includes(evcislo))) {preukazy.push(evcislo)}
              courses.push(alias)

              if (!(evcislo in preukazy_scope)){
                preukazy_scope[evcislo] = []
              }
              preukazy_scope[evcislo].push(course.course_nazov)
            }
            if (evcislo!=="" && feasible===false) {
              if (!(evcislo in problematic_scope)){
                problematic_scope[evcislo] = []
              }
              problematic_scope[evcislo].push(course.course_nazov)
            }
            
          }
        })
      }
    })
    // este pridaj kontrolu, ci preukaz je v specifikacii (parameter funkcie??)
    console.log('in getFeasiblePreukazy:',person_id,preukazy,courses)
    this.setState({scope_problematic:problematic_scope})
    return [preukazy,courses,preukazy_scope]
  }

  archivePreukaz(line,evcislo_old,evcislo_new,alias){
    console.log('archiving line:',line, 'for evcislo:',evcislo_old)
    if (!("course_alias" in line)) {
      line["course_alias"] = alias
    }
    if (!("action" in line)) {
      line["action"] = 'update'
    }
    // return JSON.stringify({'archived':evcislo_old,'line':line})
    const update_line = JSON.stringify(line)
    update_line.replace(evcislo_new,evcislo_old)
    console.log('archiving2 line:',line, 'for evcislo:',evcislo_old)
    return {'archived':evcislo_old,'new':evcislo_new,'line':JSON.parse(update_line)}
  }

  rsUpdateLine(line,evcislo_old,evcislo_new,courseNamesLocal,alias){
    console.log('updating line:',line,' for evcislo old/new:',evcislo_old,evcislo_new,' and local course names:',courseNamesLocal)
    //replace old ev with new one
    console.log('line[alias+_zevcislo]',alias,line[alias+'_zevcislo'])
    console.log('evcislaStored:',line[alias+'_zevcislo'].split(';;'))
    let evcislaStored = line[alias+'_zevcislo'].split(';;')
    const idx = evcislaStored.indexOf(evcislo_old)
    evcislaStored[idx] = evcislo_new
    //replace old vydanie with new
    let vydania = line[alias+'_zvydanie'].split(';;')
    vydania[idx] = this.state.currentCourse.datum
    let rozsahy = null
    if (line[alias+'_zrozsah'] && line[alias+'_zrozsah']!=='null'){
      rozsahy = JSON.parse(line[alias+'_zrozsah'])
      courseNamesLocal.map(courseNameLocal=>{
        const subitem = courseNameLocal.split(" ").slice(2).join(" ")
        console.log('after subitem:',rozsahy,subitem)
        rozsahy[subitem].map((item,index)=>{
          if (item===evcislo_old){
            rozsahy[subitem][index] = evcislo_new
          }
        })

        if (!rozsahy[subitem].includes(evcislo_old) && !rozsahy[subitem].includes(evcislo_new)){
          rozsahy[subitem].push(evcislo_new)
        }
      })
    }
    let predOS = JSON.parse(line[alias+'_predOS'])
    console.log('evcisla,idx:',evcislo_old,idx)
    predOS[idx+1] = []
    let predAOP = JSON.parse(line[alias+'_predAOP'])
    predAOP[idx+1] = []

    console.log('updated line:',evcislaStored,vydania,(line[alias+'_zrozsah'] && line[alias+'_zrozsah']!=='null')?rozsahy:null,predOS,predAOP)

    
    if (rozsahy){
      line[alias+'_zevcislo']= evcislaStored.join(';;')
      line[alias+'_zvydanie']= vydania.join(';;')
      line[alias+'_zrozsah']= JSON.stringify(rozsahy)
      line[alias+'_predOS']= JSON.stringify(predOS)
      line[alias+'_predAOP']= JSON.stringify(predAOP)
    }else{
      line[alias+'_zevcislo']= evcislaStored.join(';;')
      line[alias+'_zvydanie']= vydania.join(';;')
      line[alias+'_predOS']= JSON.stringify(predOS)
      line[alias+'_predAOP']= JSON.stringify(predAOP)
    }
    
    return line
  }

  rsCreateLine(evcislo_new,courseNamesLocal,alias){
    console.log('creating line for evcislo new:',evcislo_new,' and local course names:',courseNamesLocal, 'and alias:',alias)



    let line = {}
    const hasRozsahy = this.state.allCourses.filter(item=>item.course_alias===alias)[0].course_rozsah
    console.log('rozsahy:',hasRozsahy)
    let rozsahy = {}
    if (hasRozsahy && hasRozsahy!=='null'){
      hasRozsahy.split(';;').map(item=>rozsahy[item] = [])
      courseNamesLocal.map(courseNameLocal=>{
        const subitem = courseNameLocal.split(" ").slice(2).join(" ")
        console.log('after subitem:',rozsahy,subitem)
        rozsahy[subitem].push(evcislo_new)
      })
    }
    let predOS = [[],[],[],[],[],[]]
    let predAOP = [[],[],[],[],[],[]]

    console.log('created line:',(line[alias+'_zrozsah'] && line[alias+'_zrozsah']!=='null')?rozsahy:null,predOS,predAOP)

    
    if (hasRozsahy && hasRozsahy!=='null'){
      line[alias+'_zevcislo']= evcislo_new
      line[alias+'_zvydanie']= this.state.currentCourse.datum
      line[alias+'_zrozsah']= JSON.stringify(rozsahy)
      line[alias+'_predOS']= JSON.stringify(predOS)
      line[alias+'_predAOP']= JSON.stringify(predAOP)
      line[alias+'_zskuska']= this.state.currentCourse.datum
    }else{
      line[alias+'_zevcislo']= evcislo_new
      line[alias+'_zvydanie']= this.state.currentCourse.datum
      line[alias+'_predOS']= JSON.stringify(predOS)
      line[alias+'_predAOP']= JSON.stringify(predAOP)
      line[alias+'_zskuska']= this.state.currentCourse.datum
    }
    
    return line
  }

  mergeUpdateCreate(updateLineExisting,createdLine){

    console.log('merging lines:',updateLineExisting,createdLine)
  }

  async rsSelected(evcisla_new,kurzy_new_create){
    const kurzy_current_update = this.state.scope_preukazy //old evcisla as keys
    const evcisla_old = this.state.rsPreukazy
    console.log('in rsSelected...:')
    console.log('evcisla old, evcisla new:',evcisla_old,evcisla_new)
    console.log('current update, new create:',kurzy_current_update,kurzy_new_create)

    let rozsirit_summary = []
    
    
    let archived = []
    evcisla_new.map((evcislo_new,index)=>{
      //organize updating existing
      let group_updates={}
      const evcislo_old = evcisla_old[index]

      kurzy_current_update[evcislo_old] && Promise.all(
        kurzy_current_update[evcislo_old].map(courseNameLocal=>{
          const alias = this.getCourseAliasFromLocalCourseAliases(courseNameLocal)
          if (alias in group_updates){
            group_updates[alias].push(courseNameLocal)
          }else{
            group_updates[alias] = [courseNameLocal]
          }
          console.log('updating:',alias,group_updates)
        })
      )
      
      // organize creating new
      let group_creates={}
      kurzy_new_create[evcislo_new] && Promise.all(
        kurzy_new_create[evcislo_new].map(courseNameLocal=>{
          const alias = this.getCourseAliasFromLocalCourseAliases(courseNameLocal)
          console.log('alias,group updates:',alias, group_updates,courseNameLocal)
          if (alias in group_updates){
            console.log('updating group_updates')
            //new subitem but already existing update within the course
            group_updates[alias].push(courseNameLocal)
          }else{
            if (alias in group_creates){
              group_creates[alias].push(courseNameLocal)
            }else{
              group_creates[alias] = [courseNameLocal]
            }
          }
        })
      )


      console.log('group update, group new:',group_updates, group_creates)
      let line = null
      let line_archive = null
      let updatedLine = null
      Object.keys(group_updates).map((alias,index2)=>{
        let doAppend = false
        console.log('line to update:',this.getPersonsLineInTable(alias,this.state.person_id_tmp))
        line = (index2===0)?(
          (!rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)[0])?
            this.getPersonsLineInTable(alias,this.state.person_id_tmp)
            :rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)[0].preukazy_vytvorit
          )
          :updatedLine
        
        // line_archive = this.getPersonsLineInTable(alias,this.state.person_id_tmp)
            

        line = 
          (!rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)[0])?
            this.getPersonsLineInTable(alias,this.state.person_id_tmp)
            :rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)[0].preukazy_vytvorit
          
          
        // if (index2===0 && !rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)[0]){
        if (!rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)[0]){
          doAppend = true
        }
          if (rozsirit_summary.filter(item=>item.alias === alias && item.action === 'archive').length===0){
            const archiveLine = this.archivePreukaz(line,evcislo_old,evcislo_new,alias)
            let append = {}
            append['person_id'] = this.state.person_id_tmp
            append['action'] = 'archive'
            append['alias'] = alias
            append['preukazy_vytvorit'] = archiveLine
            rozsirit_summary.push(append)
          }
        // }
        updatedLine = this.rsUpdateLine(line,evcislo_old,evcislo_new,group_updates[alias],alias)
        if (doAppend===true){
          let append = {}
          append['person_id'] = this.state.person_id_tmp
          append['action'] = 'update'
          append['alias'] = alias
          append['preukazy_vytvorit'] = updatedLine
          rozsirit_summary.push(append)
        }
      })
      

      Object.keys(group_creates).map(alias=>{
        const createdLine = this.rsCreateLine(evcislo_new,group_creates[alias],alias)
        let createdLineCorr = null
        //ak su nove podrozsahy, moze sa stat, ze sa robi aj update starych, vtedy treba create line mergnut s update.
        let updateLineExisting = rozsirit_summary.filter(item=>item.action==='update' && item.alias===alias)
        if (updateLineExisting[0]){
          createdLineCorr = this.mergeUpdateCreate(updateLineExisting,createdLine)
        }else{
          createdLineCorr = createdLine
        }
        let append = {}
        append['person_id'] = this.state.person_id_tmp
        append['action'] = 'create'
        append['alias'] = alias
        append['preukazy_vytvorit'] = createdLineCorr
        rozsirit_summary.push(append)
      })
      
    })
    let reversed_scopes = {}
    Object.keys(this.state.scope_preukazy).map((key,index)=>{
      this.state.scope_preukazy[key].map(localCourse=>{
        if (localCourse in reversed_scopes){
          reversed_scopes[localCourse].push(this.state.rsPreukazyNew[index])
        }else{
          reversed_scopes[localCourse] = [this.state.rsPreukazyNew[index]]
        }
      })
    })
    Object.keys(kurzy_new_create).map(key=>{
      kurzy_new_create[key].map(localCourse=>{
        if (localCourse in reversed_scopes){
          reversed_scopes[localCourse].push(key)
        }else{
          reversed_scopes[localCourse] = [key]
        }
      })
    })

    console.log('rozsirit preukazy:',rozsirit_summary)
    console.log('reversed_scopes:',reversed_scopes)
    let person = {}
    const personal_details = this.state.database.filter(item=>item.table_name === 'personal_details')[0]['data'].filter(item2=>item2.id === this.state.person_id_tmp)[0]
    console.log('osoba:',personal_details)
    person['personal_details'] = personal_details
    person['rozsirit_summary'] = rozsirit_summary
    person['rsPreukazyNew'] = this.state.rsPreukazyNew
    person['rsPreukazy'] = this.state.rsPreukazy
    person['scope_preukazy'] = this.state.scope_preukazy
    person['feasible_preukazy'] = this.state.feasible_preukazy
    person['kurzy_new_create'] = kurzy_new_create
    person['reversed_scopes'] = reversed_scopes
    this.setState({rsPreukazyNew: [],rsPreukazy: [], scope_preukazy: {},feasible_preukazy:[]})
    
    // append ucastnici_rozsirit
    let ucastnici_rozsirit_tmp = this.state.currentCourse.ucastnici_rozsirit
    ucastnici_rozsirit_tmp.push(person)
    this.setState(function(prevState) {return {currentCourse: {...prevState.currentCourse,ucastnici_rozsirit: ucastnici_rozsirit_tmp}}})
  }


  rozsirit(temp_profile_id){
    console.log('rozsirit!!!',temp_profile_id)

    let [feasible_preukazy,feasible_courses,scope_preukazy] = this.getFeasiblePreukazy(temp_profile_id)
    console.log('output:',feasible_preukazy,feasible_courses,scope_preukazy )

    if (feasible_preukazy.length===0){
      console.log('some error')
      swal("Uups!", "Niekde sa vyskytla chyba. Napríklad rozširované školenie nemá vedený preukaz s evidenčným číslom, iba dátum základného školenia. Dajte nám vedieť!", "error")
    }
    if (feasible_preukazy.length>1){
      this.setState({show2stepAlert:true,feasible_preukazy:feasible_preukazy,scope_preukazy:scope_preukazy})
    }
    if (feasible_preukazy.length===1){
      console.log('dlzka=1')
      this.setState({showNewEvcisla:true,feasible_preukazy:feasible_preukazy,rsPreukazy:feasible_preukazy,scope_preukazy:scope_preukazy})
    }
  }


  async nonObligatoryLicensedCoursesAllHaveEvcisla(){
    /*
    loops over all courses/subitems of a person with person_id_tmp to check if there is none such a course 
    that has no license number (from noPreukazAllowed)
    returns
      true: if all courses (including those from noPreukazAllowed) have evcislo
      false: if there is ANY such a course from noPreukazAllowed that has no evcislo
    */
    let personal_profile = null
    let output_pred = null
    let output = null

    await DataService.get(this.state.person_id_tmp)
    .then(response => {
      personal_profile = response.data[0][0]
      // console.log('fetched full current profile in nonObligatoryLicensedCoursesAllHaveEvcisla with response:',response.data[0][0]);
    })
    .then(()=>{
      this.state.zsEncountered.forEach(element => {
        const course_alias = this.getCourseAliasFromLocalCourseAliases(element)
        if (this.state.noPreukazAllowed.includes(course_alias) === true){
          // console.log('in nonObligatoryLicensedCoursesAllHaveEvcisla: evcislo:',personal_profile[course_alias+'_zevcislo'])
          if (!personal_profile[course_alias+'_zevcislo']){
            output = false
            console.log('returning false')
          }else{
            console.log('returning true')
            output = true
          }
          if (this.state.rsAllow!==output){
            this.setState({rsAllow: output})
            output_pred = output
          }
        }
      });  
    })
    .catch(err=>
      console.log('error retrieving profile in nonObligatoryLicensedCoursesAllHaveEvcisla()',err)
    )
    
    console.log('nonObligatoryLicensedCoursesAllHaveEvcisla output:',output)
    return output

  }

  async rzAllowed(){
    // let out = null
    // this.nonObligatoryLicensedCoursesAllHaveEvcisla()
    // .then(response=>{
    //   out = 2//response
    //   console.log('in rzAllowed1:',out)
    //   console.log('in rzAllowed2:',response)
    //   // this.setState({rsAllow: response})
    //   // return out
    // })
    // .catch(err=>console.log('some error in rzAllowed:',err))
    // .finally(()=>{
    //   console.log('out in finalize:',out)
    //   return out
    // })
    // return out
    const out = await this.nonObligatoryLicensedCoursesAllHaveEvcisla()
    // console.log('exiting rzAllowed:',out)
    // this.setState({rsAllow: out})
    return out
  }

  less_actual_than_default_or_zero(company){
    const no_persons_of_company = this.state.currentCourse.ucastnici.filter(ucastnik=>ucastnik['zamestnávateľ'] === company.company_nazov).length
    //show company badge if number of attendees for a given company is equal to number from default selection
    const default_number = this.state.default_attendees && this.state.default_attendees.filter(item=>item['zamestnávateľ']===company.company_nazov).length
    return (no_persons_of_company<default_number || no_persons_of_company===0)?true:false
  }

  default_zero(company){
    const default_number = this.state.default_attendees && this.state.default_attendees.filter(item=>item['zamestnávateľ']===company.company_nazov).length
    return (default_number===0)?true:false
  }

  includeCompanysPersons(e,company){
    console.log('includeCompanysPersons:',e,company)
    if (e.target.checked === true){
      let ucastnici_id = []
      let currentCourse_tmp = JSON.parse(JSON.stringify(this.state.currentCourse))
      this.state.currentCourse.ucastnici.map(uc=>ucastnici_id.push(uc.person_id))
      let ucastnici = this.state.currentCourse.ucastnici
      this.state.default_attendees.map(def=>{
        if (!ucastnici_id.includes(def.person_id) && def['zamestnávateľ']===company.company_nazov){
          ucastnici.push(def)
        }
      })
      console.log('asStored before:',this.state.currentCourse_asStored)
      currentCourse_tmp.ucastnici = ucastnici
      this.setState({currentCourse:currentCourse_tmp},()=>{console.log('asStored after:',this.state.currentCourse_asStored)})
    }
    if (e.target.checked === false){
      this.removeAllFromCompany(company.coumpany_nazov,'ucastnici')
    }
  }

  removeAllFromCompany(company_nazov,mode){
    if (mode==='ucastnici'){
      const ucastnici = this.state.currentCourse.ucastnici.filter(function(item){return item['zamestnávateľ']!==company_nazov})
      this.setState(function(prevState){return {
        currentCourse: {
          ...prevState.currentCourse,
          'ucastnici': ucastnici,
        }}
      })
    }
    if (mode==='rozsirit'){
      const ucastnici = this.state.currentCourse.ucastnici_rozsirit.filter(function(item){return item.personal_details['zamestnávateľ']!==company_nazov})
      this.setState(function(prevState){return {
        currentCourse: {
          ...prevState.currentCourse,
          'ucastnici_rozsirit': ucastnici,
        }}
      })
    }
  }

  setStateExceptDatabase(){
    let state = JSON.parse(JSON.stringify(this.state))
    console.log('keys:',Object.keys(state))
    delete state.database
    console.log('keys2:',Object.keys(state))
    return JSON.stringify(state)
  }
  


  render() {

  // if (this.state.currentCourse) console.log('length of currentCourse stringify:',JSON.stringify(this.state.currentCourse).length)
  // console.log('toEdit:',this.state.toEdit)
  if (this.state.toEdit && this.state.toEdit===true && this.state.currentCourse.typ!==""){
    return <Redirect to={{
      pathname: '/edit_profile',
      state: this.state.profileRetrieved,
      whole: this.state
    }} />;
  }
  // console.log('this.state in createCourse:',this.state)
  // console.log("specifikacia.length",this.state.currentCourse.specifikacia,this.state.currentCourse.specifikacia.length)
  var tmp = 0
  const ucastnici_sorted = this.state.currentCourse.ucastnici.sort((a, b) => {
    if (a['zamestnávateľ'] !== b['zamestnávateľ']) {
      if (!a['zamestnávateľ']) return 1
      if (!b['zamestnávateľ']) return -1
      return a['zamestnávateľ'] > b['zamestnávateľ'] ? 1:-1
    }
    else {
      if (a.priezvisko !== b.priezvisko) return a.priezvisko > b.priezvisko ? 1:-1
      else  return a.meno > b.meno ? 1:-1 
    }  
  })

  const ucastnici_rozsirit_sorted = this.state.currentCourse.ucastnici_rozsirit && this.state.currentCourse.ucastnici_rozsirit.sort((a, b) => {
    if (a.personal_details['zamestnávateľ'] !== b.personal_details['zamestnávateľ']) {
      if (!a.personal_details['zamestnávateľ']) return 1
      if (!b.personal_details['zamestnávateľ']) return -1
      return a.personal_details['zamestnávateľ'] > b.personal_details['zamestnávateľ'] ? 1:-1
    }
    else {
      if (a.personal_details.priezvisko !== b.personal_details.priezvisko) return a.personal_details.priezvisko > b.personal_details.priezvisko ? 1:-1
      else  return a.personal_details.meno > b.personal_details.meno ? 1:-1 
    }  
  })

  // console.log('check time diff:',this.future_pos_diff("20.11.2021","21.11.2021"))
  let renewDict = {}
  const createLoad = this.createLoading()
  const createSucc = this.createSuccess()
  const deleteLoad = this.deleteLoading()
  const deleteSucc = this.deleteSuccess()
  const updateLoad = this.updateLoading()
  const updateSucc = this.updateSuccess()
  // console.log('heellowww',updateLoad,updateSucc)
  // console.log('heellowww create',createLoad,createSucc)
  const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
 
let arr_warning = []
console.log('arr_warning:',arr_warning)
console.log('full state in createCourse:',this.state)

function Overlay(props) {
  const person2 = props.person2
  const state = props.state
  // console.log('state:',state,person2)
  let hasInactive = false
  state && state.evcislaDict && Object.keys(state.deadlines).length>0 && person2.preukazy_obnovit.map(pr=>{
    // console.log('person,pr, pr in:',person2,pr,pr in state.evcislaDict)
    if (pr in state.evcislaDict && person2.id in state.evcislaDict[pr]){
      state.evcislaDict[pr][person2.id] && state.evcislaDict[pr][person2.id].map(name=>{
        if (props.isCourseActive(person2,name) === false){hasInactive = true}
      })
    }else{
      if (props.isCourseActive(person2,pr) === false){hasInactive = true}
    }
  })
  if (state) {
  return <OverlayTrigger 
    trigger={['hover','focus']} 
    placement="right" 
    overlay={
      <Popover>
        <Popover.Title as="h3">Obnoviť preukazy:</Popover.Title>
        <Popover.Content>
            {state.evcislaDict && Object.keys(state.deadlines).length>0 && person2.preukazy_obnovit.map(pr=><li>
              {/* {pr} (Platný do: {state.deadlines[person2.id][pr]}) */}
              {pr} (Platný do: {(person2.id in state.deadlines && pr in state.deadlines[person2.id])?state.deadlines[person2.id][pr]:"dátum nenájdený"})
                <ul>
                  {(pr in state.evcislaDict)?(
                    state.evcislaDict[pr][person2.id] && state.evcislaDict[pr][person2.id].map(name=>(
                      <li>{name} {(!props.isCourseActive(person2,name))?(<a><FaExclamationCircle color = "red"/> (kurz neaktívny)</a>):null}</li>
                    ))
                  ):null}
                </ul>
              </li>)}
        </Popover.Content>
      </Popover>
    }
    >
    {/* <button className="kurz-chip line-break" style={{display: 'inline-block'}} > */}
    <button className="kurz-chip" style={{display: 'inline-block'}} >
    {[person2.priezvisko +' '+ person2.meno]} {(hasInactive === true)?<FaExclamationCircle color = "red"/>:null}
      <button type="button" className="close close-custom" aria-label="Close" 
        onClick={()=> {
          var currentCourse_tmp = JSON.parse(JSON.stringify(state.currentCourse))
          currentCourse_tmp.ucastnici = state.currentCourse.ucastnici.filter(function(item){return item.id!==person2.id})
          props.setState({currentCourse: currentCourse_tmp})
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </button>
  </OverlayTrigger>
  } else {
    return null
  }
}

  return (
  <>

    <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, borderTop: 0}}>
      <Row className="fluid" style={{height: '100vh'}}>
 

        <Col xs={2} className="" style={{maxHeight:"100vh"}}>

          <ProSidebar style={{width:"auto",minWidth:"auto"}}>
            <Menu iconShape="square">
              <MenuItem>Zoznam kurzov</MenuItem>
              <SubMenu title="Plánované kurzy" icon={<FaAlgolia />}>
                {(this.state.skolenia)?<li className="pro-menu-item">
                  <div className="pro-inner-item">
                    <input
                      type="text"
                      className="form-control"
                      id="search_future_skolenia"
                      value={this.state.search_future_events}
                      onChange={(e) => {this.setState({search_future_events: e.target.value})}}
                      name=""
                      placeholder="Hľadaj podľa názvu"
                    />
                  </div>
                </li>:<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:5, width: "1.2rem", height: "1.2rem",}}></span>}
                {this.state.skolenia && this.state.skolenia.sort((a,b)=>a.nazov>b.nazov?1:-1).map(skolenie =>(
                  (this.future_true_dates(skolenie['datum'])===1)?
                  ((skolenie['nazov'].toLowerCase().includes(this.state.search_future_events))?<MenuItem 
                  suffix={(skolenie.ucastnici_rozsirit)?skolenie.ucastnici.length+skolenie.ucastnici_rozsirit.length:skolenie.ucastnici.length}
                    onClick={async () =>{
                      await swal({
                        buttons: false,
                        title: 'Načítavam...',
                        type: 'info',
                        text: 'Získavajú sa dáta o aktuálnom kurze...',
                        timer: 100,
                        content: <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{width: "3rem", height: "3rem"}}></span>
                      })
                      // probably in the line below, we do need to run only the show_default_attendees(true): there is no setState() function for 'false' scenario, only the return (which is not used here) is then different. 'true' option sets default_attendees in the state
                      this.setState({currentCourse: skolenie,aktualizovat: true, currentCourse_asStored: JSON.parse(JSON.stringify(skolenie))},function(){this.show_default_attendees(true)},swal.close());this.final_checks_clearAll()}}> 
                      {(skolenie!==null) ? `${skolenie['nazov']} ${" "} (${skolenie['datum']})`: 'NoName'}
                  </MenuItem>:null):null
                ))}
              </SubMenu>
              <SubMenu title="Vykonané kurzy" icon={<FaCheck />}>
                {(this.state.skolenia)?<li className="pro-menu-item">
                  <div className="pro-inner-item">
                    <input
                      type="text"
                      className="form-control"
                      id="search_past_skolenia"
                      value={this.state.search_past_events}
                      onChange={(e) => {this.setState({search_past_events: e.target.value})}}
                      name=""
                      placeholder="Hľadaj podľa názvu"
                    />
                  </div>
                </li>:<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:5, width: "1.2rem", height: "1.2rem",}}></span>}
                {this.state.skolenia && this.state.skolenia.sort((a,b)=>a.nazov>b.nazov?1:-1).map(skolenie =>(
                  (this.future_true_dates(skolenie['datum'])===-1)?
                  ((skolenie['nazov'].toLowerCase().includes(this.state.search_past_events))?
                  <MenuItem 
                    suffix={(skolenie.ucastnici_rozsirit)?skolenie.ucastnici.length+skolenie.ucastnici_rozsirit.length:skolenie.ucastnici.length}
                    onClick={async () =>{
                      await swal({
                        buttons: false,
                        title: 'Načítavam...',
                        type: 'info',
                        text: 'Získavajú sa dáta o aktuálnom kurze...',
                        timer: 100,
                        content: <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{width: "3rem", height: "3rem"}}></span>
                      })
                      // probably in the line below, we do need to run only the show_default_attendees(true) - see previous similar command for more details
                    this.setState({currentCourse: skolenie,aktualizovat: true, currentCourse_asStored: JSON.parse(JSON.stringify(skolenie))},function(){this.show_default_attendees(true)},swal.close());this.final_checks_clearAll();}}>
                      {(skolenie!==null) ? `${skolenie['nazov']} ${" "} (${skolenie['datum']})`: 'NoName'}
                  </MenuItem>:null):null
                ))}
              </SubMenu>
            </Menu>
          </ProSidebar>
        </Col>
        
          <Col xs={8} style={{paddingTop: 15, paddingLeft: 40, paddingRight: 40,marginBottom:30}} className="my_scroll">

            <Row className="w-100">
                {(this.state.aktualizovat && this.state.aktualizovat===true)?
                <div className="div-right">
                  {(this.state.currentCourse.typ === this.state.types[0] || this.state.currentCourse.typ === this.state.types[1])?
                  <button onClick={ () => {this.setState({showDeletePopup: true})}}
                          className="btn btn-danger "
                          style={{marginRight: "1em"}}
                          >              
                      Vymazať
                  </button>:null}
                  <button onClick={ () => {window.location.href='/courses'; this.setState({aktualizovat: false})}}
                          className="btn btn-secondary "
                          >              
                      Nový kurz
                  </button>
                  <button onClick={() =>{
                    console.log('current, stored:',this.state.currentCourse,this.state.currentCourse_asStored)
                    this.setState({currentCourse: this.state.currentCourse_asStored},console.log('current, stored after:',this.state.currentCourse,this.state.currentCourse_asStored));
                    this.final_checks_clearAll()}
                  }
                          className="btn btn-secondary "
                          style={{marginLeft: "1em"}}
                          >              
                      Zahodiť zmeny
                  </button>
                </div>:null}
                <div className="form-group col-lg-12">
                  <input
                    style = {{fontSize: 30,textAlign: "center", color: "white"}}
                    type="text"
                    className="form-control transparent-input"
                    id="nazov"
                    value={this.state.currentCourse.nazov}
                    onChange={(e) => {this.onChangeCourseData(e,'nazov');this.final_checks_clear("error_name")}}
                    name="nazov"
                    placeholder = "*Názov kurzu"
                  />
                  {this.state.checks.error_name && this.state.checks.error_name!=="" && <p className="error">{this.state.checks.error_name}</p>}
                </div>
            </Row>


            <Row>

              {this.state.currentCourse.specifikacia.length>0 && this.state.currentCourse.specifikacia.map(course => (

                <div className="kurz-chip">{course}
                  {(this.state.aktualizovat===true && this.state.currentCourse_asStored.specifikacia.includes(course))?null:
                  <button type="button" className="close close-custom" aria-label="Close" 
                    onClick={()=> 
                      this.cancel(course)
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  }
                </div>
              ))}
            </Row>

              {/* {(!window.sessionStorage.getItem("createCourseState"))? */}
              {!(window.sessionStorage.getItem("createCourseState")!==null && !this.state.database)?
              <Row style={{marginTop:"10px"}}>
                <div className="form-group col-lg-4 required">
                  <label style={{color: "white"}}>Dátum konania</label>
                  <input
                    disabled = {(this.state.currentCourse.typ===this.state.types[2] && this.state.aktualizovat===true)?true:false}
                    type="text"
                    className="form-control"
                    id="vznik"
                    // required
                    value={this.state.currentCourse.datum}
                    onChange={(e) => {this.onChangeCourseData(e,'datum');this.final_checks_clear("error_datum")}}
                    name="vznik"
                    placeholder = "DD.MM.RRRR"
                  />
                  {this.state.checks.error_datum && this.state.checks.error_datum!=="" && <p className="error">{this.state.checks.error_datum}</p>}
                </div>

                <div className="form-group col-lg-4 required">
                  <label style={{color: "white"}}>Typ školenia</label>
                  <select class="custom-select"
                    disabled = {(this.state.currentCourse.typ===this.state.types[2] && this.state.aktualizovat===true)?true:false}
                    value = {this.state.currentCourse.typ}
                    // onChange={e=>{this.setState(function(prevState) {return {currentCourse: {...prevState.currentCourse,typ: e.target.value}} });this.final_checks_clear("error_typ") }}
                    onChange={e=>{this.onChangeCourseData(e,'typ');this.final_checks_clear("error_typ") }}
                  >
                    <option selected>Vyber typ</option>
                    {
                    (this.state.aktualizovat!== true) ? this.state.types && this.state.types.sort((a, b) => a > b ? 1:-1).map(type=>
                      <option value={type}>{type}</option>
                    ):(
                      (this.state.currentCourse.typ!==this.state.types[2]) ? (this.state.types && this.state.types.sort((a, b) => a > b ? 1:-1).slice(0,2).map(type=><option value={type}>{type}</option>))
                                                                          : (this.state.types && this.state.types.sort((a, b) => a > b ? 1:-1).slice(2,3).map(type=><option value={type}>{type}</option>))
                    )
                    }
                  </select>
                  {this.state.checks.error_typ && this.state.checks.error_typ!=="" && <p className="error">{this.state.checks.error_typ}</p>}
                </div>

                
                {/* {(this.state.allCourses) ? <Specifikacia_os_aop final_checks_clear = {this.final_checks_clear} error_specifikacia = {this.state.checks.error_specifikacia} onChangeCourseData = {this.onChangeCourseData} allCourses = {this.state.allCourses} typ = {this.state.currentCourse.typ} 
                rray = {this.state.currentCourse.specifikacia} initSpec ={(this.state.aktualizovat===true)?this.state.currentCourse_asStored.specifikacia:null} database = {this.state.database}/>: null} */}
                <Specifikacia_os_aop final_checks_clear = {this.final_checks_clear} error_specifikacia = {this.state.checks.error_specifikacia} onChangeCourseData = {this.onChangeCourseData} allCourses = {this.state.allCourses} typ = {this.state.currentCourse.typ} option_array = {this.state.currentCourse.specifikacia} initSpec ={(this.state.aktualizovat===true)?this.state.currentCourse_asStored.specifikacia:null} database = {this.state.database} comingBack = {window.sessionStorage.getItem("createCourseState")}/>


                <div className="form-group col-lg-4 required">
                  <label style={{color: "white"}}>Predseda komisie</label>
                  <select class="custom-select"
                    value = {this.state.currentCourse.predseda}
                    onChange={e=>{this.setState(function(prevState) {return {currentCourse: {...prevState.currentCourse,predseda: e.target.value}} });this.final_checks_clear("error_predseda") }}
                  >
                    <option selected>Vyber technika</option>
                    {
                    this.state.allTechnicians && this.state.allTechnicians.sort((a, b) => a.technician_priezvisko > b.technician_priezvisko ? 1:-1).map(tech=>
                      <option value={[tech.technician_meno+" "+tech.technician_priezvisko]}>{tech.technician_priezvisko} {tech.technician_meno}</option>
                    )
                    }
                  </select>
                  {this.state.checks.error_predseda && this.state.checks.error_predseda!=="" && <p className="error">{this.state.checks.error_predseda}</p>}
                </div>

                <div className="form-group col-lg-4">
                  <label style={{color: "white"}}>Prísediaci 1</label>
                  <input
                    type="text"
                    className="form-control"
                    id="prisediaci1"
                    value={this.state.currentCourse.prisediaci1}
                    onChange={(e) => {this.onChangeCourseData(e,'prisediaci1');}}
                    name="prisediaci1"
                  />
                </div>

                <div className="form-group col-lg-4">
                  <label style={{color: "white"}}>Prísediaci 2 (náhradník)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="prisediaci2"
                    value={this.state.currentCourse.prisediaci2}
                    onChange={(e) => {this.onChangeCourseData(e,'prisediaci2');}}
                    name="prisediaci2"
                  />
                </div>
                

                <div className="form-group col-lg-4 ">
                  <label style={{color: "white"}}>Poznámka</label>
                  <textarea rows="3" cols="50"
                    type="text" 
                    className="form-control"
                    id="poznamka"
                    // required
                    value={this.state.currentCourse.poznamka}
                    onChange={(e) => this.onChangeCourseData(e,'poznamka')}
                    name="poznamka"
                  />
                  {/* <textarea id="w3review" name="w3review" rows="4" cols="50"></textarea> */}
                </div>
                
              </Row>:
                null
              }
                

              {(this.state.currentCourse.specifikacia.length>0)?<>
                <Row>
                <div className="col-md-8 list" style={{marginTop:80}}>
                  <h5 className="mb-3 text-center" style={{color: "white"}}>Manuálny výber</h5>
                </div>
                </Row>

                <Row style={{justifyContent:"center"}}>
                  <div className="col-md-8 list" style={{position:"absolute",zIndex:2}}>
                    <div className="input-group mb-3">
                    
                      {/* Search by title field */}
                      <input
                        style={{borderRadius: '5px'}}
                        type="text"
                        className="form-control"
                        placeholder="Hľadaj podľa mena/priezviska"
                        value={this.state.searchByName}
                        // onChange={this.onChangeSearchTitle}
                        onChange={(e)=>{this.setState({
                                          searchByName: e.target.value,
                                          searched: false,
                                          showNameList: true,
                                      },
                                      // this.searchByName); 
                                      this.searchByNameLocal); 
                                  }}
                        onClick = {()=>this.setState({showNameList: true})}
                      />

                      
                      <Link to={{pathname: "/add"}}  className="btn btn-secondary" style={{marginLeft: 30}} onClick={()=>window.sessionStorage.setItem("createCourseState",this.setStateExceptDatabase())}>Nový profil</Link>

                    </div>
                    {(this.state.searchByName && this.state.searchByName!=="" && this.state.showNameList === true)?
                    <ul ref={this.wrapperRef} className="list-group selection-area scroll_custom overlay" style={{marginBottom:30,overflowX:"hidden",zIndex:10}}>
                      {this.state.searchedByName && this.state.searched && 
                        this.state.searchedByName.sort((a, b) => {if (a.priezvisko !== b.priezvisko) {return a.priezvisko > b.priezvisko ? 1:-1} 
                        else { return a.meno > b.meno ? 1:-1}}).map((person, index) => (
                          <li
                            
                            className={
                              "list-group-item " +
                              (index === this.state.currentIndex ? "active" : "")
                            }
                            key={person.id}
                          >
                            <label>
                              <input 
                                onClick={() => this.setActivePerson(person, index)} 
                                type="checkbox" 
                                defaultChecked={this.state.currentCourse.ucastnici.filter(function(ucastnik) {return ucastnik.id === person.id}).length}
                                checked={this.state.currentCourse.ucastnici.filter(function(ucastnik) {return ucastnik.id === person.id}).length || this.state.currentCourse.ucastnici_rozsirit.filter(function(ucastnik) {return ucastnik.personal_details.id === person.id}).length}
                              />
                              {/* <span>{person.priezvisko+' '+person.meno+'   |   '+person['zamestnávateľ'] + '   |   '}</span> */}
                              <span>{person.priezvisko+' '+person.meno+' '}</span>
                              {(person.active===false)?<FaExclamationCircle style={{fontSize: "20px", color: "red", cursor: "pointer"}}/>:null}
                            </label>
                          </li>
                        ))}
                    </ul>:null}
                  </div>
                </Row>
              </>:null}

              {(this.state.currentCourse.ucastnici.length>0 || this.state.currentCourse.ucastnici_rozsirit.length>0) ?(
                <div className = "w-100" style={{marginTop:50}}>
                  <Row>
                    <h4 className = "w-50" style={{color: "white"}}>Účastníci</h4>
                    <h4 className = "w-50" style={{color: "white",textAlign: "right",justifyContent: "right"}}>Počet účastníkov: {(this.state.currentCourse.ucastnici_rozsirit)?this.state.currentCourse.ucastnici.length + this.state.currentCourse.ucastnici_rozsirit.length:this.state.currentCourse.ucastnici.length}</h4>
                  </Row>
                  <hr/>
                  <div className="btn btn-secondary"
                    onClick = {()=>{
                      console.log('skolenia after Zrusit vyber: ',this.state.skolenia)
                      this.setState(function(prevState){return {
                        currentCourse: {
                          ...prevState.currentCourse,
                          'ucastnici': (this.state.aktualizovat===true && this.state.currentCourse.typ===this.state.types[2])?this.state.currentCourse_asStored.ucastnici:[],
                          'ucastnici_rozsirit': (this.state.aktualizovat===true && this.state.currentCourse.typ===this.state.types[2])?this.state.currentCourse_asStored.ucastnici_rozsirit:[],
                        }}})
                    }}
                  >
                    Zrušiť výber
                  </div>
                </div>)
                :null}


              <div className = {(this.state.currentCourse.typ!==this.state.types[2])?"container-custom x":"x"} style={{marginTop:20, marginBottom: 20, paddingTop: 40, paddingBottom: 40}}>
                {/* <Col>
              <Row> */}
                {ucastnici_sorted && ucastnici_sorted.map((person, index) => (
                  // <ul style={{width: "100%"}}>
                  <div style={{width:"100%"}}>
                    {(index===0)?(
                      //put a name of company with x sign to delete all people from that company
                      <div>
                        <div className="kurz-chip-head line-break w-50" style={{marginTop:"4em",marginLeft:"0em",width:"50%"}}>{(!person['zamestnávateľ']) ? `Nešpecifikovaná firma (${this.state.currentCourse.ucastnici.filter(item=>!item['zamestnávateľ']).length})` : `${person['zamestnávateľ']} (${this.state.currentCourse.ucastnici.filter(item=>item['zamestnávateľ']===person['zamestnávateľ']).length})`}
                          {/* {(!this.state.aktualizovat || this.state.aktualizovat)? */}
                          {(!this.state.aktualizovat || this.state.currentCourse.typ!==this.state.types[2])?
                          <button type="button" className="close close-custom" aria-label="Close" 
                            onClick={()=> this.removeAllFromCompany(person['zamestnávateľ'],'ucastnici')}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>:null}
                        </div>
                        {(this.state.currentCourse.typ!==this.state.types[2])?
                        <Row> 
                          {this.state.database && this.state.courseName2courseAlias && ucastnici_sorted.map(person2=>(person2['zamestnávateľ']===person['zamestnávateľ'])?
                            <div>
                              {/* put chips with names and surnames plus trigger on hover */}
                              <Overlay person2 = {person2} state = {this.state} isCourseActive = {this.isCourseActive} setState = {this.setState}/>
                            </div>  
                          
                          :null)}
                        </Row>
                        :null}
                      </div>
                      ): null}
                    {(index>0 && person['zamestnávateľ']!==ucastnici_sorted[index - 1]['zamestnávateľ'])?
                      // if change of company in ucastnici sorted, pu its name there
                      <div>
                        <div className="kurz-chip-head line-break w-50" style={{marginTop:"4em",marginLeft:"0em",width:"50%"}}>{(!person['zamestnávateľ']) ? `Nešpecifikovaná firma (${this.state.currentCourse.ucastnici.filter(item=>!item['zamestnávateľ']).length})` : `${person['zamestnávateľ']} (${this.state.currentCourse.ucastnici.filter(item=>item['zamestnávateľ']===person['zamestnávateľ']).length})`}
                          {/* {(!this.state.aktualizovat || this.state.aktualizovat)? */}
                          {(!this.state.aktualizovat || this.state.currentCourse.typ!==this.state.types[2])?
                          <button type="button" className="close close-custom" aria-label="Close" 
                            onClick={()=> this.removeAllFromCompany(person['zamestnávateľ'],'ucastnici')}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>:null}
                        </div>
                        {(this.state.currentCourse.typ!==this.state.types[2])?
                        <Row> 
                          {this.state.database && this.state.courseName2courseAlias && ucastnici_sorted.map(person2=>(person2['zamestnávateľ']===person['zamestnávateľ'])?
                            <div>
                              {/* put chips with names and surnames plus trigger on hover */}
                              <Overlay person2 = {person2} state = {this.state} isCourseActive = {this.isCourseActive} setState = {this.setState}/>
                            </div>  
                          
                          :null)}
                        </Row>
                        :null}
                      </div>
                    :null}
                    
                    {
                      (this.state.currentCourse.typ!==this.state.types[2])?
                      null
                      : 
                      (
                        // (person.localCourseList.length>0) ? 
                        <Row>
                          <Col xs={3} lg={3}>
                            <div className="kurz-chip line-break" style={{display: 'inline-block'}}>
                              {[person.priezvisko +' '+ person.meno]}
                              {(this.state.aktualizovat===true && this.state.currentCourse_asStored.ucastnici.filter(item=>item.id === person.id).length!==0)?null:
                              <button type="button" className="close close-custom" aria-label="Close" 
                                onClick={()=> {
                                  const ucastnici = this.state.currentCourse.ucastnici.filter(function(item){return item.id!==person.id})
                                  this.setState(function(prevState){return {
                                    currentCourse: {
                                      ...prevState.currentCourse,
                                      'ucastnici': ucastnici,
                                    }}
                                  })
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>}
                            </div>
                          </Col>
                          <Col xs={3} lg={3}>
                            <div 
                              className="" 
                              style={{display: 'inline-block',margin: '15px',pointerEvents:((this.state.aktualizovat===true && this.state.currentCourse_asStored.ucastnici.filter(item=>item.id === person.id).length!==0))?"none":null}} 
                              onChange={() => this.final_checks_clear("error_evcislo_"+person.id)}
                            >
                              <ReactChipInput
                                  classes="class1 class2"
                                  placeholder="Hello there"
                                  chips={person.preukazy}
                                  onSubmit={value => this.addChip(value,person)}
                                  onRemove={index => this.removeChip(index,person)}
                                />
                                {this.state.checks["error_evcislo_"+person.id] && this.state.checks["error_evcislo_"+person.id]!=="" && <p className="error">{this.state.checks["error_evcislo_"+person.id]}</p>}
                            </div>
                          </Col>
                          <Col xs={6} lg={6}>
                            {person.localCourseList && person.localCourseList.length>0 && person.localCourseList.sort((a,b)=>a.course_name>b.course_name?1:-1).map((course,idx) => (
                              <div className="kurz-chip">{course.course_name}
                                {(this.state.aktualizovat===true && this.state.currentCourse_asStored.ucastnici.filter(item=>item.id === person.id).length!==0)?null:
                                <button type="button" className="close close-custom" aria-label="Close" 
                                  onClick={()=> 
                                    this.cancelLocal(course.course_name,person.id)
                                  }
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>}
                                <br/> 
                                  {person.preukazy.map((preukaz,id)=>(
                                    <div class="form-check form-check-inline">
                                      <input 
                                      disabled = {(this.state.aktualizovat===true && this.state.currentCourse_asStored.ucastnici.filter(item=>item.id === person.id).length!==0)?true:false}
                                      class="form-check-input-custom" 
                                      type="checkbox" 
                                      id={id}
                                      value="option1" 
                                      checked = {person.localCourseList[idx].onPreukaz.includes(preukaz)}
                                      defaultChecked={false}
                                      onChange = {()=>  {this.changePreukazInCourse(person,course,idx,preukaz);this.final_checks_clear("error_"+person.id+"_"+course.course_name)}}
                                      />
                                      <label class="form-check-label" for="inlineCheckbox1">{preukaz}</label>
                                    </div>
                                  ))}
                                {
                                this.state.checks["error_"+person.id+"_"+course.course_name] && this.state.checks["error_"+person.id+"_"+course.course_name]!=="" && <p className="error">{this.state.checks["error_"+person.id+"_"+course.course_name]}</p>
                                }
                              </div>
                              ))
                            }
                          </Col>
                        </Row>
                        // :null
                      )
                    }
                    {/* </ul> */}
                    </div>
                    )
                  )
                }
              {/* </Row>
              </Col> */}


                {ucastnici_rozsirit_sorted.map((person,index)=>(
                  <div style={{width: "100%"}}>
                    {(index===0)?(
                      <div className="kurz-chip-head line-break w-50" style={{marginTop:"4em",marginLeft:"0em",width:"50%"}}>
                        {(!person.personal_details['zamestnávateľ']) ? 'Nešpecifikovaná firma' : person.personal_details['zamestnávateľ']}
                        {/* {(!this.state.aktualizovat || this.state.aktualizovat)? */}
                        {(!this.state.aktualizovat || this.state.currentCourse.typ!==this.state.types[2])?
                        <button type="button" className="close close-custom" aria-label="Close" 
                          onClick={()=> this.removeAllFromCompany(person.personal_details['zamestnávateľ'],'rozsirit')}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>:null}
                      </div>): null}
                    {(index>0 && person.personal_details['zamestnávateľ']!==ucastnici_rozsirit_sorted[index - 1].personal_details['zamestnávateľ'])?
                      <div className="kurz-chip-head line-break w-50">
                        {(!person.personal_details['zamestnávateľ']) ? 'Nešpecifikovaná firma' : person.personal_details['zamestnávateľ']}
                        {/* {(!this.state.aktualizovat || this.state.aktualizovat)? */}
                        {(!this.state.aktualizovat || this.state.currentCourse.typ!==this.state.types[2])?
                        <button type="button" className="close close-custom" aria-label="Close" 
                          onClick={()=> this.removeAllFromCompany(person.personal_details['zamestnávateľ'],'rozsirit')}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>:null}
                      </div>
                      : null}
                
                    <Row>
                      <Col xs={3} lg={3}>
                        <div className="kurz-chip line-break" style={{display: 'inline-block', backgroundColor:"darkcyan"}}>
                          {[person.personal_details.priezvisko +' '+ person.personal_details.meno]}
                          {(this.state.aktualizovat===true && this.state.currentCourse_asStored.ucastnici_rozsirit.filter(item=>item.id === person.id).length!==0)?null:
                          <button type="button" className="close close-custom" aria-label="Close" 
                            onClick={()=> {
                              const ucastnici_rozsirit = this.state.currentCourse.ucastnici_rozsirit.filter(function(item){return item.id!==person.id})
                              this.setState(function(prevState){return {
                                currentCourse: {
                                  ...prevState.currentCourse,
                                  'ucastnici_rozsirit': ucastnici_rozsirit,
                                }}
                              })
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>}
                        </div>
                      </Col>
                      <Col xs={3} lg={3}>
                        <div 
                          className="" 
                          style={{display: 'inline-block',margin: '15px',pointerEvents:((this.state.currentCourse.ucastnici_rozsirit.filter(item=>item.id === person.id).length!==0))?"none":null}} 
                          onChange={() => this.final_checks_clear("error_evcislo_"+person.id)}
                        >
                          <ReactChipInput
                              classes="class1 class2"
                              placeholder="Hello there"
                              chips={person.rsPreukazyNew}
                              onSubmit={value => this.addChip(value,person)}
                              onRemove={index => this.removeChip(index,person)}
                            />
                            {this.state.checks["error_evcislo_"+person.id] && this.state.checks["error_evcislo_"+person.id]!=="" && <p className="error">{this.state.checks["error_evcislo_"+person.id]}</p>}
                        </div>
                      </Col>
                      <Col xs={6} lg={6}>
                        {person.reversed_scopes && Object.keys(person.reversed_scopes).length>0 && Object.keys(person.reversed_scopes).map((key,idx) => (
                          <div className="kurz-chip" style={{backgroundColor:"darkcyan"}}>{key}
                            
                            <br/> 
                              {person.rsPreukazyNew.map((preukaz,id)=>(
                                <div class="form-check form-check-inline">
                                  <input 
                                  disabled = {true}
                                  class="form-check-input-custom" 
                                  type="checkbox" 
                                  id={id}
                                  value="option1" 
                                  checked = {person.reversed_scopes[key].includes(preukaz)}
                                  defaultChecked={false}
                                  onChange = {()=>  {}}
                                  />
                                  <label class="form-check-label" for="inlineCheckbox1">{preukaz}</label>
                                </div>
                              ))}
                            {
                            // this.state.checks["error_"+person.id+"_"+course.course_name] && this.state.checks["error_"+person.id+"_"+course.course_name]!=="" && <p className="error">{this.state.checks["error_"+person.id+"_"+course.course_name]}</p>
                            }
                          </div>
                          ))
                        }
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>

            {/* updating of the course */}
            {(this.state.aktualizovat && this.state.aktualizovat===true)?
            (
              // (this.state.currentCourse.typ === this.state.types[0]||this.state.currentCourse.typ === this.state.types[1])?
              (JSON.stringify(this.state.currentCourse)!==JSON.stringify(this.state.currentCourse_asStored))?
              <div className="row w-75 mx-auto justify-content-center" style={{marginBottom:"2em"}}>
                <button onClick={ () => {
                                          this.setState({showSpinner: true})
                                          if (this.final_checks()===false) {console.log('some problem in fill-up');this.setState({showDataCheckPopup: true, showSpinner: false})}else{this.updateCourse()}
                                        }}
                        className="btn btn-success justify-content-right">              
                    Aktualizovať
                    {(this.state.showSpinner===true) ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:5, width: "1.2rem", height: "1.2rem",}}></span>:null}
                </button>
              </div>
              :null
              )
            :
            // creating of the course
            <div className="row w-75 mx-auto justify-content-center" style={{marginBottom:"2em"}}>
              <button onClick={ () => {
                                        this.setState({showSpinner:true})
                                        if (this.final_checks()===false) {console.log('some problem in fill-up');this.setState({showDataCheckPopup: true, showSpinner: false})}else{this.createCourse();}
                                      }}
                      className="btn btn-success justify-content-right">              
                  Vytvoriť
                  {(this.state.showSpinner===true) ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:5, width: "1.2rem", height: "1.2rem",}}></span>:null}
              </button>
            </div>
            }



            <SweetAlert
              show={this.state.noCourseMatching!==""}
              custom
              type = "warning"
              showCancel
              showCloseButton
              confirmBtnText="Upraviť"
              cancelBtnText="Späť"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="secondary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Osobu nie je možné pridať do kurzu"
              onConfirm={() => {
                this.setState({noCourseMatching: ""})
                if (this.state.person_id_tmp) this.getCurrentFull(this.state.person_id_tmp)

                
                // window.location.href='/edit_profile'
              }}
              onCancel={() => this.setState({noCourseMatching: ""})}
            >
              Vybraná osoba nemá základné školenie z ani jedného vybraného kurzu. Ak chcete osobu pridať, je potrebné pridať základný kurz aspoň k jednej odbornosti.
          </SweetAlert>

          <SweetAlert
              className = "text-left justify-content-left"
              show={this.state.bitWideLicense===true}
              custom
              type = 'warning'
              // showCancel
              showCloseButton
              confirmBtnText="Ok"
              confirmBtnBsStyle="primary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Osobný profil obsahuje preukaz, ktorý nie je možné obnoviť"
              onConfirm={() => {
                this.setState({bitWideLicense: false, relevant: "", exceeding: ""})
              }}
              // onCancel={() => this.setState({bitWideLicense: false, relevant: "", exceeding: ""})}
            >
              
              <Col className="text-left">
              Vybraná osoba má viacero preukazov, ktoré obsahujú vybrané školenie. Nižšie uvedené však nemôžu byť obnovené, lebo obsahujú aj kurzy, ktoré nie sú súčasťou školenia. Chýbajúce kurzy pre jednotlivé preukazy:
              <br/>
              <br/>
              {this.state.relevant && Object.keys(this.state.relevant).map(key =>(
                // console.log('key:',key)
                (this.state.exceeding[key] && this.state.relevant[key]===true) ?
                  // console.log('key in exceeding',this.state.exceeding[key])
                  this.state.exceeding[key].map(extend => 
                    <li style={{fontsize:22}}>{key}: <span style={{fontsize:22}} className="badge badge-pill badge-primary" > {extend}</span></li>
                    // console.log('extension:',extend)
                  ) : null
              )
              )}
              </Col>
          </SweetAlert>

          <SweetAlert
              className = "text-left justify-content-left"
              show={this.state.tooWideLicense===true}
              custom
              type = 'warning'
              showCancel
              showCloseButton
              confirmBtnText="Upraviť"
              cancelBtnText="Späť"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="secondary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Osobu nie je možné pridať do kurzu"
              onConfirm={() => {
                this.setState({tooWideLicense: false, relevant: "", exceeding: ""})
                if (this.state.person_id_tmp) this.getCurrentFull(this.state.person_id_tmp)
              }}
              onCancel={() => this.setState({tooWideLicense: false, relevant: "", exceeding: ""})}
            >
              
              <Col className="text-left">
              Vybraná osoba má základné kurzy z niektorých odborností, ale daný preukaz obsahuje dodatočnú odbornosť, ktorá nie je zvolená. Pre pridanie tejto osoby je potrebné buď rozšíriť obsah kurzu alebo zmeniť údaje v osobnom profile. Chýbajúce kurzy:
              <br/>
              <br/>
              {this.state.relevant && Object.keys(this.state.relevant).map(key =>(
                // console.log('key:',key)
                (this.state.exceeding[key] && this.state.relevant[key]===true) ?
                  // console.log('key in exceeding',this.state.exceeding[key])
                  this.state.exceeding[key].map(extend => 
                    <li style={{fontsize:22}}>{key}: <span style={{fontsize:22}} className="badge badge-pill badge-primary" > {extend}</span></li>
                    // console.log('extension:',extend)
                  ) : null
              )
              )}
              </Col>
          </SweetAlert>

          <SweetAlert
              show={this.state.showZSalreadyPresentPopup}
              // dependencies={[this.state.rsAllow]}
              custom
              type = "warning"
              showCancel={false}
              showConfirm={false}
              showCloseButton
              // confirmBtnText="Upraviť profil"
              // cancelBtnText={(this.state.zsEncountered && this.state.zsEncountered.some(item=>this.state.noPreukazAllowed.includes(this.getCourseAliasFromLocalCourseAliases(item))))?((this.state.rsAllow===true)?"Rozšíriť preukaz":"Späť"):"Rozšíriť preukaz"}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="secondary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Osobu nie je možné pridať do kurzu"
              // onConfirm={() => {
              //   this.setState({showZSalreadyPresentPopup: false})
              //   if (this.state.person_id_tmp) this.getCurrentFull(this.state.person_id_tmp)
              //   }}
              onCancel={() => {
                        this.setState({showZSalreadyPresentPopup: false, rsPreukazyNew: []});
                        }}
            >
              <Col className="text-left">
              Vybraná osoba už má základné školenie z niektorého spomedzi vybraných kurzov. {(this.state.zsEncountered && this.state.zsEncountered.some(item=>this.state.noPreukazAllowed.includes(this.getCourseAliasFromLocalCourseAliases(item))))?((this.state.rsAllow===true)?"Chcete rozšíriť?":""):"Chcete rozšíriť?"}
              <br/>
              <br/>
              {this.state.zsEncountered && this.state.zsEncountered.map(enc =>
                    <li style={{fontsize:22}}>{enc}</li>
              )}
              </Col>
              <br/>
              <p style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
                
                <a className="btn btn-lg btn-secondary" style={{marginRight:"0.5em"}}
                  onClick={()=>{
                    this.setState({showZSalreadyPresentPopup: false})
                    if (!this.state.zsEncountered.some(item=>this.state.noPreukazAllowed.includes(this.getCourseAliasFromLocalCourseAliases(item)))) this.rozsirit(this.state.person_id_tmp)
                    else {
                      if (this.state.rsAllow === true) this.rozsirit(this.state.person_id_tmp)
                      else console.log('nothing to do')
                    }
                  }}
                >
                  {(this.state.zsEncountered && this.state.zsEncountered.some(item=>this.state.noPreukazAllowed.includes(this.getCourseAliasFromLocalCourseAliases(item))))?((this.state.rsAllow===true)?"Rozšíriť preukaz":"Späť"):"Rozšíriť preukaz"}
                </a>

                <a className="btn btn-lg btn-primary" style={{marginLeft:"0.5em"}}
                  onClick={() => {
                    this.setState({showZSalreadyPresentPopup: false})
                    if (this.state.person_id_tmp) this.getCurrentFull(this.state.person_id_tmp)
                    }}
                >
                  Upraviť profil
                </a>
              </p>

              {/* <p style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
                
                <a className="btn btn-lg btn-success" style={{marginLeft:"2.5em",marginRight:"2.5em",fontSize:"larger"}}
                  onClick={() => {swal("Pozor!",'Funkcionalita nie je naimplementovaná',"warning")}}
                >
                  Pokračovať iba pre nekonfliktné školenia <BsArrowRightCircle style={{fontSize:20}}/>
                </a>
              </p> */}

              
          </SweetAlert>



            <SweetAlert 
              show={this.state.showDataCheckPopup===true}
              type="danger"
              title="Pozor!"
              onConfirm={() => {
                this.setState({showDataCheckPopup: false})
              }
              }
              onCancel={()=>{
                this.setState({showDataCheckPopup: false})
              }}
              >
              Skontrolujte, či sú vyplnené všetky povinné polia(*), či majú časové dáta správny formát a či počet účastníkov kurzu je väčší ako 0 a menší ako 51. 
            </SweetAlert>

            <SweetAlert 
              show={createLoad}
              closeOnClickOutside={false}
              type={(createSucc===true) ? "success" : "danger" }
              title={(createSucc===true) ? "Hotovo!" : "Pozor!" } 
              onConfirm={() => {
                this.setState({createMessage: "",createMessage2: "",showSpinner: false})
                window.location.href='/courses'
                // this.setState({showSpinner: false});
              }
              }
              onCancel={()=>{
                this.setState({createMessage: "", createMessage2: "",showSpinner:false})
                // this.setState({showSpinner: false});
              }}
              >
              {(createSucc===true)? "Kurz úspešne vytvorený!" : (
                (this.state.createMessage.slice(0,7) === "Success" && this.state.createMessage2.slice(0,7) !== "Success")?"Pri vytváraní kurzu sa vyskytla chyba! Niektoré dáta v osobných profiloch sa nepodarilo upraviť"
                :
                  ((this.state.createMessage.slice(0,7) !== "Success" && this.state.createMessage2.slice(0,7) === "Success")?"Pri vytváraní kurzu sa vyskytla chyba! Záznam o školení v tabuľke školení sa nepodarilo vytvoriť"
                  :
                    ((this.state.createMessage.slice(0,7) !== "Success" && this.state.createMessage2.slice(0,7) !== "Success")?"Pri vytváraní kurzu sa vyskytla chyba! Ukladanie úplne zlyhalo"
                      :null
                    )
                  )
              )}
            </SweetAlert>


            <SweetAlert 
              show={updateLoad}
              closeOnClickOutside={false}
              type={(updateSucc===true) ? "success" : "danger" }
              title={(updateSucc===true) ? "Hotovo!" : "Pozor!" } 
              onConfirm={() => {
                this.setState({updateMessage: "",createMessage2: "", deleteMessage2: "",showSpinner:false})
                window.location.href='/courses'

              }
              }
              onCancel={()=>{
                this.setState({updateMessage: "",createMessage2: "", deleteMessage2: "",showSpinner:false})
              }}
              >
              {updateSucc===true ? "Kurz úspešne upravený!" : "Pri upravovaní kurzu sa vyskytla chyba!"}
            </SweetAlert>

            <SweetAlert
                    show={this.state.showDeletePopup}
                    warning
                    showCancel
                    confirmBtnText="Áno, chcem vymazať!"
                    cancelBtnText="Zrušiť"
                    confirmBtnBsStyle="danger"
                    title="Chcete naozaj vymazať vybraný kurz?"
                    onConfirm={() => {
                      this.deleteCourse();
                      this.setState({showDeletePopup: false})
                      // window.location.href='/courses'
                    }
                    }
                    onCancel={()=>{
                      this.setState({showDeletePopup: false})
                    }}
                    focusCancelBtn
                  >
                    Vymazaný kurz už nebude možné obnoviť!
            </SweetAlert>
            
            <SweetAlert 
              show={deleteLoad}
              type={(deleteSucc===true) ? "success" : "danger" }
              title={(deleteSucc===true) ? "Hotovo!" : "Pozor!" } 
              onConfirm={() => {
                this.setState({deleteMessage: "",deleteMessage2: ""})
                window.location.href='/courses'
                // this.setState({showSpinner: false});
              }
              }
              onCancel={()=>{
                this.setState({deleteMessage: "", deleteMessage2: ""})
                // this.setState({showSpinner: false});
              }}
              >
              {(deleteSucc===true)? "Kurz úspešne odstránený!" : "Pri odstraňovaní kurzu sa vyskytla chyba!"}
            </SweetAlert>

            <SweetAlert
              className = "text-left justify-content-left"
              show={this.state.showMaxPreukazWarning===true}
              custom
              type = 'warning'
              showCloseButton
              confirmBtnText="Ok"
              confirmBtnBsStyle="primary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Maximálny počet preukazov presiahnutý!"
              onConfirm={() => {
                this.setState({showMaxPreukazWarning: false, tooManyPr_courses: [], tooManyPr_persons: []})
              }}
              onCancel={() => this.setState({showMaxPreukazWarning: false, tooManyPr_courses: [], tooManyPr_persons: []})}
            >
              
              <Col className="text-left">
              Niektoré profily obsahujú viac ako {this.state.npreukaz_max} preukazov v rámci jedného kurzu. V súčasnosti aplikácia viac nepovoľuje.
              <br/>
              <br/>
              {this.state.tooManyPr_persons && this.state.tooManyPr_persons.map((person,index) => 
                    <li style={{fontsize:22}}>{person}: <span style={{fontsize:22}} className="badge badge-pill badge-primary" > {this.state.tooManyPr_courses[index]}</span></li>
              )}
              </Col>
          </SweetAlert>
          
          <SweetAlert
              className = "text-left justify-content-left"
              show={this.state.show2stepAlert===true}
              custom
              // type = 'warning'
              showCloseButton
              confirmBtnText="Vybrať"
              confirmBtnBsStyle="primary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Zvoľte preukaz"
              onConfirm={() => {
                this.setState({show2stepAlert: false, showNewEvcisla:true})
              }}
              onCancel={() => this.setState({show2stepAlert: false, rsPreukazy: [], rsPreukazyNew: [], rsPreukazyError: [], feasible_preukazy: [], scope_preukazy: []})}
            >
              
            <div>
              {this.state.feasible_preukazy.map(preukaz=>

                <label style={{verticalAlign: "middle"}}>
                <input 
                  className="form-check-input-custom"
                  style={{marginLeft:"1em",verticalAlign: "middle"}}
                  onChange={(e) => {
                    console.log(e)
                    if (this.state.rsPreukazy.includes(preukaz)) {
                      this.setState({rsPreukazy:this.state.rsPreukazy.filter(item=>item!==preukaz)})
                    }else{
                      this.setState({rsPreukazy:[...this.state.rsPreukazy,preukaz]})
                    }
                  }}
                  type="checkbox" 
                  defaultChecked={false}
                />
                {preukaz}
              </label>
              )}
            </div>

          </SweetAlert>

          <SweetAlert
              className = "text-left justify-content-left"
              show={this.state.show1stepAlert===true}
              custom
              // type = 'warning'
              showCloseButton
              confirmBtnText="Vybrať"
              confirmBtnBsStyle="primary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Zvoľte rozsah obnoveného preukazu"
              onConfirm={() => {
                // // const anyBeyond = Object.keys(this.state.scope_preukazy).map(preukaz=>this.state.scope_preukazy[preukaz].some(item=>!this.state.currentCourse.specifikacia.includes(item)))
                // let scope = {...this.state.scope_preukazy}
                // // const anyBeyond = Object.keys(this.state.scope_preukazy).map(preukaz=>scope[preukaz]=scope[preukaz].filter(item=>!this.state.currentCourse.specifikacia.includes(item)))
                // const anyBeyond = this.state.rsPreukazy.map(preukaz=>scope[preukaz]=scope[preukaz].filter(item=>!this.state.currentCourse.specifikacia.includes(item)))
                // console.log('anyBeyond:',anyBeyond,scope,Object.keys(scope))
                // if (anyBeyond.length>0 && anyBeyond.some(item=>item.length>0))
                // {
                //   swal({
                //   title: "Preukazy obsahujú nasledovné prebytočné školenia",
                //   content:
                //     <div>
                //       {this.state.rsPreukazy.map((item,idx)=>(
                //         <div style={{marginBottom:"1em"}}>
                //           <span className="span-preukaz-evcislo">{this.state.rsPreukazyNew[idx]} ({item}):</span>
                //           {scope[item].map(course=>(
                //             <li style={{textAlign:"left"}}> 
                //               {course}
                //             </li>  
                //           ))}
                          
                //         </div>
                //       ))}
                //     </div>
                //   })
                  
                // }else{
                  this.setState({show1stepAlert: false})
                  this.rsSelected(this.state.rsPreukazyNew,renewDict)
                // }
              }}
              onCancel={() => this.setState({show1stepAlert: false, rsPreukazy: [], rsPreukazyNew: [], rsPreukazyNewError: [], feasible_preukazy: [], scope_preukazy: []})}
            >
              
              <div className = "">
                {this.state.rsPreukazyNew.map((preukaz,idx)=>
                <div>
                  <br/>
                  <span className="span-preukaz-evcislo">{preukaz}:</span>
                  {
                  this.state.currentCourse.specifikacia.map(kurz=>
                    <div className="">  
                      <input 
                        className="form-check-input-custom"
                        style={{verticalAlign:"top",marginRight:"0.5em"}}
                        onClick={() => {
                          if (!(preukaz in renewDict)) {renewDict[preukaz] = []}
                          if (renewDict[preukaz].includes(kurz)){
                            renewDict[preukaz] = renewDict[preukaz].filter(item => item!==kurz)
                          }else{
                            renewDict[preukaz].push(kurz)
                          }
                        }} 
                        type="checkbox" 
                        defaultChecked={(this.state.scope_preukazy[this.state.rsPreukazy[idx]].includes(kurz))?true:false}
                        disabled={(this.state.scope_preukazy[this.state.rsPreukazy[idx]].includes(kurz))?true:false}
                      />
                      <label style={{width:"90%",textAlign:"left"}}>
                        {kurz}
                      </label>
                    </div>
                  )}
                  </div>
                )}
                </div>

          </SweetAlert>
      
          <SweetAlert
              className = "text-left justify-content-left"
              show={this.state.showNewEvcisla===true}
              custom
              showCloseButton
              confirmBtnText="Vybrať"
              confirmBtnBsStyle="primary"
              customIcon="https://cdn4.iconfinder.com/data/icons/apply-pixels-glyphs/40/Smiley_No_Emotion_Outlined-512.png"
              title="Zvoľte nové evidenčné čísla preukazov"
              onConfirm={() => {
                let ok = []
                this.state.rsPreukazyNew.map((item,idx)=>{
                  const res = this.addChip2(item)
                  if (res && res!=="ok"){
                    swal("Pozor!",`${res}`,"error")
                  }else{
                    if (this.state.rsPreukazyNew.filter(pr=>pr===item).length>1){
                      swal("Pozor!",`Nie je možné zadať dve rovnaké evidenčné čísla`,"error")
                    }else{
                      ok.push("ok")
                    }
                  }


                  let scope = {...this.state.scope_preukazy}
                  let problematic = {...this.state.scope_problematic}
                  // const anyBeyond = this.state.rsPreukazy.map(preukaz=>scope[preukaz]=scope[preukaz].filter(item=>!this.state.currentCourse.specifikacia.includes(item)))
                  let anyBeyond = []
                  anyBeyond = (Object.keys(problematic).length>0)?this.state.rsPreukazy.map(preukaz=>(preukaz in problematic)?problematic[preukaz]=problematic[preukaz].filter(item=>!this.state.currentCourse.specifikacia.includes(item)):[...anyBeyond]):[]
                  console.log('anyBeyond:',anyBeyond,scope,Object.keys(scope))
                  if (anyBeyond.length>0 && anyBeyond.some(item=>item.length>0))
                  {
                    swal({
                    title: "Preukazy obsahujú nasledovné prebytočné školenia",
                    content:
                      <div>
                        {this.state.rsPreukazy.map((item,idx)=>(
                          <div style={{marginBottom:"1em"}}>
                            <span className="span-preukaz-evcislo">{this.state.rsPreukazyNew[idx]} ({item}):</span>
                            {problematic[item].map(course=>(
                              <li style={{textAlign:"left"}}> 
                                {course}
                              </li>  
                            ))}
                            
                          </div>
                        ))}
                      </div>
                    })
                  }
                  else

                  if (ok.length===this.state.rsPreukazyNew.length && ok.every(item => item==="ok")===true){
                    this.setState({showNewEvcisla:false, show1stepAlert: true})
                  }
                })
              }}
              onCancel={() => this.setState({showNewEvcisla: false, rsPreukazy: [], rsPreukazyNew: [], rsPreukazyNewError: [], feasible_preukazy: [], scope_preukazy: {}})}
            >
              <div className = "">
                {this.state.rsPreukazy.map((preukaz,idx)=>
                <div>
                  <label>Nové ev. číslo pre {preukaz}</label>
                  <input 
                    className="form-control"
                    onChange={(e) => {
                      let tmp = this.state.rsPreukazyNew
                      tmp[idx] = e.target.value
                      this.setState({rsPreukazyNew:tmp})
                    }} 
                    value = {this.state.rsPreukazyNew[idx]}
                    type="text" 
                  />
                      
                  </div>
                )}
                </div>

          </SweetAlert>

          
            <SweetAlert 
                // show={window.sessionStorage.getItem("createCourseState")!==null}
                show={window.sessionStorage.getItem("createCourseState")!==null && !this.state.database}
                closeOnClickOutside={false}
                type="info"

                title="Aktualizujú sa dáta..."
                showConfirm={false}
                html = {'You can use <b>bold text</b>, ' + '<a href="//sweetalert2.github.io">links</a> ' + 'and other HTML tags'}
                // onConfirm={() => {
                //   this.setState({updateMessage: "",createMessage2: "", deleteMessage2: "",showSpinner:false})
                //   window.location.href='/courses'
                // }
                // }
                // onCancel={()=>{
                //   this.setState({updateMessage: "",createMessage2: "", deleteMessage2: "",showSpinner:false})
                // }}
                >
                  <div>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:5, width: "3rem", height: "3rem",marginTop:"1em"}}></span>
                  </div>
                  
                
            </SweetAlert>
              

          </Col>
        
        <Col xs={2} style={{paddingTop: 15, paddingLeft: 40, paddingRight: 40,marginBottom:30, backgroundColor:((this.state.currentCourse.specifikacia.length>0 && this.state.currentCourse.typ!==this.state.types[2]))?"rgb(0, 0, 0,0.3)":""}} className="my_scroll" >


          {(this.state.currentCourse.specifikacia.length>0 && this.state.currentCourse.typ!==this.state.types[2])?
          <>
              {/* <Row> */}
              <div className="col-md-9" style={{marginTop:80}}>
                <h5 className="mb-3 text-left" style={{color: "white"}}>
                  Celé firmy
                </h5>
              </div>
              {/* </Row> */}

              <div style={{justifyContent:"center"}}>
                <div className="col-md-9" style={{position:"absolute",zIndex:2}}>
                  <div className="input-group mb-3">
                  
                    {/* Search by title field */}
                    <input
                      style={{borderRadius: '5px'}}
                      type="text"
                      className="form-control"
                      placeholder="Hľadaj podľa názvu firmy"
                      value={this.state.searchByCompany}
                      // onChange={this.onChangeSearchTitle}
                      onChange={(e)=>{this.setState({
                                        searchByCompany: e.target.value,
                                        companies_searched: false,
                                    },
                                    this.searchCompany(e.target.value)); 
                                }}
                      onClick = {(e)=>{this.setState({showCompanyList: true});this.searchCompany(e.target.value)}}
                    />

                  </div>
                  {(this.state.searchByCompany!==null && this.state.showCompanyList === true)?
                    <ul ref={this.wrapperRef} className="list-group selection-area scroll_custom overlay" style={{marginBottom:30,overflowX:"hidden",zIndex:10}}>
                      {this.state.companies && this.state.companies_searched && 
                      this.state.companies.sort((a,b)=>a.company_nazov>b.company_nazov?1:-1).map((company, index) => (
                        (this.default_zero(company))?null:<li
                          
                          className={
                            "list-group-item " +
                            (index === this.state.currentIndex ? "active" : "")
                          }
                          key={company.company_id}
                        >
                          <label>
                            <input 
                              onChange={(e) => this.includeCompanysPersons(e,company)} 
                              disabled={(!this.less_actual_than_default_or_zero(company))?true:false}
                              type="checkbox" 
                              // defaultChecked={this.state.currentCourse.ucastnici.filter(function(ucastnik) {return ucastnik.id === person.id}).length}
                              checked={(this.less_actual_than_default_or_zero(company))?false:true}
                            />
                            
                            <span>{company.company_nazov+' '}</span>
                            
                            {(company.active===false)?<FaExclamationCircle style={{fontSize: "20px", color: "red", cursor: "pointer"}}/>:null}
                          </label>
                        </li>
                      ))}
                    </ul>:null
                  }
                </div>

                <div style={{marginBottom:"2em"}}>
                  <br/>
                  <br/>
                  {this.state.default_attendees && this.state.allCompanies && this.state.allCompanies.sort((a,b)=>a.company_nazov>b.company_nazov?1:-1).map(company=>
                    
                    (this.less_actual_than_default_or_zero(company))?null:(
                      <div className="kurz-chip-head" style={{backgroundColor:""}}>
                          {company.company_nazov}
                          <button type="button" className="close close-custom" aria-label="Close" 
                            onClick={()=> this.removeAllFromCompany(company.company_nazov,'ucastnici')}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                    )
                  )}
                </div>

              </div>
            </>:null}
        </Col>

      </Row>

    </div>
  </>
    )
  }
}