import React, { Component} from "react";
import DataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaExclamationCircle} from "react-icons/fa";
// import Badge from 'react-bootstrap/Badge';
// import { saveAs } from 'file-saver';
// import Font, {Text} from 'react-font';
import { CloseButton } from "react-bootstrap";
// const Fs = require('fs')  
// const Path = require('path')  
const Axios = require('axios')



export default class CompanyProfile extends Component {
  constructor(props) {
    super(props);
//     this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
//     this.retrieveTutorials = this.retrieveTutorials.bind(this);
//     this.refreshList = this.refreshList.bind(this);
      this.setActiveCompany = this.setActiveCompany.bind(this);
//     this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.searchCompany = this.searchCompany.bind(this);
    this.searchCompanyLocal = this.searchCompanyLocal.bind(this);
    this.deleteCompany = this.deleteCompany.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    // this.getCurrentCompanyFull = this.getCurrentCompanyFull.bind(this);
//     this.download = this.download.bind(this);
    this.componentWillMount = this.componentWillMount.bind(this);

    this.state = {
      showDeletePopup: false,
      showDeletePopup: false,
      showDeletePopup2: false,
      deleteMessage: "",
//       tutorials: [],
//       profileRetrieved: [],
      currentCompany: null,
      currentIndex: -1,
      searchCompany: "",
//       showTable: true,
//       searched: false,
      show: false,
//       isHovered: false,

    };
//     this.nSkoleni=[1,2,3];
//     this.colArr=["#872397","green","orange","#00bcd4","#fccb00"]
  }

  componentWillMount() {
    console.log('in WillMount-getAllCompanies');
        
        
    DataService.getAllCompanies()
      .then(response => {
        console.log("received companies",response.data);
        this.setState({
          message: "The companies were obtained successfully!"
        });
        this.setState({
          allCompanies: response.data[0]
        });

      })
      .catch(e => {
        console.log('The allCompanies fetch failed!',e);
      });

      DataService.getAllCourses()
      .then(response => {
        console.log("received courses",response.data);
        this.setState({
          allCourses: response.data[0]
        });
      })
      .then(()=>{
        let courseName2courseAlias = {}
        let courseAlias2courseName = {}
        this.state.allCourses.forEach(item=>{
          courseName2courseAlias[item.course_nazov] = item.course_alias
          courseAlias2courseName[item.course_alias] = item.course_nazov
        })
        this.setState({courseName2courseAlias: courseName2courseAlias,courseAlias2courseName: courseAlias2courseName})
      })
      .catch(e => {
        console.log('The allCourses fetch failed!',e);
      });
  }

  handleClose(){
    this.setState({show: false});
  }

  handleShow(){
    this.setState({show: true});
    console.log('handleShow:',this.state.show)

  }

  setActiveCompany(company, index) {
    this.setState({
      currentCompany: company,
      currentIndex: index
    });
    console.log('##setting currect company');
    // this.props.setProfile(company);
    console.log('##set currect company to',company);    
  }

deleteCompany() {
  console.log('profile:',this.state.currentCompany.company_id, this.state.currentIndex);
  DataService.deleteCompany(this.state.currentCompany.company_id)
    .then(response => {
      console.log(response.data);
      this.setState({showDeletePopup2:true,deleteMessage: "Success"})
    })
    .catch(e => {
      console.log('error detected:',e);
      this.setState({showDeletePopup2:true,deleteMessage: "Error"})
    });
}

  searchCompany() {
    console.log('searchCompany:',this.state.searchCompany)
    DataService.findCompanyByName(this.state.searchCompany)
      .then(response => {
        this.setState({
          companies: response.data,
          searched: true
        });
        console.log('searchCompany response:',response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  isRightSubset = (s1, s2) => {
    if (s1.size != s2.size) return false;
    const arr1 = [...s1];
    const arr2 = [...s2];
  
    for (let x of arr1) {
  
      if (!arr2.some(item=>item.includes(x))) {
        return false;
      }else{
        const idx = arr2.indexOf(x)
        // console.log('index,arr2',idx,arr2)
        if (idx>=0){
          // console.log('index,arr2',idx,arr2)
          arr2.splice(idx,1)
        }else{
          if (arr1.length>1 && x!==arr1[arr1.length-1]) return false
        }
      }
      }
    return true;
    }
  
  searchCompanyLocal() {
    // process search string
    const search = (this.state.searchCompany)?this.state.searchCompany.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" "):""
    console.log('searched string:',search)
    
    let data_send = []
    this.state.allCompanies && this.state.allCompanies.forEach(item=>{

      const nazov_parsed = item.company_nazov.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")

      const target = [...nazov_parsed]
      const out = this.isRightSubset(search,target)
      // console.log('target,search,out:',target,search,out)
      if (out===true) data_send.push(item)
    })
        
    this.setState({
      companies: data_send,
      searched: true
    });
    console.log('searchTitle string:',this.state.searchTitle,' results:',data_send);
  }


  render() {
    const {searchCompany, companies,  currentCompany, currentIndex } = this.state;
    currentCompany && console.log('extra kontakt:',JSON.parse(currentCompany.company_kontakt_extra))
    console.log('full state in companyProfile:',this.state)
    currentCompany && console.log('current company:',currentCompany)
    console.log('searchCompany:',searchCompany)
    currentCompany && console.log('vlastna perioda os:',JSON.parse(currentCompany.company_vlastna_perioda_os))
    // currentCompany && console.log('and:',JSON.parse(currentCompany.company_vlastna_perioda_os).sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(item => item))
  
    return(
      <div className="list row justify-content-center" style={{marginTop: 50}}>
        <div className="col-md-8 ">
          <div className="input-group mb-3">
            
             {/* Search by title field */}
             <input
              disabled={this.state.allCompanies?false:true}
              style={{borderRadius: '5px'}}
              type="text"
              className="form-control"
              placeholder="Hľadaj podľa názvu firmy"
              value={searchCompany}
              // onChange={this.onChangeSearchTitle}
              onChange={(e)=>{this.setState({
                searchCompany: e.target.value,
                searched: false
                },
              // ()=> {if (e.target.value!=="") this.searchCompany()}); 
              ()=> {if (e.target.value!=="") this.searchCompanyLocal()}); 
              }}
            />
            


            <Link to={{pathname: "/add_company"}}  className="btn btn-secondary" style={{marginLeft: 30}}>Nový profil</Link>

          </div>
        </div>

        {/* tutorial list  */}
        <div className=" w-100">
          <h4 className="justify-content-left" style={{color: "white",marginTop: 60}}>Zoznam evidovaných firiem:</h4> 
          <br/>

          <ul className="list-group scroll_custom" style={{marginBottom:30,maxHeight:"60vh",overflowY:"scroll"}}>
              {companies && this.state.searched &&
                companies.map((company, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveCompany(company, index)}
                  onDoubleClick={this.handleShow}
                  // 
                  key={company.company_id}
                >
                  {company.company_nazov+" "}
                  {(company.active===false)?<FaExclamationCircle style={{fontSize: "20px", color: "red", cursor: "pointer"}}/>:null}
                </li>
                ))}
          </ul>

          
          {currentCompany ? (
            <>
              <div style={{marginBottom: 30}} className="text-right">
                <br/>
                {(this.state.searchCompany!=="") ? 
                <Button className="m-2 btn btn-sm btn-danger" onClick={ () => this.setState({showDeletePopup: true})} style={{fontSize:16}}>
                  Odstrániť firmu
                </Button> : null}

                {(this.state.searchCompany!=="") ? 
                <Button className="m-2 btn btn-sm btn-secondary" onClick={this.handleShow} style={{fontSize:16}}>
                  Zobraziť firemné údaje
                </Button> : null}

              </div>

              
                <SweetAlert
                  show={this.state.showDeletePopup}
                  warning
                  showCancel
                  confirmBtnText="Áno, chcem vymazať!"
                  cancelBtnText="Zrušiť"
                  confirmBtnBsStyle="danger"
                  title="Chcete naozaj vymazať vybraný profil?"
                  onConfirm={() => {
                    console.log('confirm');          
                    this.deleteCompany();
                    this.setState({showDeletePopup: false})
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showDeletePopup: false})
                  }}
                  focusCancelBtn
                >
                  Vymazaný profil už nebude možné obnoviť!
                </SweetAlert>

                <SweetAlert 
                  show={this.state.showDeletePopup2}
                  type={(this.state.deleteMessage.slice(0,7)==="Success") ? "success" : "danger" }
                  title={(this.state.deleteMessage.slice(0,7)==="Success") ? "Hotovo!" : "Pozor!" } 
                  onConfirm={() => {
                    console.log('confirm');
                    this.setState({showDeletePopup2: false});
                    if (this.state.deleteMessage.slice(0,7)==="Success") window.location.href='/company';
                  }
                  }
                  onCancel={()=>{
                    console.log('cancel');
                    this.setState({showDeletePopup2: false})
                    if (this.state.deleteMessage.slice(0,7)==="Success") window.location.href='/company';
                  }}
                  >
                  {this.state.deleteMessage.slice(0,7)==="Success" ? "Firma bola úspešne odstránená z databázy!" : "Pri odstraňovaní profilu firmy sa vyskytla chyba!"}
              </SweetAlert>


              <Modal className="my-modal" styles={{ backgroundColor: "black" }}  show={this.state.show} onHide={this.handleClose} animation={true} scrollable={true} size='lg'>
                <Modal.Header closeButton>
                  <Modal.Title style={{color: "white"}}>
                    {(currentCompany.company_nazov) ? (currentCompany.company_nazov) : null} 
                    {(currentCompany.active===false)?<h5 style ={{color:"red"}}>Profil neaktívny</h5>:null}
                  </Modal.Title>
                </Modal.Header>
                        
                <Modal.Body className="show-grid">

                  <Container style={{color:'white'}}>
                    <Row>
                      <Col>
                        <br/>
                        IČO: {(currentCompany.company_ico) ? (currentCompany.company_ico) : null} 
                        <br/>
                        Štatutár: {currentCompany.company_statutar}
                        <br/>
                        Email: {currentCompany.company_email}
                        <br/>
                        Mobil: {currentCompany.company_mobil}
                        <br/>
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Adresa:</span>
                        <br/>
                        <div style={{marginLeft: 10}}>
                          {/* {(currentCompany.company_ulica) ? (currentCompany.company_ulica):null} {(currentCompany.company_mesto) ? (", " + currentCompany.company_mesto) : null} {(currentCompany.company_psc) ? (", " + currentCompany.company_psc) : null} */}
                          {/* {[(currentCompany.company_ulica && currentCompany.company_ulica!=="") ? currentCompany.company_ulica : null, (currentCompany.company_mesto)?currentCompany.company_mesto:null, (currentCompany.company_psc)?currentCompany.company_psc:null].join(', ')} */}
                          {[currentCompany.company_ulica, currentCompany.company_mesto,currentCompany.company_psc].filter(item=>item && item!=="").join(", ")}
                        </div>
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Kontakt: {currentCompany.company_kontakt}</span>
                        <br/>
                          <div style={{marginLeft:10}}>
                            Email: {currentCompany.company_kontakt_email}
                            <br/>
                            Mobil: {currentCompany.company_kontakt_mobil} 
                            <br/>
                            Poznámka: {(currentCompany.company_kontakt_poznamka)?currentCompany.company_kontakt_poznamka:""} 
                          </div>
                          <br/>
                        {(currentCompany.company_kontakt_extra)?JSON.parse(currentCompany.company_kontakt_extra).map((item,index)=>(
                          <>
                            <span style={{fontWeight: 'bold'}}>Pobočka {index+2}:</span>
                            <br/>
                              <div style={{marginLeft:10}}>
                                Kontakt {index+2}): {(item.kontakt)?item.kontakt:""}
                                <br/>
                                Email (k. os. {index+2}): {(item.kontakt_email)?item.kontakt_email:""}
                                <br/>
                                Mobil (k. os. {index+2}): {(item.kontakt_mobil)?item.kontakt_mobil:""} 
                                <br/>
                                Poznámka (k. os. {index+2}): {(item.kontakt_poznamka)?item.kontakt_poznamka:""} 
                                <br/>
                                Ulica: {(item.ulica)?item.ulica:""} 
                                <br/>
                                Mesto: {(item.mesto)?item.mesto:""} 
                                <br/>
                                PSČ: {(item.PSC)?item.PSC:""} 
                              </div>
                              <br/>
                          </>
                        )):null}
                        <br/>
                        Zmluva od: {currentCompany.company_vznik}

                        <br/>
                        <br/>
                        Vykonáva sa: {JSON.parse(currentCompany.company_vykonava_sa).map(item =>
                          <span style={{marginRight:5, backgroundColor:'gray', fontSize: 14}} class="badge badge-pill badge-primary"> {item} </span>
                          )}

                      </Col>
                      <Col>
                        <br/>
                        Pridelenie 1: {currentCompany.company_pridelenie1.split(' ')[1]} {currentCompany.company_pridelenie1.split(' ')[0]}
                        <br/>
                        Pridelenie 2: {currentCompany.company_pridelenie2.split(' ')[1]} {currentCompany.company_pridelenie2.split(' ')[0]}
                        <br/>
                        <br/>
                        Perióda OŠ/AOP (v rokoch): {(currentCompany.company_period_os) ? currentCompany.company_period_os : "-"}/{(currentCompany.company_period_aop) ? currentCompany.company_period_aop : "-"}
                        <br/>
                        Význam: {["A","B","C","D","E"][currentCompany.company_vyznam-1]}
                        <br/>
                        Veľkosť: {currentCompany.company_velkost}
                        <br/>
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Popis:</span>
                        <br/>
                        <div style={{marginLeft:10}}>
                          {currentCompany.company_popis}
                        </div>
                        <br/>
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Poznámka:</span>
                        <br/>
                        <div style={{marginLeft:10}}>
                          {currentCompany.company_poznamka}
                        </div>

                        <br/>
                        <span style={{fontWeight: 'bold'}}>Osobitné lehoty OŠ:</span>
                        <br/>
                        <span>
                         {currentCompany.company_vlastna_perioda_os && Object.keys(JSON.parse(currentCompany.company_vlastna_perioda_os)).sort((a,b)=>this.state.courseAlias2courseName[a].localeCompare(this.state.courseAlias2courseName[b],'sk')).map((item,index) =>
                            <li style={{fontSize:12}}>
                                {this.state.courseAlias2courseName[item] + ' (' + JSON.parse(currentCompany.company_vlastna_perioda_os)[item] + ' mesiacov)'}   
                            </li>
                         )}
                        </span>

                        <br/>
                        <span style={{fontWeight: 'bold'}}>Osobitné lehoty AOP:</span>
                        <br/>
                        <span>
                         {currentCompany.company_vlastna_perioda_aop && Object.keys(JSON.parse(currentCompany.company_vlastna_perioda_aop)).sort((a,b)=>this.state.courseAlias2courseName[a].localeCompare(this.state.courseAlias2courseName[b],'sk')).map((item,index) =>
                            <li style={{fontSize:12}}>
                                {this.state.courseAlias2courseName[item] + ' (' + JSON.parse(currentCompany.company_vlastna_perioda_aop)[item] + ' mesiacov)'}   
                            </li>
                         )}
                        </span>

                      </Col>
                      </Row>
                      
                    </Container>
                  
                </Modal.Body>
                  
                <Modal.Footer>
            
                  <div className="text-right">
                    <Link to={{pathname: "/edit_company", state: this.state.currentCompany}}  className="btn btn-primary mt-auto">Upraviť</Link>
                  </div>

                </Modal.Footer>
              </Modal>
            </>
          ): null}
          
        </div>

        {/* <button 
          className = "btn btn-warning"
          onClick={()=> DataService.migrate()}
        
        >
          Migrate array to dict
        </button> */}

      </div>
    );
  }
}